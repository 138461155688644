.excel-uploader {
  padding: 0rem;
  .MuiPaper-root {
    background-color: rgba(30, 30, 30, 1) !important;
    color: #ffffff;
  }
  
  &__container {
    margin: 0 auto;
    padding: 2rem;
    position: relative;
  }

  &__stepper {
    margin-bottom: 3rem;
    
    .MuiStep-root{
     
      .MuiStepLabel-root{
        
        .MuiStepLabel-labelContainer{
          
          .MuiStepLabel-label{
            color: #ffffff;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    
  }

  &__title {
    text-align: center;
    margin-bottom: 1rem;
  }

  

  &__file-list {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }

  &__file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    &:last-child {
      border-bottom: none;
    }
  }

  &__process-button {
    margin-top: 1rem;
    position: static;
  }

  &__icon {
    font-size: 3rem !important;
    color: #ffffff;
    margin-bottom: 1rem;
  }

  &__button {
    margin-top: 1rem !important;
  }

  &__alert {
    width: 100%;
  }

  &__loading {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}