// VehicleTypeSelector.scss

// Variables compartidas
$border-radius: 8px;
$transition-default: all 0.2s ease-in-out;

// Variables tema claro
$background-light: #f5f7fa;
$card-background-light: #ffffff;
$border-light: #e0e6ed;
$text-primary-light: #2c3e50;
$text-secondary-light: #7f8c9a;
$accent-color-light: #4CAF50;
$hover-color-light: rgba(76, 175, 80, 0.1);
$selected-color-light: rgba(76, 175, 80, 0.15);
$icon-background-light: #e9f5e9;

// Variables tema oscuro
$background-dark: #121212;
$card-background-dark: #1e1e1e;
$border-dark: #333;
$text-primary-dark: #ffffff;
$text-secondary-dark: #aaaaaa;
$accent-color-dark: #4CAF50;
$hover-color-dark: rgba(76, 175, 80, 0.2);
$selected-color-dark: rgba(76, 175, 80, 0.25);
$icon-background-dark: #263a26;

// Tema Claro
.GM__light-vehicle-type-selector {
  padding: 20px 0;
  
  .GM__light-section-title {
    font-size: 24px;
    font-weight: 600;
    color: $text-primary-light;
    margin-bottom: 24px;
  }
  
  .GM__light-type-categories {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .GM__light-category-section {
      .GM__light-category-title {
        font-size: 18px;
        font-weight: 500;
        color: $text-primary-light;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid $border-light;
      }
      
      .GM__light-types-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 16px;
        
        .GM__light-type-card {
          background-color: $card-background-light;
          border: 1px solid $border-light;
          border-radius: $border-radius;
          padding: 16px;
          cursor: pointer;
          transition: $transition-default;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          
          &:hover {
            background-color: $hover-color-light;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
          }
          
          &.selected {
            background-color: $selected-color-light;
            border-color: $accent-color-light;
            box-shadow: 0 0 0 1px $accent-color-light;
          }
          
          .GM__light-type-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $icon-background-light;
            color: $accent-color-light;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 12px;
          }
          
          .GM__light-type-name {
            font-size: 16px;
            font-weight: 500;
            color: $text-primary-light;
            margin: 0 0 8px 0;
          }
          
          .GM__light-type-description {
            font-size: 14px;
            color: $text-secondary-light;
            margin: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
  
  .GM__light-selection-info {
    margin-top: 24px;
    padding: 16px;
    background-color: rgba($accent-color-light, 0.05);
    border-radius: $border-radius;
    border-left: 3px solid $accent-color-light;
    
    p {
      margin: 0 0 8px 0;
      color: $text-primary-light;
      font-size: 14px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    .GM__light-info-message {
      font-weight: 500;
      color: $accent-color-light;
    }
  }
}

// Tema Oscuro
.GM__dark-vehicle-type-selector {
  padding: 20px 0;
  
  .GM__dark-section-title {
    font-size: 24px;
    font-weight: 600;
    color: $text-primary-dark;
    margin-bottom: 24px;
  }
  
  .GM__dark-type-categories {
    display: flex;
    flex-direction: column;
    gap: 32px;
    
    .GM__dark-category-section {
      .GM__dark-category-title {
        font-size: 18px;
        font-weight: 500;
        color: $text-primary-dark;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid $border-dark;
      }
      
      .GM__dark-types-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 16px;
        
        .GM__dark-type-card {
          background-color: $card-background-dark;
          border: 1px solid $border-dark;
          border-radius: $border-radius;
          padding: 16px;
          cursor: pointer;
          transition: $transition-default;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          
          &:hover {
            background-color: $hover-color-dark;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          }
          
          &.selected {
            background-color: $selected-color-dark;
            border-color: $accent-color-dark;
            box-shadow: 0 0 0 1px $accent-color-dark;
          }
          
          .GM__dark-type-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: $icon-background-dark;
            color: $accent-color-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 12px;
          }
          
          .GM__dark-type-name {
            font-size: 16px;
            font-weight: 500;
            color: $text-primary-dark;
            margin: 0 0 8px 0;
          }
          
          .GM__dark-type-description {
            font-size: 14px;
            color: $text-secondary-dark;
            margin: 0;
            line-height: 1.4;
          }
        }
      }
    }
  }
  
  .GM__dark-selection-info {
    margin-top: 24px;
    padding: 16px;
    background-color: rgba($accent-color-dark, 0.1);
    border-radius: $border-radius;
    border-left: 3px solid $accent-color-dark;
    
    p {
      margin: 0 0 8px 0;
      color: $text-primary-dark;
      font-size: 14px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    
    .GM__dark-info-message {
      font-weight: 500;
      color: $accent-color-dark;
    }
  }
}

// Responsividad compartida para ambos temas
@media screen and (max-width: 992px) {
  .GM__light-vehicle-type-selector,
  .GM__dark-vehicle-type-selector {
    .GM__light-types-grid,
    .GM__dark-types-grid {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
}

@media screen and (max-width: 768px) {
  .GM__light-vehicle-type-selector,
  .GM__dark-vehicle-type-selector {
    .GM__light-types-grid,
    .GM__dark-types-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      
      .GM__light-type-card,
      .GM__dark-type-card {
        padding: 12px;
        
        .GM__light-type-icon,
        .GM__dark-type-icon {
          width: 40px;
          height: 40px;
          font-size: 18px;
        }
        
        .GM__light-type-name,
        .GM__dark-type-name {
          font-size: 14px;
        }
        
        .GM__light-type-description,
        .GM__dark-type-description {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-vehicle-type-selector,
  .GM__dark-vehicle-type-selector {
    .GM__light-section-title,
    .GM__dark-section-title {
      font-size: 20px;
    }
    
    .GM__light-category-title,
    .GM__dark-category-title {
      font-size: 16px;
    }
    
    .GM__light-types-grid,
    .GM__dark-types-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
    
    .GM__light-selection-info,
    .GM__dark-selection-info {
      padding: 12px;
      
      p {
        font-size: 13px;
      }
    }
  }
}