.GM__light-header {
  width: 100%;
  background-color: transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  color: $light-text;

  &-container {
    @include flex-between;
    margin: 0 3rem;
    gap: 1rem;

    &-greeting {
      .route-title {
        font-size: 1.2rem;
        font-weight: 600;
        opacity: 0;
        transform: translateY(-20px);
        animation: slideDown 0.3s ease forwards;
        display: inline-block;
        color: $light-text;
      }
    }

    &-center {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      max-width: 400px;

      .GM__light-search-wrapper {
        @include flex-center;
        border: 1px solid #e0e0e0;
        border-radius: $border-radius;
        overflow: hidden;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;

        .GM__light-search-input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0 15px;
          background: transparent;
          font-size: 0.9rem;
          height: 100%;
          color: $light-text;

          &::placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .GM__light-search-button {
          padding: 8px;
          color: $light-text;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }

    &-right-section {
      @include flex-center;
      gap: 1rem;
    }

    .GM__light-chip-pointer {
      transition: all $transition-speed ease;
      cursor: pointer;
      color: $light-text;

      &.chip-current {
        border-color: $chip-current-color;
      }

      &.chip-selected {
        border-color: $chip-selected-color;
      }
    }

    .GM__light-date-picker-modal {
      position: absolute;
      right: 10rem;
      top: 4rem;
      z-index: 1000;
    }

  }

  .GM__light-header-actions {
    @include flex-center;

    .action-info {
      @include flex-center;
      gap: 1rem;

      span {
        font-size: 0.9rem;
        font-weight: 500;
        color: $light-text;
      }

      button {
        color: $light-text;
      }
    }
  }


}
