// Estilos base para el componente de progreso de carga

.upload-progress {
    // Variables de diseño
    $primary-color: #4CAF50;
    $background-color: #eee;
    $transition-speed: 0.3s;
    $shadow-color: rgba(99, 175, 76, 0.575);
  
    // Estilos base
    cursor: pointer;
    transition: all $transition-speed ease;
  
    // Modificadores de tipo de progreso
    &--full-circle {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $background-color;
      
      .upload-progress__bar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        overflow: hidden;
      }
  
      .upload-progress__bar-fill {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primary-color;
        border-radius: 50%;
        animation: circleProgressFill 3s ease-in-out infinite;
      }
    }
  
    &--linear {
        width: 100%;
        height: 20px;
        background-color: $background-color;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    
        .upload-progress__bar {
          position: relative;
          width: 100%;
          height: 100%;
        }
    
        .upload-progress__bar-fill {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: $primary-color;
          border-radius: 10px;
          transition: width $transition-speed ease;
        }
    
        // Sombras desvanecientes
        .upload-progress__bar-shadow {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-radius: 10px;
          background: linear-gradient(
            to left,
            $shadow-color,
            transparent 80%
          );
          opacity: 0.6;
          transform: translateX(10px); // Simula una sombra detrás
        }
      }
  
    &--sidenav-linear {
      width: 100%;
      height: 10px;
      background-color: $background-color;
      border-radius: 5px;
      overflow: hidden;
  
      .upload-progress__bar-fill {
        height: 100%;
        background: linear-gradient(to right, transparent, $primary-color);
        animation: linearProgressFill 3s ease-in-out infinite;
      }
    }
  
    &--small-circle {
      width: 50px;
      height: 50px;
      position: relative;
      color: black;
  
      .upload-progress__small-circle {
        width: 100%;
        height: 100%;
        position: relative;
        border-radius: 50%;
        background-color: $background-color;
        overflow: hidden;
      }
  
      .upload-progress__percentage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
        font-weight: bold;
        z-index: 2;
      }
  
      .upload-progress__circle-fill {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary-color;
        border-radius: 50%;
        animation: circleProgressFill 3s ease-in-out infinite;
      }
    }
  
    // Estados de interacción
    &:hover {
      opacity: 0.8;
    }
  
    &:active {
      opacity: 0.6;
    }
  }
  
  @keyframes circleProgressFill {
    0% {
      width: 0;
      height: 0;
    }
    100% {
      width: 100%;
      height: 100%;
    }
  }
  
  @keyframes linearProgressFill {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }