// BasicDataSectionDark.scss
.GM__dark-basic-data-section {
  background-color: #2d2d2d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #444444;

  .GM__dark-section-title {
    background-color: #00C853;
    color: #ffffff;
    border-bottom: 1px solid #00B848;
  }

  .GM__dark-data-form {
    background-color: #2d2d2d;
  }

  .GM__dark-input-label {
    color: #e0e0e0;
  }

  .GM__dark-input,
  .GM__dark-select {
    border: 1px solid #444444;
    background-color: #3a3a3a;
    color: #f5f5f5;
    transition: border-color 0.2s;

    &:focus {
      border-color: #00C853;
      outline: none;
    }

    &:disabled {
      background-color: #333333;
      color: #888888;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #888888;
    }
  }

  .GM__dark-select {
    option {
      background-color: #3a3a3a;
      color: #f5f5f5;
    }
  }

  .GM__dark-consult-button {
    background-color: #00C853;
    color: white;
    transition: background-color 0.2s;
    
    &:hover:not(:disabled) {
      background-color: #00B848;
    }
  }

  .GM__dark-clear-button {
    background-color: #f44336;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #e53935;
    }
  }

  .GM__dark-pdf-button {
    background-color: #2196F3;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #1E88E5;
    }
  }

  .GM__dark-share-button {
    background-color: #673AB7;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #5E35B1;
    }
  }
}