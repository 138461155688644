// Variables
$dark-background: #000000;
$dark-text: #ffffff;
$light-background: #ffffff;
$light-text: #333333;
$border-radius: 20px;
$transition-speed: 0.3s;
$chip-current-color: #215be3;
$chip-selected-color: #a251e1;

// Mixins básicos
@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@import './subdivisions/HeaderLight.scss';
@import './subdivisions/HeaderDark.scss';


// Tema Oscuro immportado al iniico

// Tema Claro importado al inicio

// Animaciones
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media Queries
@media (max-width: 768px) {

  .GM__dark-header,
  .GM__light-header {
    &-container {
      flex-direction: column;
      gap: 1rem;
      margin: 0 1rem;
    }

    &-center {
      max-width: 100%;
      width: 100%;
    }

    &-header-actions {
      width: 100%;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 480px) {

  .GM__dark-header,
  .GM__light-header {
    padding: 1rem;

    &-greeting {
      text-align: center;
      width: 100%;
    }

    &-action-info {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
@media (max-width: 1200px) {
  .GM__light-header-actions,
  .GM__dark-header-actions {
      span {
        display: none;
      }

  }
}