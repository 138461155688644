// Importación de estilos por tema
@import './subdivisions/ContactSectionAddVehicleLight.scss';
@import './subdivisions/ContactSectionAddVehicleDark.scss';

// Variables compartidas
$grid-gap-base: 20px;
$section-spacing: 24px;
$border-radius: 8px;
$transition-timing: 0.2s ease;

// Media queries compartidas
@media screen and (max-width: 1200px) {
  .GM__light-contact-section,
  .GM__dark-contact-section {
    .GM__light-contact-grid,
    .GM__dark-contact-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: $grid-gap-base;
    }
  }
}

@media screen and (max-width: 768px) {
  .GM__light-contact-section,
  .GM__dark-contact-section {
    .GM__light-contact-grid,
    .GM__dark-contact-grid {
      grid-template-columns: 1fr;
      gap: 16px;
    }
    
    .GM__light-section-title,
    .GM__dark-section-title,
    .GM__light-subsection-title,
    .GM__dark-subsection-title {
      font-size: 18px;
    }
    
    .GM__light-phone-input-container,
    .GM__dark-phone-input-container {
      flex-direction: column;
      gap: 10px;
      
      .GM__light-sort-dropdown,
      .GM__dark-sort-dropdown {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-contact-section,
  .GM__dark-contact-section {
    padding: 16px 0;
    
    .GM__light-phone-group,
    .GM__dark-phone-group {
      grid-column: 1;
    }
    
    .GM__light-location-section,
    .GM__dark-location-section {
      margin-top: 24px;
    }
  }
}