// Importación de temas
@import './subdivisions/SidebarLight';
@import './subdivisions/SidebarDark';

// Estilos para la estructura de carpetas
.sidebar-folder {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  transition: all 0.3s ease;
  
  &.open {
    background: rgba(255, 255, 255, 0.03);
    
    .light & {
      background: rgba(42, 45, 52, 0.03);
    }
  }
  
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: rgba(255, 255, 255, 0.02);
    border-left: 3px solid transparent;
    
    &.open {
      background: rgba(255, 255, 255, 0.1);
      border-left: 3px solid #00a651;
      
      .light & {
        background: rgba(42, 45, 52, 0.1);
      }
    }
    
    &:hover {
      background: rgba(255, 255, 255, 0.08);
      
      .light & {
        background: rgba(42, 45, 52, 0.08);
      }
    }
    
    .sidebar-folder-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      width: 20px;
      height: 20px;
      transition: transform 0.3s ease;
      
      &.rotated {
        transform: rotate(0deg);
      }
      
      img {
        width: 14px;
        height: 14px;
        transition: transform 0.3s ease;
        
        &.chevron-down {
          transform: rotate(90deg);
        }
      }
    }
  }
  
  &-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 8px;
    
    &.expanded {
      max-height: 500px;
      opacity: 1;
      visibility: visible;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    
    .sidebar-body-routes-route {
      padding-left: 2.5rem;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      
      .light & {
        border-left: 1px solid rgba(42, 45, 52, 0.1);
      }
    }
    
    .sidebar-folder-item {
      position: relative;
      font-size: 0.85rem;
      
      &::before {
        content: "";
        position: absolute;
        left: 1.5rem;
        top: 50%;
        width: 0.4rem;
        height: 1px;
        background: rgba(255, 255, 255, 0.3);
        
        .light & {
          background: rgba(42, 45, 52, 0.3);
        }
      }
      
      &.active {
        background: rgba(0, 166, 81, 0.15);
        
        &::before {
          background: #00a651;
        }
      }
    }
  }
}

// Ajustes para la barra lateral colapsada
.sidebar.collapsed {
  .sidebar-folder {
    &-header {
      .sidebar-folder-arrow {
        display: none;
      }
      
      &.open, &:hover {
        background: rgba(255, 255, 255, 0.15);
        
        .light & {
          background: rgba(42, 45, 52, 0.15);
        }
      }
    }
    
    &-content {
      position: absolute;
      left: 60px;
      top: 0;
      background: #1a1a1a;
      border-radius: 0 6px 6px 0;
      box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
      padding: 0;
      z-index: 100;
      width: 180px;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      margin-left: 0;
      
      .light & {
        background: #ffffff;
        box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
      }
      
      &.expanded {
        max-height: 500px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        padding: 8px 0;
      }
      
      .sidebar-body-routes-route {
        padding-left: 1rem;
        border-left: none;
        
        &:first-child {
          margin-top: 4px;
        }
        
        &:last-child {
          margin-bottom: 4px;
        }
      }
      
      .sidebar-folder-item {
        &::before {
          display: none;
        }
        
        .sidebar-body-routes-route-name {
          font-size: 0.85rem;
        }
      }
    }
  }
}

// Ajuste adicional para el indicador de ruta activa
.sidebar-body-routes {
  &::after {
    z-index: 5;
  }
}

// Mejora de la visualización para rutas activas
.sidebar-body-routes-route {
  &.active {
    background: rgba(0, 166, 81, 0.15) !important;
    border-left: 3px solid #00a651;
    
    .sidebar-body-routes-route-name {
      font-weight: 500;
    }
  }
}

// Indicador para mostrar cuáles son carpetas
.sidebar-folder-header {
  &::after {
    content: "";
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #00a651;
    opacity: 0.7;
  }
  
  &.open::after {
    background: #00a651;
    opacity: 1;
  }
}

// Ocultar indicador cuando está expandido
.sidebar.expanded .sidebar-folder-header::after {
  display: none;
}