.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-family: system-ui, -apple-system, sans-serif;
  
    &--dark {
      background: #1a1a1a;
      color: #ffffff;
  
      .pagination__button,
      .pagination__number {
        background: #2a2a2a;
        color: #ffffff;
        border: 1px solid #333333;
  
        &:hover:not(:disabled) {
          background: #3a3a3a;
        }
  
        &:disabled {
          background: #222222;
          color: #666666;
          cursor: not-allowed;
        }
      }
  
      .pagination__number {
        &--active {
          background: #4a4a4a;
          border-color: #5a5a5a;
        }
  
        &--ellipsis {
          background: transparent;
          border: none;
          color: #888888;
        }
      }
    }
  
    &__numbers {
      display: flex;
      gap: 0.5rem;
    }
  
    &__button,
    &__number {
      min-width: 2.5rem;
      height: 2.5rem;
      padding: 0 0.75rem;
      border-radius: 0.375rem;
      font-size: 0.875rem;
      font-weight: 500;
      transition: all 0.2s ease;
      cursor: pointer;
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.5);
      }
    }
  
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  @media (max-width: 640px) {
    .pagination {
      flex-direction: column;
      gap: 0.75rem;
  
      &__numbers {
        order: -1;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }