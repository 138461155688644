// Variables para tema oscuro
$background-dark: #1a1a1a;
$background-darker: #2a2a2a;
$border-color-dark: #444;
$border-color-darker: #666;
$text-color-dark: #fff;
$text-color-muted-dark: #888;
$accent-color-dark: #747373;
$accent-hover-dark: #666;

// Variables para tema claro
$background-light: #f5f7fa;
$background-lighter: #ffffff;
$border-color-light: #e0e6ed;
$border-color-lighter: #d1d9e6;
$text-color-light: #2c3e50;
$text-color-muted-light: #7f8c9a;
$accent-color-light: #4CAF50;
$accent-hover-light: #3e8e41;

// Estilos para tema oscuro
.GM__dark-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $border-color-dark;
  border-radius: 4px;
  height: 50px;
  background-color: $background-dark;
  transition: border-color 0.3s;
  width: 100%;

  input,
  textarea {
    background-color: transparent;
    color: $text-color-dark;
    border: none;
    outline: none;
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
  }

  input::placeholder,
  textarea::placeholder {
    color: $text-color-muted-dark;
  }

  .GM__dark-icon-button {
    background: none;
    border: none;
    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
      outline: 2px solid $border-color-darker;
      border-radius: 4px;
    }

    .GM__dark-icon {
      margin: 0;
    }
  }

  .GM__dark-icon-wrapper {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;

    .GM__dark-icon {
      margin: 0;
    }
  }

  &.GM__dark-small {
    padding: 0.25rem;

    input,
    textarea {
      font-size: 0.875rem;
    }
  }

  &.GM__dark-medium {
    padding: 0.75rem;

    input,
    textarea {
      font-size: 1rem;
    }
  }

  &.GM__dark-auto {
    width: 100%;
  }

  &:hover {
    border-color: $border-color-darker;
  }

  &.GM__dark-select-wrapper {
    position: relative;
    flex-direction: column;
    cursor: pointer;

    .GM__dark-selected-value {
      font-size: 1rem;
      padding: 0.5rem;
      border-bottom: 1px solid $border-color-dark;
      width: 100%;
      cursor: pointer;
    }
  }
}

.GM__dark-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: $background-darker;
  border: 1px solid $border-color-dark;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 10;

  .GM__dark-dropdown-search-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $border-color-dark;

    .GM__dark-dropdown-search {
      flex: 1;
      margin-bottom: 0;
      margin-right: 0.5rem;
      border: none;
      width: 97%;
      padding: 0.5rem;
      outline: none;
      font-size: 1rem;
      background-color: $background-dark;
      color: $text-color-dark;

      &::placeholder {
        color: $text-color-muted-dark;
      }
    }

    .GM__dark-search-button {
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      transition: background-color 0.2s;

      &:hover {
        background-color: $border-color-dark;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .GM__dark-dropdown-loading {
    padding: 1rem;
    text-align: center;
    color: $text-color-muted-dark;
  }

  .GM__dark-dropdown-no-data {
    padding: 1rem;
    text-align: center;
    color: $text-color-muted-dark;
  }

  .GM__dark-dropdown-pagination {
    border-top: 1px solid $border-color-dark;
    padding: 0.5rem;

    // Ajustes específicos para la paginación dentro del dropdown
    .pagination {
      margin: 0;

      &__button {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }

      &__numbers {
        gap: 0.25rem;
      }

      &__number {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .GM__dark-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    color: $text-color-dark;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    outline: none;

    &:hover {
      background-color: $accent-color-dark;
    }

    .GM__dark-info-icon {
      margin-left: 0.5rem;
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: $text-color-dark;
    }
  }
}

// Estilos para tema claro
.GM__light-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid $border-color-light;
  border-radius: 4px;
  height: 50px;
  background-color: $background-lighter;
  transition: border-color 0.3s;
  width: 100%;

  input,
  textarea {
    background-color: transparent;
    color: $text-color-light;
    border: none;
    outline: none;
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
  }

  input::placeholder,
  textarea::placeholder {
    color: $text-color-muted-light;
  }

  .GM__light-icon-button {
    background: none;
    border: none;
    padding: 0;
    margin-right: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
      outline: 2px solid $border-color-lighter;
      border-radius: 4px;
    }

    .GM__light-icon {
      margin: 0;
    }
  }

  .GM__light-icon-wrapper {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;

    .GM__light-icon {
      margin: 0;
    }
  }

  &.GM__light-small {
    padding: 0.25rem;

    input,
    textarea {
      font-size: 0.875rem;
    }
  }

  &.GM__light-medium {
    padding: 0.75rem;

    input,
    textarea {
      font-size: 1rem;
    }
  }

  &.GM__light-auto {
    width: 100%;
  }

  &:hover {
    border-color: $border-color-lighter;
  }

  &.GM__light-select-wrapper {
    position: relative;
    flex-direction: column;
    cursor: pointer;

    .GM__light-selected-value {
      font-size: 1rem;
      padding: 0.5rem;
      border-bottom: 1px solid $border-color-light;
      width: 100%;
      cursor: pointer;
    }
  }
}

.GM__light-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background-color: $background-light;
  border: 1px solid $border-color-light;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .GM__light-dropdown-search-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid $border-color-light;

    .GM__light-dropdown-search {
      flex: 1;
      margin-bottom: 0;
      margin-right: 0.5rem;
      border: none;
      width: 97%;
      padding: 0.5rem;
      outline: none;
      font-size: 1rem;
      background-color: $background-lighter;
      color: $text-color-light;

      &::placeholder {
        color: $text-color-muted-light;
      }
    }

    .GM__light-search-button {
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      transition: background-color 0.2s;

      &:hover {
        background-color: $border-color-light;
      }

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .GM__light-dropdown-loading {
    padding: 1rem;
    text-align: center;
    color: $text-color-muted-light;
  }

  .GM__light-dropdown-no-data {
    padding: 1rem;
    text-align: center;
    color: $text-color-muted-light;
  }

  .GM__light-dropdown-pagination {
    border-top: 1px solid $border-color-light;
    padding: 0.5rem;

    // Ajustes específicos para la paginación dentro del dropdown
    .pagination {
      margin: 0;

      &__button {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }

      &__numbers {
        gap: 0.25rem;
      }

      &__number {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .GM__light-dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    color: $text-color-light;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    outline: none;

    &:hover {
      background-color: rgba($accent-color-light, 0.1);
    }

    .GM__light-info-icon {
      margin-left: 0.5rem;
      width: 20px;
      height: 20px;
      cursor: pointer;
      color: $accent-color-light;
    }
  }
}