// Importamos los estilos específicos para cada tema
@import './subdivisions/ViewContentLight.scss';
@import './subdivisions/ViewContentDark.scss';

// Estilos comunes para todas las vistas
.users-view-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  
  // Estilo común para grids
  .users-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    margin-bottom: 32px;
  }
  
  // Estilo común para cabeceras
  .users-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 16px;
    flex-wrap: wrap;
  }
  
  // Contenedor de paginación común
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }
}

// Media queries comunes
@media screen and (max-width: 992px) {
  .users-view-container {
    .users-header {
      flex-direction: column;
      align-items: stretch;
    }
  }
}

@media screen and (max-width: 768px) {
  .users-view-container {
    .users-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
}

@media screen and (max-width: 480px) {
  .users-view-container {
    padding: 8px;
    
    .users-grid {
      grid-template-columns: 1fr;
    }
  }
}