.data-visualization {
  width: 100%;
  height: 75vh;
  padding: 1rem;
  overflow: auto;
  color: #ffffff;

  .cards-data {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Responsivo para las tarjetas
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .charts {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Responsivo para los gráficos
    margin-top: 2rem;

    .chart {
      background-color: #f5f5f5;
      border-radius: 8px;
    }
  }
  .events {
    padding: 50px 0;
    width: 100%;
    height: auto;
    .carousel {
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      max-width: 76vw;
      height: auto;
    }
  }


  .alert {
    margin-top: 1rem;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .btn {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;

      &.btn-outline {
        background-color: transparent;
        border: 2px solid #007bff;
        color: #007bff;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          background-color: #007bff;
          color: #fff;
        }
      }

      &.btn-primary {
        background-color: #007bff;
        color: #fff;
        border: none;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
