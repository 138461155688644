// Variables para el tema claro
$background-light: #f5f7fa;
$background-lighter: #ffffff;
$border-color-light: #e0e6ed;
$border-color-lighter: #d1d9e6;
$text-color-light: #2c3e50;
$text-color-muted-light: #7f8c9a;
$accent-color-light: #4CAF50;
$accent-hover-light: #3e8e41;
$danger-color-light: #e74c3c;
$danger-hover-light: #c0392b;

.GM__light-add-vehicle-container {
  width: 100%;
  background-color: $background-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .GM__light-vehicle-form-container {
    width: 100%;
    max-width: 1200px;
    background: $background-lighter;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 2rem;
    
    .GM__light-stepper {
      padding: 24px 16px;
      background-color: $background-lighter;
      border-bottom: 1px solid $border-color-light;
      
      .MuiStepIcon-root {
        color: $accent-color-light;
        
        &.MuiStepIcon-active {
          color: $accent-color-light;
        }
        
        &.MuiStepIcon-completed {
          color: $accent-color-light;
        }
      }
      
      .MuiStepLabel-label {
        color: $text-color-light;
        
        &.MuiStepLabel-active {
          color: $text-color-light;
          font-weight: 600;
        }
        
        &.MuiStepLabel-completed {
          color: $text-color-muted-light;
        }
      }
    }
    
    .GM__light-step-content {
      padding: 10px;
      min-height: 400px;
      
      // Contenido específico para VehicleTypeSelector
      .vehicle-type-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 16px;
        
        .vehicle-type-card {
          background-color: $background-lighter;
          border: 1px solid $border-color-light;
          border-radius: 8px;
          padding: 16px;
          text-align: center;
          cursor: pointer;
          transition: all 0.2s ease;
          
          &:hover {
            border-color: $accent-color-light;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
          
          &.selected {
            border: 2px solid $accent-color-light;
            background-color: rgba($accent-color-light, 0.05);
          }
          
          img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            margin-bottom: 12px;
          }
          
          h3 {
            font-size: 16px;
            margin: 0;
            color: $text-color-light;
          }
        }
      }
      
      // Contenido específico para VehicleInfoSection
      .vehicle-info-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        
        .form-field {
          margin-bottom: 16px;
          
          label {
            display: block;
            font-size: 14px;
            margin-bottom: 8px;
            color: $text-color-light;
          }
          
          input, select {
            width: 100%;
            padding: 10px 12px;
            border: 1px solid $border-color-light;
            border-radius: 4px;
            font-size: 14px;
            color: $text-color-light;
            background-color: $background-lighter;
            
            &:focus {
              outline: none;
              border-color: $accent-color-light;
            }
          }
        }
      }
      
      // Contenido específico para ContactSectionAddVehicle
      .contact-form {
        .form-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          margin-bottom: 24px;
          
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }
        
        .date-range {
          display: flex;
          gap: 16px;
          align-items: center;
          
          .date-field {
            flex: 1;
          }
        }
      }
      
      // Contenido específico para DocumentsSection
      .documents-section {
        .operation-selector {
          margin-bottom: 24px;
          
          label {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
          }
        }
        
        .documents-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          gap: 20px;
          margin-bottom: 24px;
          
          .document-card {
            border: 1px solid $border-color-light;
            border-radius: 8px;
            padding: 16px;
            position: relative;
            
            &.required {
              border-left: 3px solid $accent-color-light;
            }
            
            .required-badge {
              position: absolute;
              top: 8px;
              right: 8px;
              background-color: $accent-color-light;
              color: white;
              font-size: 12px;
              padding: 2px 8px;
              border-radius: 12px;
            }
            
            .document-actions {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            }
          }
        }
        
        .documents-table {
          width: 100%;
          border-collapse: collapse;
          
          th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid $border-color-light;
          }
          
          th {
            background-color: $background-light;
            font-weight: 600;
            color: $text-color-light;
          }
          
          tr:hover {
            background-color: $background-light;
          }
          
          .action-column {
            width: 100px;
            text-align: center;
          }
        }
      }
    }
    
    .GM__light-navigation-buttons {
      display: flex;
      justify-content: space-between;
      padding: 24px 32px;
      border-top: 1px solid $border-color-light;
      
      .GM__light-back-button {
        background-color: transparent;
        color: $text-color-light;
        border: 1px solid $border-color-light;
        
        &:hover {
          background-color: $background-light;
        }
        
        &:disabled {
          color: $text-color-muted-light;
          border-color: $border-color-lighter;
        }
      }
      
      .GM__light-next-button,
      .GM__light-submit-button {
        background-color: $accent-color-light;
        color: white;
        
        &:hover {
          background-color: $accent-hover-light;
        }
        
        &:disabled {
          background-color: lighten($accent-color-light, 20%);
        }
      }
    }
  }
}