// Variables para el ancho del menú lateral
$nav-collapsed-width: 56px;
$nav-expanded-width: 250px;

.route-map-page {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  
  &.nav-collapsed {
    .map-dashboard-container {
      position: absolute;
      left: $nav-collapsed-width;
      width: calc(100% - #{$nav-collapsed-width});
    }
  }
  
  &.nav-expanded {
    .map-dashboard-container {
      position: absolute;
      left: $nav-expanded-width;
      width: calc(100% - #{$nav-expanded-width});
    }
  }
}

.map-dashboard-container {
  height: 100%;
  z-index: 1;
  transition: left 0.3s ease, width 0.3s ease;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.marker-label {
  color: #333;
  background-color: white;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  white-space: pre-line;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.map-error,
.map-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2rem;
  color: #666;
}