.pdf-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #23262d;
  &-iframe {
    width: 100%;
    height: 100%; 
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  &-error {
    color: #d9534f;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    width: 90%;
    max-width: 600px;
    margin: auto;
  }
}
