@import '../../../views/variables.scss';

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .placeholder {
    @include flex-center;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    color: #666;
  }
}