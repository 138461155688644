// Mixins para patrones de estilo reutilizables
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin smooth-transition {
  transition: all 0.5s ease-in-out;
}

// Variables globales
$primary-color: #00A651;
$secondary-color: #ffffff;
$shadow-color: rgba(0, 0, 0, 0.15);
$transition-duration: 0.5s;

// Estilos de formulario compartidos
@mixin form-styles {
  .form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-title {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    
    .form-input {
      width: calc(100% - 60px);
      height: 48px;
      border-radius: 24px;
      border: 1px solid #E0E0E0;
      padding: 0 48px 0 16px;
      font-size: 14px;
      outline: none;
      transition: all 0.3s ease;
      
      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 2px rgba(0, 166, 81, 0.2);
      }
      
      &::placeholder {
        color: #9E9E9E;
      }
    }
    
    .input-icon {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      color: #9E9E9E;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .remember-me {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 8px 0;
    cursor: pointer;
    font-size: 14px;
    color: #757575;
    
    .checkbox {
      appearance: none;
      -webkit-appearance: none;
      width: 18px;
      height: 18px;
      border: 1px solid #E0E0E0;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      outline: none;
      
      &:checked {
        background-color: $primary-color;
        border-color: $primary-color;
        
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
        }
      }
    }
  }

  .form-button {
    width: 100%;
    height: 48px;
    border-radius: 24px;
    background-color: $primary-color;
    color: white;
    font-weight: 600;
    font-size: 14px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      background-color: darken($primary-color, 5%);
      transform: translateY(-2px);
    }
    
    &:disabled {
      background-color: #BDBDBD;
      cursor: not-allowed;
    }
  }

  .form-link {
    text-align: center;
    color: #757575;
    font-size: 14px;
    cursor: pointer;
    margin-top: 16px;
    
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .info-text {
    font-size: 14px;
    color: #757575;
    text-align: center;
    margin: 8px 0;
    font-style: italic;
  }

  .captcha-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
}

.login-screen {
  position: relative;
  height: calc(100vh - 82px);
  width: 100vw;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background: {
    image: url('../../img/martillo_bombeo_fondo.png');
    size: cover;
    position: center;
  }

  &-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: transparent;
  }

  // Versión Desktop
  .login-card {
    width: 800px;
    height: 500px;
    display: flex;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1);

    // Contenedor de Grid con Transición
    .grid-container {
      width: 100%;
      height: 100%;

      .MuiGrid-container {
        height: 100%
      }

      // Estados de Recuperación
      &.recovering {

        .login-content,
        .login-message {
          @include smooth-transition;
        }

        .login-content {
          order: 2;
        }

        .login-message {
          order: 1;
        }
      }

      // Modos de Columnas
      .login-content {
        padding: 20px;
        @include smooth-transition;
        @include form-styles;

        .content-wrapper {
          width: calc(100% - 40px);
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
        }

        // Modo de Login
        &.login-mode {
          order: 1;
        }

        // Modo de Recuperación
        &.recover-mode {
          order: 2;
        }
      }

      .login-message {
        background: $primary-color;
        padding: 20px;
        @include smooth-transition;
        height: 100%;

        // Modo de Login
        &.login-mode {
          order: 1;
        }

        // Modo de Recuperación
        &.recover-mode {
          order: 2;
        }

        // Título con sombreado
        .title-shadow {
          font-size: 2.5rem; // Tamaño ajustable
          font-weight: bold;
          text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
          color: #FFFFFF;
          margin-bottom: 20px;
        }

        .background-image-movement {
          width: 200px;
          height: 200px;
          background-image: url('../../img/nube_movimiento.png');
          background-size: 7200px 200px;
          background-repeat: no-repeat;
          margin: 0 auto;
          animation: backgroundMovement 4s steps(36) infinite;
        }

        @keyframes backgroundMovement {
          from {
            background-position: 0;
          }

          to {
            background-position: -7200px;
          }
        }

        // Imagen del Personaje
        .background-image-character {
          position: absolute;
          left: 13%;
          transform: translateX(-55%);
          transform: translateY(40%);
          z-index: 10;

          img {
            width: 200px;
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
          }
        }

        .logo-container {
          position: absolute;
          top: 260px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 20;

          .logo-image {
            width: 30px;
            height: auto;
            display: block;
            object-fit: contain;
          }
        }

        .logo-containerv2 {
          position: absolute;
          top: 270px;
          left: 51%;
          transform: translateX(-50%);
          z-index: 20;

          .logo-image {
            width: 30px;
            height: auto;
            display: block;
            object-fit: contain;
          }
        }
      }
    }

    .login-message {
      background-color: $primary-color;
      @include flex-center;
      flex-direction: column;
      text-align: center;
      position: relative;
      height: 100%;
      overflow: hidden;

      // Variaciones Tipográficas
      h5 {
        font-weight: 600;
        margin-bottom: 16px;
        color: #FFFFFF;
      }

      // Contenedor específico para la imagen del personaje
      .background-image-character {
        padding: 0 0 0 50px;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          display: block;
        }
      }
    }
  }

  // Estilos para la versión móvil
  &.mobile {
    background: $primary-color;

    // Diseño específico para versión móvil
    .login-card {
      display: none; // Ocultamos completamente el diseño desktop
    }

    .mobile-login-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      @include form-styles;

      .mobile-background {
        width: 100vw;
        height: calc(100vh - 80px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: #1E1E1E;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: transparent;
          z-index: 0;
        }

        // Imagen de onda SVG con posicionamiento absoluto
        .mobile-background-waves {
          position: absolute;
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          top:40%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          object-fit: cover;
          object-position: center;

        }
        // Contenedor de logo ya existente (ajustar z-index)
        .mobile-logo-container {
          z-index: 10;
          position: absolute;
          top:10%;
          left: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .mobile-logo {
          width: 200px; // Reducción del tamaño del logo (antes 100px)
          height: auto;
          filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2));
          margin-bottom: 16px;
        }
        .mobile-logo-text {
          color: $secondary-color;
          font-weight: 700;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          letter-spacing: 2px;
          text-align: center; // Garantiza centrado del texto
        }

        // Mantener el efecto de sombra inferior
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;
          background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
          z-index: 5;
        }
      }

      // Tarjeta deslizante desde abajo
      .mobile-card {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 350px;
        background-color: $secondary-color;
        border-radius: 24px 24px 0 0;
        box-shadow: 0 -4px 20px $shadow-color;
        z-index: 20;
        overflow: hidden;
        padding: 0;
        transform-origin: bottom center;

        // Contenedor del formulario
        .mobile-form-container {
          width: calc(100% - 40px);
          padding: 20px;
          display: flex;
          flex-direction: column;

          // Título del formulario
          .mobile-form-title {
            text-align: center;
            margin-bottom: 20px;
            font-weight: 600;
            color: #333;
          }
        }
      }
    }
  }
}

// Media query adicionales para asegurar compatibilidad
@media (max-width: 810px) {
  .mobile-login-container {
    .mobile-background {
      .mobile-background-waves {
        top: -20%; // Ajuste vertical para pantallas bajas
      }
    }
  }
}

// Optimizaciones para dispositivos muy pequeños
@media (max-width: 480px) {
  .mobile-login-container {
    .mobile-background {
      .mobile-background-waves {
        width: 140%;
        top: -10%;
      }
    }
  }
}
@media (max-height: 770px) {
  .login-screen {
    &.mobile {
      .mobile-login-container {
        .mobile-background {
          .mobile-logo-container {
            top: 1%; 
          }
        }
      }
    }
  }
}
@media (max-height: 690px) {
  .login-screen {
    &.mobile {
      .mobile-login-container {
        .mobile-background {
          .mobile-logo-container {
            .mobile-logo {
              width: 100px; // Reducción adicional del logo
              margin-bottom: 8px; // Menor margen
            }
            
            .mobile-logo-text {
              font-size: 1.5rem; // Texto más pequeño
              letter-spacing: 1px; // Menos espaciado
            }
          }
        }
      }
    }
  }
}
// Animaciones específicas para la transición entre estados
@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}