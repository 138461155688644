.contacto-section {
  width: 100%;
  background-color: #fff;
  padding: 3rem 0;
  
  @media (max-width: 576px) {
    padding: 2rem 0; // Reducir padding en móviles
  }
  
  .contacto-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
    
    @media (max-width: 576px) {
      padding: 0 1rem;
    }
  }
  
  .contacto-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-weight: 700;
    color: #333;
    
    &-highlight {
      color: #00a651;
    }
    
    @media (max-width: 768px) {
      font-size: 2.2rem;
      margin-bottom: 2.5rem;
    }
    
    @media (max-width: 576px) {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  }
  
  .contacto-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
    
    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
    
    // En móvil, ya está en columna por defecto, pero optimizamos
    @media (max-width: 576px) {
      gap: 1.5rem;
      margin-bottom: 2rem;
    }
    
    .contacto-image-container {
      flex: 1;
      background-color: #00a651;
      padding: 1.5rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      
      @media (max-width: 576px) {
        padding: 1rem;
        // Aumentamos la prioridad visual
        order: 1;
      }
      
      .contacto-image {
        max-width: 100%;
        height: auto;
        
        @media (max-width: 576px) {
          max-width: 85%; // Ligeramente más pequeña en móvil
        }
      }
    }
    
    .contacto-form-container {
      flex: 1;
      
      @media (max-width: 576px) {
        order: 2; // Después de la imagen en móvil
      }
      
      .contacto-form {
        .form-group {
          margin-bottom: 1.5rem;
          
          @media (max-width: 576px) {
            margin-bottom: 1.2rem;
          }
          
          label {
            display: block;
            margin-bottom: 0.5rem;
            color: #666;
            font-size: 0.9rem;
            
            @media (max-width: 576px) {
              font-size: 0.85rem;
              margin-bottom: 0.3rem;
            }
          }
          
          .form-control {
            width: 95%;
            padding: 0.75rem 0;
            border: 1px solid #ddd;
            border-radius: 0.25rem;
            background-color: #e8f5e9;
            
            @media (max-width: 576px) {
              width: 100%;
              padding: 0.6rem 0;
            }
            
            &:focus {
              outline: none;
              border-color: #00a651;
              box-shadow: 0 0 0 2px rgba(0, 166, 81, 0.2);
            }
          }
          
          textarea.form-control {
            resize: vertical;
            min-height: 100px;
            
            @media (max-width: 576px) {
              min-height: 80px; // Reducir altura mínima en móvil
            }
          }
        }
        
        .form-submit {
          display: flex;
          justify-content: flex-end;
          
          @media (max-width: 576px) {
            justify-content: center; // Centrar botón en móvil
          }
          
          .submit-button {
            background-color: #00a651;
            color: white;
            border: none;
            border-radius: 0.25rem;
            padding: 0.75rem 2rem;
            font-weight: 600;
            cursor: pointer;
            transition: background-color 0.2s;
            
            @media (max-width: 576px) {
              width: 100%; // Botón de ancho completo
              padding: 0.7rem 0;
            }
            
            &:hover {
              background-color: darken(#00a651, 10%);
            }
            
            &.enviando {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  
  .contacto-info {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 3rem;
    
    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }
    
    @media (max-width: 576px) {
      gap: 1.5rem;
      margin-bottom: 2rem;
      order: 3; // Después del formulario en móvil
    }
    
    .info-item {
      display: flex;
      align-items: flex-start;
      gap: 1rem;
      
      @media (max-width: 576px) {
        gap: 0.8rem;
      }
      
      .info-icon {
        width: 3rem;
        height: 3rem;
        background-color: rgba(0, 166, 81, 0.1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00a651;
        font-size: 1.5rem;
        
        @media (max-width: 576px) {
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.2rem;
        }
        
        img {
          width: 50%;
          height: 50%;
          
          @media (max-width: 576px) {
            width: 45%;
            height: 45%;
          }
        }
      }
      
      .info-content {
        h3 {
          margin: 0 0 0.5rem 0;
          font-size: 1.2rem;
          color: #333;
          
          @media (max-width: 576px) {
            font-size: 1rem;
            margin-bottom: 0.3rem;
          }
        }
        
        p {
          margin: 0;
          color: #666;
          line-height: 1.5;
          
          @media (max-width: 576px) {
            font-size: 0.9rem;
            line-height: 1.4;
          }
        }
      }
    }
  }
  
  .contacto-footer {
    padding-top: 2rem;
    
    @media (max-width: 576px) {
      padding-top: 1.5rem;
      order: 4; // Al final en móvil
    }
    
    .footer-divider {
      height: 1px;
      background-color: #eee;
      margin-bottom: 2rem;
      
      @media (max-width: 576px) {
        margin-bottom: 1.5rem;
      }
    }
    
    .footer-quote {
      text-align: center;
      font-style: italic;
      color: #666;
      margin-bottom: 1.5rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      
      @media (max-width: 576px) {
        font-size: 0.9rem;
        margin-bottom: 1.2rem;
      }
    }
    
    .footer-copyright {
      text-align: center;
      color: #999;
      font-size: 0.9rem;
      
      @media (max-width: 576px) {
        font-size: 0.8rem;
      }
    }
  }
}