.route-manager {
    /* Estilos existentes aquí */
    
    .coordinates-container {
      margin-top: 5px;
      
      .coordinate-inputs {
        display: flex;
        gap: 8px;
        margin-bottom: 8px;
        
        .coordinate-input {
          flex: 1;
          
          label {
            font-size: 0.8rem;
            color: #666;
            margin-bottom: 3px;
          }
          
          input {
            width: 100%;
          }
        }
      }
      
      .map-select-btn {
        width: 100%;
        background-color: #607d8b;
        color: white;
        padding: 6px 12px;
        
        &:hover {
          background-color: #546e7a;
        }
        
        &:disabled {
          background-color: #b0bec5;
          cursor: not-allowed;
        }
      }
    }
    
    .route-item {
      /* Estilos existentes aquí */
      
      &__waypoints {
        margin: 10px 0;
        padding: 8px;
        background-color: #f5f5f5;
        border-radius: 4px;
        
        .waypoint {
          margin-bottom: 5px;
          font-size: 0.9rem;
          
          &-label {
            font-weight: 500;
            margin-right: 5px;
            color: #555;
          }
          
          &-value {
            color: #777;
            font-family: monospace;
          }
        }
      }
    }
  }