// Variables para el tema claro
$background-light: #f5f7fa;
$background-lighter: #ffffff;
$border-color-light: #e0e6ed;
$border-color-lighter: #d1d9e6;
$text-color-light: #2c3e50;
$text-color-muted-light: #7f8c9a;
$accent-color-light: #4CAF50;
$accent-hover-light: #3e8e41;
$danger-color-light: #e74c3c;
$danger-hover-light: #c0392b;

.GM__light-vehicles-container {
  width: 100%;
  height: 87vh;
  background: $background-lighter;
  color: $text-color-light;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  .GM__light-vehicles-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background: $background-lighter;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    will-change: transform;
    
    &.header-hidden {
      transform: translateY(-100%);
      opacity: 0;
    }
    
    .GM__light-vehicles-title {
      font-size: 2.5rem;
      color: $text-color-light;
      padding-left: 20px;
    }
    
    .GM__light-vehicles-navigation {
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 1.5rem;
      
      .GM__light-navigation-item-clickable {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        color: $text-color-light;
        background-color: rgba(0, 0, 0, 0.05);
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
      
      .GM__light-navigation-item-current {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        color: $accent-color-light;
        background-color: rgba(76, 175, 80, 0.1);
      }
      
      .GM__light-navigation-separator {
        color: rgba(0, 0, 0, 0.3);
        font-size: 1.2rem;
      }
    }
    
    .GM__light-header-divider {
      width: 70%;
      height: 1px;
      background-color: $border-color-light;
      margin: 0;
    }
  }
  
  .GM__light-vehicles-content {
    flex: 1;
    overflow-y: auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    height: 87vh;
    margin-top: 0;
    background-color: $background-light;
    display: flex;
    flex-direction: column; 
    
    &.header-hidden {
      height: 100%;
      margin-top: calc(var(--header-height, 200px) * -1);
    }
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $background-lighter;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $border-color-light;
      border-radius: 4px;
      
      &:hover {
        background: $border-color-lighter;
      }
    }
  }
}