/* LocationSelector.scss */

/* Variables de color (ejemplo) */
$dark-bg: #1e1e1e;
$text-color: #f0f0f0;
$input-bg: #2a2a2a;
$border-color: #444;
$primary-color: #4caf50; /* Puedes cambiar este color a tu gusto */
$error-color: #ff4444;

/* Contenedor principal */
.location-selector {
  background-color: $dark-bg;
  color: $text-color;
  padding: 1.5rem;
  border-radius: 6px;
  max-width: 100%; /* Que ocupe todo el ancho disponible */
  margin: 0 auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);

  /* Filas flex */
  .flex-row {
    display: flex;
    flex-wrap: wrap; /* Para que en móviles se haga columnar si no cabe */
    margin-bottom: 1rem;

    /* Espaciado entre los elementos dentro de la fila */
    > .field-group {
      flex: 1; /* Cada uno ocupa el espacio disponible */
      min-width: 180px; /* Evitar que se haga demasiado pequeño */
      margin-right: 1rem;
      margin-bottom: 1rem; /* Para móviles cuando se apile */
    }

    /* Último elemento no necesita margen-right */
    > .field-group:last-child {
      margin-right: 0;
    }
  }

  /* Fila 1 (País, Departamento, Ciudad) queda con flex: 1 para cada uno */
  .row-1 {
    /* Si deseas ajustar algo distinto para la primera fila, se hace aquí */
  }

  /* Fila 2: Dirección y Código Postal */
  .row-2 {
    /* Dirección y código postal */
    .field-address {
      flex: 3; /* Dirección más grande */
    }

    .field-postal {
      flex: 1; /* Código postal más pequeño */
      max-width: 200px; /* Opcional, para no crecer demasiado */
    }
  }

  /* Fila 3: Botón y mensajes de estado */
  .row-3 {
    display: flex;
    flex-direction: column; /* Botón y mensaje uno debajo de otro */
    align-items: flex-start; /* Alinear a la izquierda, si prefieres al centro ajusta aquí */

    .save-button {
      margin-bottom: 0.5rem;
    }
  }

  /* Estilos de los elementos de formulario */
  .field-group {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }

    select,
    input,
    textarea {
      background-color: $input-bg;
      color: $text-color;
      border: 1px solid $border-color;
      border-radius: 4px;
      padding: 0.5rem;
      font-size: 1rem;

      &:focus {
        outline: none;
        border-color: $primary-color;
      }
    }

    textarea {
      resize: vertical;
    }
  }

  .save-button {
    padding: 0.75rem 1.5rem;
    background-color: $primary-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;

    &:hover:not(:disabled) {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      background-color: gray;
      cursor: not-allowed;
    }
  }

  /* Mensajes de estado (uploaded o error) */
  .status-message {
    margin-top: 0.5rem;
    font-size: 0.9rem;

    &.status-uploaded {
      color: $primary-color;
    }

    &.status-error {
      color: $error-color;
    }
  }
}

/* Responsividad específica (media queries) */
/* Puedes personalizar estas medidas a tu gusto */
@media (max-width: 768px) {
  .location-selector {
    .flex-row {
      flex-direction: column; /* En pantallas pequeñas, apilamos */
    }

    .row-3 {
      align-items: stretch; /* Para que el botón ocupe todo el ancho */
    }
  }
}
