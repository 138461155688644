// Modificación para DocumentLayout.scss
.document-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 99%;
  overflow-y: auto;
  
  &.dark-theme {
    background: #222;
    color: #f0f0f0;
  }

  .document-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .document-content {
    flex: 1;
    padding: 20px;
    background-color: #f5f5f5;
  }
}