// assets/scss/components/GeneralParametersSection.scss
@import "../variables";

// Variables específicas para parámetros
$speed-column-width: 35%;
$time-column-width: 35%;
$operation-column-width: 30%;

// Estilos base compartidos
.GM__general-parameters-section {
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
  
  h3 {
    font-weight: 600;
    margin: 0;
    padding: 1rem;
    text-align: center;
    color: white;
    background-color: var(--primary-color);
  }
  
  h4 {
    margin: 0 0 1rem 0;
    font-weight: 500;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .parameters-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
    gap: 1.5rem;
    
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  
  // Estilos para columnas
  .speeds-column, .times-column, .operation-column {
    flex: 1;
    border-radius: 8px;
    background-color: var(--section-bg);
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    
    @media (max-width: 992px) {
      width: 100%;
    }
  }
  
  // Estilos específicos para columna de velocidades
  .speeds-column {
    min-width: 300px;
    
    .speed-headers {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;
      
      .speed-header {
        width: 80px;
        text-align: center;
        font-weight: 500;
        font-size: 0.9rem;
        color: var(--text-muted);
      }
    }
  }
  
  // Listas de elementos
  .speeds-list, .times-list, .operation-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  // Elementos de velocidad
  .speed-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--border-color);
    
    &:last-child {
      border-bottom: none;
    }
    
    .terrain-icon {
      width: 24px;
      height: 24px;
      margin-right: 1rem;
      
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .terrain-name {
      flex: 1;
    }
    
    .speed-value {
      width: 80px;
      text-align: center;
      font-weight: 600;
    }
  }
  
  // Elementos de tiempo
  .time-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--border-color);
    
    &:last-child {
      border-bottom: none;
    }
    
    .time-icon {
      width: 24px;
      height: 24px;
      margin-right: 1rem;
      
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .time-name {
      flex: 1;
      padding-right: 0.5rem;
    }
    
    .time-value {
      min-width: 100px;
      text-align: right;
      font-weight: 600;
    }
  }
  
  // Elementos de operación
  .operation-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--border-color);
    
    &:last-child {
      border-bottom: none;
    }
    
    .operation-icon {
      width: 24px;
      height: 24px;
      margin-right: 1rem;
      
      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .operation-name {
      flex: 1;
    }
    
    .operation-value {
      min-width: 100px;
      text-align: right;
      font-weight: 600;
    }
  }
  
  // Estados de carga y error
  &.loading, &.no-data, &.error {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .loading-indicator {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .spinner {
        width: 40px;
        height: 40px;
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        border-top: 4px solid var(--primary-color);
        animation: spin 1s linear infinite;
        margin-bottom: 1rem;
      }
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

// Temas específicos
.GM__light-general-parameters-section {
  @extend .GM__general-parameters-section;
  --primary-color: #00a650;
  --section-bg: #ffffff;
  --border-color: #e0e0e0;
  --text-muted: #666666;
  
  .spinner {
    border-top-color: #00a650;
  }
}

.GM__dark-general-parameters-section {
  @extend .GM__general-parameters-section;
  --primary-color: #00c65f;
  --section-bg: #2a2a2a;
  --border-color: #444;
  --text-muted: #aaaaaa;
  
  h4 {
    color: #f0f0f0;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-top-color: #00c65f;
  }
  
  .terrain-name, .time-name, .operation-name {
    color: #d0d0d0;
  }
  
  .speed-value, .time-value, .operation-value {
    color: #ffffff;
  }
}