// Variables para el tema claro
$background-light: #f5f7fa;
$surface-light: #ffffff;
$border-color-light: #e0e6ed;
$text-primary-light: #2c3e50;
$text-secondary-light: #7f8c9a;
$accent-color-light: #4CAF50;
$input-background-light: #ffffff;
$input-border-light: #d1d9e6;
$input-focus-light: #4CAF50;
$input-hover-light: #f0f4f8;
$label-color-light: #2c3e50;
$divider-light: #e0e6ed;
$shadow-light: 0 2px 8px rgba(0, 0, 0, 0.08);

.GM__light-contact-section {
  padding: 28px 0;
  background-color: $surface-light;
  
  .GM__light-section-title {
    font-size: 22px;
    font-weight: 600;
    color: $text-primary-light;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color-light;
  }
  
  .GM__light-subsection-title {
    font-size: 18px;
    font-weight: 600;
    color: $text-primary-light;
    margin: 24px 0 16px 0;
  }
  
  .GM__light-contact-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  
  .GM__light-input-group {
    margin-bottom: 8px;
    
    .GM__light-input-label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $label-color-light;
    }
    
    // Estilos para inputs personalizados
    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid $input-border-light;
      border-radius: 8px;
      font-size: 14px;
      color: $text-primary-light;
      background-color: $input-background-light;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      
      &:hover {
        background-color: $input-hover-light;
      }
      
      &:focus {
        outline: none;
        border-color: $input-focus-light;
        box-shadow: 0 0 0 2px rgba($input-focus-light, 0.1);
      }
      
      &::placeholder {
        color: $text-secondary-light;
      }
    }
  }
  
  .GM__light-phone-group {
    grid-column: span 2;
  }
  
  .GM__light-phone-input-container {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    
    .GM__light-sort-dropdown {
      width: 160px;
      flex-shrink: 0;
      
      .dropdown-toggle {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $input-border-light;
        border-radius: 8px;
        font-size: 14px;
        color: $text-primary-light;
        background-color: $input-background-light;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: $input-hover-light;
        }
      }
      
      .dropdown-menu {
        border: 1px solid $border-color-light;
        border-radius: 8px;
        box-shadow: $shadow-light;
        background-color: $surface-light;
        
        .dropdown-item {
          color: $text-primary-light;
          padding: 8px 12px;
          
          &:hover {
            background-color: $input-hover-light;
          }
          
          &.active {
            background-color: rgba($accent-color-light, 0.1);
            color: $accent-color-light;
          }
        }
      }
    }
    
    input {
      flex-grow: 1;
    }
  }
  
  .GM__light-location-section {
    margin-top: 32px;
    padding-top: 20px;
    border-top: 1px solid $divider-light;
    
    .location-map-container {
      margin-top: 16px;
      border: 1px solid $border-color-light;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: $shadow-light;
      
      .map-search-input {
        border: 1px solid $input-border-light;
        padding: 10px;
        font-size: 14px;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 12px;
        
        &:focus {
          border-color: $accent-color-light;
          outline: none;
        }
      }
    }
  }
  
  // Estilos para CustomDatePicker
  .GM__light-datepicker-wrapper {
    position: relative;
    
    input {
      padding-right: 36px;
    }
    
    .datepicker-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      pointer-events: none;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  
  // Estilos para react-datepicker
  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  
  .react-datepicker {
    font-family: inherit;
    border: 1px solid $border-color-light;
    border-radius: 8px;
    box-shadow: $shadow-light;
    
    .react-datepicker__header {
      background-color: $input-hover-light;
      border-bottom: 1px solid $border-color-light;
    }
    
    .react-datepicker__day--selected {
      background-color: $accent-color-light;
      color: white;
    }
    
    .react-datepicker__day:hover {
      background-color: $input-hover-light;
    }
  }
}