// assets/scss/components/RouteCharacteristicsSection.scss
@import "../variables";

// Estilos compartidos entre temas
.GM__route-characteristics-section {
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 8px;
  
  // Resto del código sin cambios...
  
  .characteristics-container {
    display: flex;
    flex-wrap: nowrap; // ¡CAMBIO IMPORTANTE! - Evita que los elementos se envuelvan
    gap: 1.5rem;
    width: 100%; // Asegura que el contenedor ocupe todo el ancho disponible
    
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  
  .topography-column {
    flex: 1;
    min-width: 250px;
    max-width: 30%;
  }
  
  .map-column {
    flex: 2;
    width: 40%;
    min-width: 350px;
    position: relative;
    
    .map-container {
      height: 300px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
    }
  }
  
  .chart-column {
    flex: 1;
    min-width: 250px;
    max-width: 30%;
    display: flex;
    flex-direction: column;
  }
  
  
  .topography-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .topography-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
    
    &:last-child {
      border-bottom: none;
    }
    
    &.zero-segment {
      opacity: 0.5;
    }
  }
  
  .topography-icon {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .topography-name {
    flex: 1;
  }
  
  .topography-distance {
    font-weight: bold;
    text-align: right;
    min-width: 80px;
  }
  
  .map-container {
    height: 300px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .chart-container {
    height: 200px;
    position: relative;
    margin-bottom: 1rem;
  }
  
  .chart-legend {
    margin-top: auto;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .legend-color {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  
  .loading-indicator, .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    text-align: center;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid var(--primary-color);
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
  @media (max-width: 1200px) {
    .characteristics-container {
      flex-wrap: wrap; // En pantallas medianas permitimos wrap
    }
    
    .topography-column,
    .map-column,
    .chart-column {
      flex: 1 0 45%; // En tablets, 2 columnas por fila
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .topography-column,
    .map-column,
    .chart-column {
      flex: 1 0 100%; // En móviles, 1 columna ocupa todo el ancho
    }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

// Tema claro
.GM__light-route-characteristics-section {
  @extend .GM__route-characteristics-section;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  --primary-color: #00a650;
  --border-color: #e0e0e0;
  
  .topography-name {
    color: #333;
  }
  
  .spinner {
    border-top-color: #00a650;
  }
}

// Tema oscuro
.GM__dark-route-characteristics-section {
  @extend .GM__route-characteristics-section;
  background-color: #2a2a2a;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  
  --primary-color: #00c65f;
  --border-color: #444;
  
  h3, h4 {
    color: #f0f0f0;
  }
  
  .topography-name {
    color: #d0d0d0;
  }
  
  .topography-distance {
    color: #fff;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-top-color: #00c65f;
  }
  
  .legend-text {
    color: #d0d0d0;
  }
}