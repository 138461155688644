// DocumentRequirementsManagerDark.scss - Tema oscuro para el gestor de requisitos

// Variables para el tema oscuro
$background-dark: #121212;
$card-bg-dark: #1e1e1e;
$panel-bg-dark: #252525;
$primary-color-dark: #90caf9;
$primary-hover-dark: #64b5f6;
$success-color-dark: #81c784;
$warning-color-dark: #ffb74d;
$danger-color-dark: #e57373;
$text-primary-dark: #e0e0e0;
$text-secondary-dark: #9e9e9e;
$border-color-dark: #333333;
$hover-bg-dark: #303030;
$selected-bg-dark: rgba(144, 202, 249, 0.15);
$disabled-bg-dark: #2c2c2c;
$disabled-text-dark: #666666;
$chip-bg-dark: #424242;
$chip-text-dark: #e0e0e0;
$required-bg-dark: rgba(129, 199, 132, 0.15);
$not-required-bg-dark: rgba(229, 115, 115, 0.15);
$scrollbar-track-dark: #1a1a1a;
$scrollbar-thumb-dark: #444444;
$scrollbar-thumb-hover-dark: #555555;
$divider-dark: #333333;
$tab-active-color-dark: #90caf9;
$tab-inactive-color-dark: #9e9e9e;

// Contenedor principal
.GM__dark-document-requirements-manager {
  @extend %flex-container;
  background-color: $background-dark;
  padding: var(--spacing-md);
  min-height: 600px;
  width: 100%;
  box-sizing: border-box;
  
  .GM__dark-header-section {
    margin-bottom: var(--spacing-lg);
    
    .GM__dark-section-title {
      font-size: 1.75rem;
      color: $text-primary-dark;
      margin: 0 0 var(--spacing-sm) 0;
      font-weight: 600;
    }
  }
  
  .GM__dark-tabs-container {
    @extend %flex-container;
    background-color: $card-bg-dark;
    @extend %card-base;
    flex: 1;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    
    .GM__dark-configuration-tabs {
      background-color: $card-bg-dark;
      border-bottom: 1px solid $border-color-dark;
      
      .GM__dark-tab {
        color: $tab-inactive-color-dark;
        font-size: 0.95rem;
        
        &.Mui-selected {
          color: $tab-active-color-dark;
          font-weight: 600;
        }
      }
      
      .MuiTabs-indicator {
        background-color: $tab-active-color-dark;
      }
    }
    
    .GM__dark-tab-content {
      padding: var(--spacing-md);
      overflow: auto;
      flex: 1;
      
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-dark;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-dark;
        
        &:hover {
          background: $scrollbar-thumb-hover-dark;
        }
      }
    }
  }
}

// Componentes de configuración por operación
.GM__dark-operation-config-tab {
  @extend %flex-row;
  gap: var(--spacing-lg);
  height: 100%;
  
  .GM__dark-operation-list {
    width: 280px;
    background-color: $panel-bg-dark;
    @extend %card-base;
    @extend %flex-container;
    padding: var(--spacing-md);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    
    .GM__dark-list-title {
      font-size: 1.1rem;
      color: $text-primary-dark;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__dark-scrollable-list {
      @extend %flex-container;
      overflow-y: auto;
      flex: 1;
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-dark;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-dark;
        
        &:hover {
          background: $scrollbar-thumb-hover-dark;
        }
      }
      
      .GM__dark-list-item {
        padding: var(--spacing-md);
        border-radius: var(--input-radius);
        cursor: pointer;
        transition: background-color var(--transition-speed) ease;
        color: $text-primary-dark;
        margin-bottom: var(--spacing-xs);
        
        &:hover {
          background-color: $hover-bg-dark;
        }
        
        &.GM__dark-selected {
          background-color: $selected-bg-dark;
          font-weight: 500;
          color: $primary-color-dark;
        }
      }
    }
  }
  
  .GM__dark-operation-detail {
    flex: 1;
    
    .GM__dark-detail-panel {
      @extend %flex-container;
      @extend %card-base;
      background-color: $panel-bg-dark;
      padding: var(--spacing-md);
      height: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

// Componentes de configuración por tipo de vehículo
.GM__dark-vehicle-type-config-tab {
  @extend %flex-row;
  gap: var(--spacing-lg);
  height: 100%;
  
  .GM__dark-vehicle-type-list {
    width: 300px;
    background-color: $panel-bg-dark;
    @extend %card-base;
    @extend %flex-container;
    padding: var(--spacing-md);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    
    .GM__dark-list-title {
      font-size: 1.1rem;
      color: $text-primary-dark;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__dark-scrollable-list {
      @extend %flex-container;
      overflow-y: auto;
      flex: 1;
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-dark;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-dark;
        
        &:hover {
          background: $scrollbar-thumb-hover-dark;
        }
      }
      
      .GM__dark-vehicle-accordion {
        margin-bottom: var(--spacing-sm);
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $border-color-dark;
        border-radius: var(--input-radius);
        
        .GM__dark-accordion-summary {
          padding: var(--spacing-sm) var(--spacing-md);
          min-height: auto;
          
          .GM__dark-category-title {
            font-size: 1rem;
            margin: 0;
            color: $text-primary-dark;
            font-weight: 500;
          }
        }
        
        .GM__dark-accordion-details {
          padding: 0 var(--spacing-md) var(--spacing-sm);
          
          .GM__dark-category-items {
            @extend %flex-container;
            
            .GM__dark-list-item {
              padding: var(--spacing-md);
              border-radius: var(--input-radius);
              cursor: pointer;
              transition: background-color var(--transition-speed) ease;
              color: $text-primary-dark;
              margin-bottom: var(--spacing-xs);
              
              &:hover {
                background-color: $hover-bg-dark;
              }
              
              &.GM__dark-selected {
                background-color: $selected-bg-dark;
                font-weight: 500;
                color: $primary-color-dark;
              }
            }
          }
        }
      }
    }
  }
  
  .GM__dark-vehicle-type-detail {
    flex: 1;
    
    .GM__dark-detail-panel {
      @extend %flex-container;
      @extend %card-base;
      background-color: $panel-bg-dark;
      padding: var(--spacing-md);
      height: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}

// Estilos compartidos de paneles de detalle
.GM__dark-detail-panel {
  .GM__dark-detail-header {
    @extend %flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
    
    .GM__dark-detail-title {
      font-size: 1.3rem;
      color: $text-primary-dark;
      margin: 0;
      font-weight: 600;
    }
    
    .GM__dark-configure-button {
      background-color: $primary-color-dark;
      color: $background-dark;
      border: none;
      padding: var(--spacing-sm) var(--spacing-md);
      border-radius: var(--input-radius);
      cursor: pointer;
      font-weight: 500;
      transition: background-color var(--transition-speed) ease;
      
      &:hover {
        background-color: $primary-hover-dark;
      }
    }
  }
  
  .GM__dark-detail-info {
    @extend %flex-container;
    margin-bottom: var(--spacing-lg);
    
    .GM__dark-info-row {
      @extend %flex-row;
      margin-bottom: var(--spacing-md);
      
      .GM__dark-info-label {
        font-weight: 500;
        color: $text-secondary-dark;
        width: 180px;
      }
      
      .GM__dark-info-value {
        color: $text-primary-dark;
      }
    }
  }
  
  .GM__dark-document-list-section {
    .GM__dark-section-subtitle {
      font-size: 1.1rem;
      color: $text-primary-dark;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__dark-document-chips {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-sm);
      
      .GM__dark-document-chip {
        background-color: $chip-bg-dark;
        color: $chip-text-dark;
        border-radius: 16px;
        padding: 4px 12px;
        font-size: 0.85rem;
        
        &.MuiChip-root {
          height: 28px;
        }
      }
    }
    
    .GM__dark-no-documents-message {
      color: $text-secondary-dark;
      font-style: italic;
    }
  }
}

// Matriz Comparativa
.GM__dark-comparison-matrix-tab {
  @extend %flex-container;
  gap: var(--spacing-lg);
  
  .GM__dark-operation-selection {
    background-color: $panel-bg-dark;
    @extend %card-base;
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    
    .GM__dark-section-subtitle {
      font-size: 1.1rem;
      color: $text-primary-dark;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__dark-operation-checkboxes {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-md);
      
      .GM__dark-operation-checkbox-label {
        margin: 0;
        color: $text-primary-dark;
        
        .GM__dark-operation-checkbox {
          color: $primary-color-dark;
          
          &.Mui-checked {
            color: $primary-color-dark;
          }
        }
      }
    }
  }
  
  .GM__dark-matrix-container {
    @extend %flex-container;
    flex: 1;
    
    .GM__dark-instruction-message {
      text-align: center;
      color: $text-secondary-dark;
      padding: var(--spacing-xl) 0;
    }
    
    .GM__dark-error-message {
      text-align: center;
      color: $danger-color-dark;
      padding: var(--spacing-xl) 0;
    }
    
    .GM__dark-comparison-table-wrapper {
      @extend %flex-container;
      background-color: $panel-bg-dark;
      @extend %card-base;
      padding: var(--spacing-md);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
      
      .GM__dark-matrix-title {
        font-size: 1.2rem;
        color: $text-primary-dark;
        margin: 0 0 var(--spacing-md) 0;
        font-weight: 600;
      }
      
      .GM__dark-vehicle-table-wrapper-table {
        overflow-x: auto;
        @extend %scrollbar-base;
        
        &::-webkit-scrollbar-track {
          background: $scrollbar-track-dark;
        }
        
        &::-webkit-scrollbar-thumb {
          background: $scrollbar-thumb-dark;
          
          &:hover {
            background: $scrollbar-thumb-hover-dark;
          }
        }
        
        .GM__dark-vehicle-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
          
          th, td {
            padding: var(--spacing-md);
            border-bottom: 1px solid $border-color-dark;
            
            &.fixed-column {
              position: sticky;
              left: 0;
              background-color: $panel-bg-dark;
              z-index: 10;
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.25);
            }
          }
          
          th {
            text-align: left;
            background-color: $hover-bg-dark;
            font-weight: 600;
            color: $text-primary-dark;
            
            &.fixed-column {
              z-index: 11;
              background-color: $hover-bg-dark;
            }
          }
          
          .GM__dark-document-name {
            font-weight: 500;
            color: $text-primary-dark;
          }
          
          .GM__dark-requirement-cell {
            text-align: center;
            
            .GM__dark-required-indicator {
              background-color: $required-bg-dark;
              color: $success-color-dark;
              padding: 4px 8px;
              border-radius: var(--input-radius);
              font-size: 0.85rem;
              font-weight: 500;
            }
            
            .GM__dark-not-required-indicator {
              background-color: $not-required-bg-dark;
              color: $danger-color-dark;
              padding: 4px 8px;
              border-radius: var(--input-radius);
              font-size: 0.85rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

// Diálogo de selección de documentos
.GM__dark-document-selection-modal {
  min-width: 600px;
  max-width: 90vw;
  
  .GM__dark-modal-header-container {
    padding: var(--spacing-md) var(--spacing-lg);
    border-bottom: 1px solid $border-color-dark;
    
    .GM__dark-modal-header {
      font-size: 1.3rem;
      color: $text-primary-dark;
      margin: 0;
      font-weight: 600;
    }
  }
  
  .GM__dark-modal-content {
    padding: var(--spacing-lg);
    max-height: 60vh;
    overflow-y: auto;
    @extend %scrollbar-base;
    
    &::-webkit-scrollbar-track {
      background: $scrollbar-track-dark;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-thumb-dark;
      
      &:hover {
        background: $scrollbar-thumb-hover-dark;
      }
    }
    
    .GM__dark-selection-instruction {
      margin: 0 0 var(--spacing-md) 0;
      color: $text-secondary-dark;
    }
    
    .GM__dark-document-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: var(--spacing-md);
      
      .GM__dark-document-checkbox-item {
        .GM__dark-document-checkbox-label {
          margin: 0;
          display: flex;
          align-items: flex-start;
          color: $text-primary-dark;
          
          .GM__dark-document-checkbox {
            color: $primary-color-dark;
            padding: 4px;
            margin-right: 8px;
            
            &.Mui-checked {
              color: $primary-color-dark;
            }
          }
          
          .GM__dark-document-label {
            display: flex;
            align-items: center;
            
            .GM__dark-document-name-label {
              font-size: 0.95rem;
              color: $text-primary-dark;
            }
            
            .GM__dark-info-icon {
              margin-left: var(--spacing-xs);
              font-size: 1rem;
              color: $text-secondary-dark;
              cursor: help;
            }
          }
        }
      }
    }
  }
  
  .GM__dark-modal-footer {
    padding: var(--spacing-md) var(--spacing-lg);
    border-top: 1px solid $border-color-dark;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-md);
    
    .GM__dark-btn {
      padding: var(--spacing-sm) var(--spacing-lg);
      border-radius: var(--input-radius);
      font-weight: 500;
      cursor: pointer;
      border: none;
      transition: background-color var(--transition-speed) ease;
      
      &.GM__dark-btn-cancel {
        background-color: $hover-bg-dark;
        color: $text-primary-dark;
        
        &:hover {
          background-color: lighten($hover-bg-dark, 5%);
        }
      }
      
      &.GM__dark-btn-save {
        background-color: $primary-color-dark;
        color: $background-dark;
        
        &:hover {
          background-color: $primary-hover-dark;
        }
        
        &:disabled {
          background-color: $disabled-bg-dark;
          color: $disabled-text-dark;
          cursor: not-allowed;
        }
      }
    }
  }
}

// Estados vacíos y de error
.GM__dark-empty-state {
  text-align: center;
  padding: var(--spacing-xl) 0;
  color: $text-secondary-dark;
  
  p {
    margin: var(--spacing-sm) 0;
  }
}

.GM__dark-error-state {
  color: $danger-color-dark;
  text-align: center;
  padding: var(--spacing-xl) 0;
}