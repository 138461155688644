.users-rol-card {
    position: relative;
    width: 300px;
    height: 150px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(191, 149, 149, 0.25);
    overflow: hidden;
  
    .card-header {
      position: relative;
      height: 30%;
      display: flex;
    
      .column {
        position: relative;
        height: 100%;
        background: transparent;
    
        &.first {
          width: 120px;
          position: relative;
          border-radius: 0 0 0 0;
    
          .first-margen {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10px;
            border-radius: 10px 0 10px 0;
            z-index: 10;
          }
          .first-margenv1 {
            padding: 6px 0px 0px 15px;
            font-size: 20px;
          }
        }
    
        &.second {
          flex: 1;
          position: relative;
          border-radius: 10 0 0 0;
          overflow: visible;
        }
    
        .second-margen {
          position: absolute;
          width: 100%;
          height: 10px;
          border-radius: 10px 10px 0 0;
        }
        .second-content {
          width: 182px;
          left: -2px;
          position: absolute;
          height: 100%;
          border-radius: 10px 10px 0 0;
        }
        .second-margenv2 {
          position: absolute;
          bottom: 10px;
          left: -15px;
          width: 160px;
          height: 15px;
          
          &::before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: -5px;
            left: -7px;
            border-radius: 0 0 10px 0;
          }
        }
        .second-margenv3 {
          position: absolute;
          bottom: 0px;
          left: -10px;
          width: 160px;
          height: 10px;
        }
      }
    }
    
    .card-content {
      position: relative;
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .image-wrapper {
        transform: rotate(45deg);
        position: relative;
        margin-left: 10px;
  
        .image-border {
          width: 64px;
          height: 64px;
          background: #ffffff;
          border-width: 2px;
          border-style: solid;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          
          & img {
            transform: rotate(-45deg);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
  
      .info-wrapper {
        flex: 1;
        margin-left: 20px;
  
        .info {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
  
          .line {
            width: 4px;
            height: 20px;
            margin-right: 10px;
          }
  
          .text {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }