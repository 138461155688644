// Variables para tema oscuro
$dark-background: #1e1e2f;
$dark-text-color: #e0e0e0;
$dark-secondary-color: #a0a0a0;
$dark-hover-background: rgba(97, 97, 97, 0.18);
$dark-border-color: #444444;
$dark-header-bg: #2A2D34;
$dark-error-color: #ff6666;
$dark-icon-size: 24px;
$dark-spacing-unit: 8px;

// Temas específicos para botones
$dark-preview-btn-bg: #333333;
$dark-preview-btn-hover-bg: #444444;
$dark-update-btn-bg: #133a5e;
$dark-update-btn-hover-bg: #1c4f7e;
$dark-delete-btn-bg: #4e2c2c;
$dark-delete-btn-hover-bg: #663333;
$dark-comment-btn-bg: #2c2c4e;
$dark-comment-btn-hover-bg: #3a3a66;
$dark-save-btn-bg: #2c4e2c;
$dark-save-btn-hover-bg: #336633;

.GM__dark-documents-section {
  padding: 20px;
  color: $dark-text-color;
  
  .GM__dark-document-type-table {
    margin-bottom: 30px;
    border: 1px solid $dark-border-color;
    border-radius: 8px;
    overflow: hidden;
    background-color: $dark-background;

    .GM__dark-table-header {
      background-color: $dark-header-bg;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $dark-border-color;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: $dark-text-color;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: $dark-secondary-color;
        flex-grow: 1;
        padding: 0 20px;
      }

      .GM__dark-add-document-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        transition: transform 0.2s ease;

        &:hover {
          transform: translateY(-2px);
        }

        img {
          width: 20px;
          height: 20px;
          filter: invert(1); // Invertir color para iconos oscuros
        }
      }
    }

    .GM__dark-table {
      width: 100%;
      border-collapse: collapse;
      background-color: $dark-background;

      th {
        background-color: #232336;
        padding: 12px;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        color: $dark-text-color;
        border-bottom: 2px solid $dark-border-color;
      }

      td {
        padding: 12px;
        border-bottom: 1px solid $dark-border-color;
        vertical-align: middle;
        color: $dark-text-color;

        &:first-child {
          width: 60px;
          text-align: center;

          .GM__dark-status-icon {
            width: 24px;
            height: 24px;
            filter: brightness(0.8); // Reducir brillo para ajustar a tema oscuro
          }
        }
      }
      
      .GM__dark-action-buttons {
        padding: 15px;
        border-bottom: 1px solid $dark-border-color;
        vertical-align: middle;
        min-width: 150px;
        
        .GM__dark-action-buttons-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: flex-start;
          
          button {
            flex: 0 0 calc(33.33% - 6px);
            min-width: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 4px;
            transition: background-color 0.2s ease, transform 0.1s ease;
            
            &:hover {
              transform: translateY(-2px);
            }
            
            .GM__dark-action-icon {
              width: 20px;
              height: 20px;
              object-fit: contain;
              filter: brightness(0.9); // Ajustar brillo para iconos en tema oscuro
            }
          }
          
          .GM__dark-preview-btn {
            background-color: $dark-preview-btn-bg;
            &:hover {
              background-color: $dark-preview-btn-hover-bg;
            }
          }
          
          .GM__dark-update-btn {
            background-color: $dark-update-btn-bg;
            &:hover {
              background-color: $dark-update-btn-hover-bg;
            }
          }
          
          .GM__dark-save-btn {
            background-color: $dark-save-btn-bg;
            &:hover {
              background-color: $dark-save-btn-hover-bg;
            }
          }
          
          .GM__dark-delete-btn {
            background-color: $dark-delete-btn-bg;
            &:hover {
              background-color: $dark-delete-btn-hover-bg;
            }
          }
          
          .GM__dark-coment-btn {
            background-color: $dark-comment-btn-bg;
            &:hover {
              background-color: $dark-comment-btn-hover-bg;
            }
          }
        }
      }

      .GM__dark-file-upload {
        .GM__dark-file-label {
          display: inline-block;
          padding: 8px 12px;
          background-color: #333333;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          color: $dark-text-color;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #444444;
          }
        }
      }

      .GM__dark-file-name {
        color: $dark-text-color;
      }

      .GM__dark-checkbox {
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-color: #333333;
        border: 1px solid #555555;
        
        &:checked {
          accent-color: #2c7be5;
        }
      }

      tr {
        &:hover {
          background-color: rgba(70, 70, 70, 0.3);
        }

        &:last-child td {
          border-bottom: none;
        }
      }
      
      .GM__dark-empty-document {
        .GM__dark-no-documents {
          text-align: center;
          color: $dark-secondary-color;
          padding: 20px;
        }
      }
    }
  }

  // Estilos específicos para los componentes personalizados
  .GM__dark-input-field,
  .GM__dark-date-picker {
    width: 100%;
    color: $dark-text-color;
    border-color: #555555;
  }
  
  .GM__dark-input-field {
    min-width: 200px;
  }
  
  .GM__dark-date-picker {
    min-width: 150px;
  }
}

// Media queries
@media screen and (max-width: 768px) {
  .GM__dark-documents-section {
    padding: 16px;
    
    .GM__dark-document-type-table {
      .GM__dark-table-header {
        flex-direction: column;
        gap: 8px;
        
        h3, p {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__dark-documents-section {
    padding: 12px;
  }
}