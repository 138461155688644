// Importar los archivos de temas
@import './subdivisions/AddVehicleLight.scss';
@import './subdivisions/AddVehicleDark.scss';

// Media queries compartidas para ambos temas
@media screen and (max-width: 1200px) {
  .GM__light-add-vehicle-container,
  .GM__dark-add-vehicle-container {
    .GM__light-vehicle-form-container,
    .GM__dark-vehicle-form-container {
      width: 95%;
    }
    
    .GM__light-step-content,
    .GM__dark-step-content {
      .main-section {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .GM__light-add-vehicle-container,
  .GM__dark-add-vehicle-container {
    .GM__light-stepper,
    .GM__dark-stepper {
      overflow-x: auto;
      padding-bottom: 16px;
    }
    
    .GM__light-navigation-buttons,
    .GM__dark-navigation-buttons {
      flex-direction: column;
      gap: 16px;
      
      button {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-add-vehicle-container,
  .GM__dark-add-vehicle-container {
    .GM__light-page-title,
    .GM__dark-page-title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    
    .GM__light-step-content,
    .GM__dark-step-content {
      padding: 16px;
    }
  }
}