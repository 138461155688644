.documents-dropzone-section {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.size-small {
    width: 300px;
    height: 200px;
  }

  &.size-medium {
    width: 300px;
    height: 300px;
  }

  &.size-large {
    width: 100%;
    height: auto;
  }

  .dropzone {
    padding: 24px 16px;
    border: 2px dashed #e0e0e0;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: #ffffff;

    &:hover {
      border-color: #1a237e;
    }

    &.active {
      border-color: #1a237e;
      background: rgba(26, 35, 126, 0.08);
    }

    svg {
      color: #6b7280;
    }

    .upload-text {
      text-align: center;
      color: #374151;
      font-weight: 500;
    }

    .upload-subtext {
      color: #6b7280;
      font-size: 0.825rem;
    }
  }

  .file-rejections {
    color: #ef4444;
    font-size: 0.825rem;
  }

  .uploaded-files {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(var(--max-columns), 1fr);

    @media (max-width: 1024px) {
      --max-columns: 2;
    }

    @media (max-width: 640px) {
      --max-columns: 1;
    }

    .file-item {
      background: #f9fafb;
      border-radius: 8px;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e5e7eb;

      &:hover {
        background: #f3f4f6;
      }

      .file-icon {
        color: #1a237e;
        margin-right: 12px;
      }

      .file-details {
        flex-grow: 1;

        .file-name {
          font-weight: 500;
          color: #111827;
        }

        .file-size {
          color: #6b7280;
          font-size: 0.825rem;
        }
      }

      .delete-icon {
        color: #ef4444;
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
          color: #dc2626;
        }
      }
    }
  }

  .pagination {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}

/* Opcionalmente puedes darle estilos fijos a contenedores circulares */
.single-file-preview-image {
  /* si quieres una clase global en lugar de inline styles */
}

.single-file-preview-doc {
  /* si quieres una clase global en lugar de inline styles */
}
