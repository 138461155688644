.status-display {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    z-index: 1000;
  
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 360px;
      width: 90%;
      padding: 20px;
    }
  
    &__image-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
  
    &__image {
      width: 100%;
      height: auto;
      max-width: 700px;
      object-fit: contain;
    }
  
    &__message {
      font-size: 1.5rem;
      color: #ffffff;
      text-align: center;
      margin: 0;
      padding: 0 20px;
    }
  }