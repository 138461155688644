// Variables para tema claro
$light-primary-color: #333333;
$light-secondary-color: #757575;
$light-background-color: #FFFFFF;
$light-border-radius: 12px;
$light-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
$light-spacing-unit: 8px;
$light-hover-background: rgba(0, 123, 255, 0.08);

// Mixins útiles
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin card-container($bg-color, $radius, $shadow) {
  background-color: $bg-color;
  border-radius: $radius;
  box-shadow: $shadow;
  padding: $light-spacing-unit * 2;
}

.GM__light-role-card {
  @include card-container($light-background-color, $light-border-radius, $light-box-shadow);
  width: 100%;
  min-height: 170px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #eaeaea;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: left;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    background-color: $light-hover-background;
  }
  
  &:focus {
    outline: 2px solid #4285F4;
    outline-offset: 2px;
  }
  
  .GM__light-card-header {
    @include flex-between;
    margin-bottom: 16px;
    
    .GM__light-card-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: $light-primary-color;
      margin: 0;
    }
    
    .GM__light-card-counter {
      font-size: 0.875rem;
      color: $light-secondary-color;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: rgba($light-secondary-color, 0.1);
    }
  }
  
  .GM__light-avatar-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 8px;
    
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .GM__light-avatar-button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }
    
    &:focus {
      outline: 2px solid #4285F4;
      outline-offset: 2px;
    }
  }

  .GM__light-action-icon-button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 25px;
    
    &:focus {
      outline: 2px solid #4285F4;
      outline-offset: 2px;
    }
  }
  
  .GM__light-action-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
    
    &:hover {
      transform: scale(1.1);
    }
  }
  
  .GM__light-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f5f5f5;
    @include flex-center;
    border: 1px solid #eaeaea;
    
    .GM__light-placeholder {
      font-size: 0.75rem;
      color: $light-secondary-color;
    }
  }
}