// Variables para el tema claro
$table-bg-light: #ffffff;
$table-border-light: #e0e6ed;
$table-header-bg-light: #f5f7fa;
$table-row-hover-light: #f7faff;
$text-primary-light: #2c3e50;
$text-secondary-light: #7f8c9a;
$accent-color-light: #007bff;
$accent-hover-light: #0056b3;
$warning-color-light: #ffc107;
$warning-hover-light: #e0a800;
$danger-color-light: #dc3545;
$danger-hover-light: #c82333;
$secondary-color-light: #6c757d;
$secondary-hover-light: #5a6268;
$input-bg-light: #ffffff;
$input-border-light: #ccc;
$modal-bg-light: #ffffff;

// Estilos para el componente de tabla en tema claro
.GM__light-vehicle-table-wrapper {
  margin: 2rem 0;
  font-family: "Roboto", sans-serif;
  color: $text-primary-light;
  
  // Sección de encabezado
  .GM__light-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    
    .GM__light-section-title {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-primary-light;
    }
    
    .GM__light-add-button {
      background-color: $accent-color-light;
      color: #fff;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color: $accent-hover-light;
      }
    }
  }
  
  // Contenedor de tabla con scroll
  .GM__light-vehicle-table-wrapper-table {
    position: relative;
    overflow-x: auto;
    background: $table-bg-light;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-width: 100%;
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $table-border-light;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $secondary-color-light;
      border-radius: 4px;
      
      &:hover {
        background: $secondary-hover-light;
      }
    }
    
    // Tabla principal
    .GM__light-vehicle-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      background-color: $table-bg-light; // Fondo explícito para la tabla
      
      // Estilos para encabezados
      thead {
        tr {
          th {
            background-color: $table-header-bg-light;
            padding: 0.75rem;
            text-align: left;
            font-weight: 600;
            color: $text-primary-light;
            border-bottom: 2px solid $table-border-light;
            position: relative;
            min-width: 120px;
            
            &:first-child {
              border-top-left-radius: 4px;
            }
            
            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }
      }
      
      // Estilos para cuerpo de tabla
      tbody {
        tr {
          transition: background-color 0.2s ease;
          
          &:hover {
            background-color: $table-row-hover-light;
          }
          
          &:last-child td {
            border-bottom: none;
            
            &:first-child {
              border-bottom-left-radius: 4px;
            }
            
            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
          
          td {
            padding: 0.75rem;
            color: $text-primary-light;
            position: relative;
            border-bottom: 1px solid $table-border-light;
            background-color: $table-bg-light; // Fondo explícito para todas las celdas
            
            &.GM__light-empty-message {
              text-align: center;
              padding: 1.5rem;
              color: $text-secondary-light;
            }
            
            // Estilo para celdas con botón de copia
            &.GM__light-copyable-cell {
              position: relative;
              padding-right: 2.5rem;
              
              span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              
              .GM__light-copy-icon {
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                padding: 0.25rem;
                cursor: pointer;
                opacity: 0.6;
                transition: opacity 0.2s ease;
                
                &:hover {
                  opacity: 1;
                }
                
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            
            // Estilos para botones de acción
            &.GM__light-action-buttons {
              display: flex;
              gap: 0.5rem;
              
              .GM__light-edit-button {
                background-color: $warning-color-light;
                color: #000;
                border: none;
                padding: 0.4rem 0.75rem;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.2s ease;
                
                &:hover {
                  background-color: $warning-hover-light;
                }
              }
              
              .GM__light-delete-button {
                background-color: $danger-color-light;
                color: #fff;
                border: none;
                padding: 0.4rem 0.75rem;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.2s ease;
                
                &:hover {
                  background-color: $danger-hover-light;
                }
              }
            }
          }
        }
      }
      
      // Implementación de columnas fijas - Sistema optimizado
      
      // Modo 1: Solo primera columna fija
      &.fixed-columns-1 {
        th, td {
          &.fixed-column-1 {
            position: sticky !important;
            left: 0;
            z-index: 10;
            background-color: $table-bg-light !important; // Color de fondo explícito
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
            }
          }
        }
        
        thead th.fixed-column-1 {
          background-color: $table-header-bg-light !important; // Color de fondo explícito para header
          z-index: 20;
        }
        
        tbody tr:hover td.fixed-column-1 {
          background-color: $table-row-hover-light !important; // Color de fondo explícito para hover
        }
      }
      
      // Modo 2: Solo segunda columna fija
      &.fixed-columns-2 {
        th, td {
          &.fixed-column-2 {
            position: sticky !important;
            left: 0;
            z-index: 10;
            background-color: $table-bg-light !important; // Color de fondo explícito
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
            }
          }
        }
        
        thead th.fixed-column-2 {
          background-color: $table-header-bg-light !important; // Color de fondo explícito para header
          z-index: 20;
        }
        
        tbody tr:hover td.fixed-column-2 {
          background-color: $table-row-hover-light !important; // Color de fondo explícito para hover
        }
      }
      
      // Modo 3: Ambas columnas fijas
      &.fixed-columns-3 {
        th, td {
          &.fixed-column-1, &.fixed-column-2 {
            position: sticky !important;
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
            }
          }
          
          &.fixed-column-1 {
            left: 0;
            z-index: 12;
            background-color: $table-bg-light !important; // Color de fondo explícito
          }
          
          &.fixed-column-2 {
            left: 150px; // Ajustar según el ancho de la primera columna
            z-index: 11;
            background-color: $table-bg-light !important; // Color de fondo explícito
          }
        }
        
        thead th {
          &.fixed-column-1 {
            background-color: $table-header-bg-light !important; // Color de fondo explícito para header
            z-index: 22;
          }
          
          &.fixed-column-2 {
            background-color: $table-header-bg-light !important; // Color de fondo explícito para header
            z-index: 21;
          }
        }
        
        tbody tr:hover td {
          &.fixed-column-1, &.fixed-column-2 {
            background-color: $table-row-hover-light !important; // Color de fondo explícito para hover
          }
        }
      }
    }
  }
  
  // Estilos del modal
  .GM__light-vehicle-modal {
    background-color: $modal-bg-light;
    padding: 2rem;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    max-width: 480px;
    width: 90%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    
    .GM__light-modal-title {
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.3rem;
      color: $text-primary-light;
      font-weight: 600;
    }
    
    .GM__light-form-label {
      display: block;
      margin-top: 1rem;
      font-weight: 600;
      color: $text-primary-light;
    }
    
    .GM__light-form-input {
      width: 100%;
      padding: 0.5rem;
      margin-top: 0.25rem;
      border: 1px solid $input-border-light;
      border-radius: 4px;
      font-size: 0.95rem;
      color: $text-primary-light;
      background-color: $input-bg-light;
      
      &:focus {
        outline: none;
        border-color: $accent-color-light;
        box-shadow: 0 0 0 2px rgba($accent-color-light, 0.25);
      }
    }
    
    .GM__light-form-select {
      width: 100%;
      padding: 0.5rem;
      margin-top: 0.25rem;
      border: 1px solid $input-border-light;
      border-radius: 4px;
      font-size: 0.95rem;
      color: $text-primary-light;
      background-color: $input-bg-light;
      
      &:focus {
        outline: none;
        border-color: $accent-color-light;
        box-shadow: 0 0 0 2px rgba($accent-color-light, 0.25);
      }
    }
    
    .GM__light-error-message {
      color: $danger-color-light;
      font-size: 0.85rem;
      margin-top: 0.25rem;
      display: block;
    }
    
    .GM__light-modal-actions {
      margin-top: 1.5rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      
      .GM__light-save-button {
        background-color: $accent-color-light;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: $accent-hover-light;
        }
      }
      
      .GM__light-cancel-button {
        background-color: $secondary-color-light;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: $secondary-hover-light;
        }
      }
    }
  }
}

// Ajustes para dispositivos móviles
@media (max-width: 1024px) {
  .GM__light-vehicle-table-wrapper {
    .GM__light-vehicle-table-wrapper-table {
      .GM__light-vehicle-table {
        &.fixed-columns-3 {
          th, td {
            &.fixed-column-2 {
              left: 120px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .GM__light-vehicle-table-wrapper {
    margin: 1rem 0;
    
    .GM__light-header-section {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      
      .GM__light-add-button {
        width: 100%;
      }
    }
    
    .GM__light-vehicle-table-wrapper-table {
      .GM__light-vehicle-table {
        &.fixed-columns-3 {
          th, td {
            &.fixed-column-2 {
              left: 100px;
            }
          }
        }
        
        th, td {
          padding: 0.5rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}