// Variables del modal - Tema claro
$modal-overlay-bg-light: rgba(0, 0, 0, 0.5);
$modal-bg-light: #ffffff;
$modal-shadow-light: 0 4px 20px rgba(0, 0, 0, 0.15);
$modal-border-light: 1px solid rgba(0, 0, 0, 0.05);
$modal-header-border-light: 1px solid #eaeaea;
$modal-footer-border-light: 1px solid #eaeaea;
$input-bg-light: #ffffff;
$input-border-light: #d1d5db;
$input-focus-border-light: #4299e1;
$input-border-radius-light: 4px;
$save-button-bg-light: #38a169;
$save-button-hover-light: #2f855a;
$cancel-button-bg-light: #f3f4f6;
$cancel-button-hover-light: #e5e7eb;
$text-primary-light: #374151;
$danger-color-light: #e53e3e;
$accent-color-light: #4299e1;
$accent-hover-light: #3182ce;

// Contenedor del modal (overlay)
.GM__modal-container-light {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $modal-overlay-bg-light;
  z-index: 1300;
  overflow-y: auto;
  padding: 20px;
}

// Estilos base para todos los modales
.GM__light-modal {
  background-color: $modal-bg-light;
  border-radius: 10px;
  box-shadow: $modal-shadow-light;
  border: $modal-border-light;
  width: 90%;
  max-width: 80%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: modalFadeIn 0.3s ease;
  margin: auto;
}

// Contenedor de encabezado
.GM__light-modal-header-container {
  padding: 1.25rem 1.5rem;
  border-bottom: $modal-header-border-light;
  
  .GM__light-modal-header {
    font-size: 1.4rem;
    font-weight: 600;
    color: $text-primary-light;
    margin: 0 0 0.5rem 0;
  }
  
  .GM__light-modal-subtitle {
    font-size: 0.9rem;
    color: rgba($text-primary-light, 0.7);
    margin: 0;
  }
}

// Contenido del modal
.GM__light-modal-content {
  padding: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  max-width: 100%;
  
  // Filas de formulario (dos columnas)
  .GM__light-form-row {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    @media (max-width: 640px) {
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  // Grupos de formulario
  .GM__light-form-group {
    flex: 1;
    
    &.full-width {
      width: 100%;
    }
    
    .GM__light-form-label {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 500;
      color: $text-primary-light;
      font-size: 0.9rem;
    }
    
    .GM__light-form-input,
    .GM__light-form-select {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid $input-border-light;
      border-radius: $input-border-radius-light;
      font-size: 0.95rem;
      color: $text-primary-light;
      background-color: $input-bg-light;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      
      &:focus {
        outline: none;
        border-color: $input-focus-border-light;
        box-shadow: 0 0 0 2px rgba($input-focus-border-light, 0.2);
      }
      
      &::placeholder {
        color: rgba($text-primary-light, 0.4);
      }
      
      &.is-invalid {
        border-color: $danger-color-light;
      }
    }
    
    .GM__light-form-select {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23374151' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 0.7rem center;
      background-size: 1em;
      padding-right: 2.5rem;
    }
    
    .GM__light-form-error {
      color: $danger-color-light;
      font-size: 0.8rem;
      margin-top: 0.375rem;
      display: block;
    }
  }
}

// Footer del modal
.GM__light-modal-footer {
  padding: 1.25rem 1.5rem;
  border-top: $modal-footer-border-light;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}

// Estilos de botones adaptados
.GM__light-btn {
  padding: 0.75rem 2rem;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  
  &.GM__light-btn-save {
    background-color: $save-button-bg-light;
    color: white;
    
    &:hover {
      background-color: $save-button-hover-light;
    }
    
    &:active {
      transform: translateY(1px);
    }
  }
  
  &.GM__light-btn-cancel {
    background-color: $cancel-button-bg-light;
    color: $text-primary-light;
    
    &:hover {
      background-color: $cancel-button-hover-light;
    }
    
    &:active {
      transform: translateY(1px);
    }
  }
}

// Animación de entrada
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Media queries
@media (max-width: 640px) {
  .GM__light-modal {
    width: 95%;
    margin: 10px auto;
  }
  
  .GM__light-modal-footer {
    flex-direction: column-reverse;
    
    .GM__light-btn {
      width: 100%;
      text-align: center;
    }
  }
}