// Variables para el tema claro
$background-light: #f5f7fa;
$surface-light: #ffffff;
$border-light: #e0e6ed;
$text-primary-light: #2c3e50;
$text-secondary-light: #7f8c9a;
$accent-light: #4CAF50;
$input-background-light: #ffffff;
$input-border-light: #d1d9e6;
$input-focus-light: #4CAF50;
$input-hover-light: #f0f4f8;
$label-light: #2c3e50;
$light-border-color: #dee2e6;
$light-text-muted: #adb5bd;
$light-success-color: #198754;
$light-text-secondary: #6c757d;

.GM__light-vehicle-info-section {
  padding: 24px 0;
  background-color: $surface-light;
  border-radius: 8px;
  
  .GM__light-section-title {
    font-size: 22px;
    font-weight: 600;
    color: $text-primary-light;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-light;
  }
  
  .GM__light-input-group {
    margin-bottom: 20px;
    
    .GM__light-input-label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $label-light;
    }
    
    // Estilos para los inputs personalizados
    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid $input-border-light;
      border-radius: 4px;
      font-size: 14px;
      color: $text-primary-light;
      background-color: $input-background-light;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      
      &:hover {
        background-color: $input-hover-light;
      }
      
      &:focus {
        outline: none;
        border-color: $input-focus-light;
        box-shadow: 0 0 0 2px rgba($input-focus-light, 0.2);
      }
      
      &::placeholder {
        color: $text-secondary-light;
      }
    }
    
    // Ajustes para SortDropdown
    .GM__light-sort-dropdown {
      .dropdown-toggle {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $input-border-light;
        border-radius: 4px;
        font-size: 14px;
        color: $text-primary-light;
        background-color: $input-background-light;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        
        &:hover {
          background-color: $input-hover-light;
        }
      }
      
      .dropdown-menu {
        border: 1px solid $border-light;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        background-color: $surface-light;
        
        .dropdown-item {
          color: $text-primary-light;
          padding: 8px 12px;
          
          &:hover {
            background-color: $input-hover-light;
          }
          
          &.active {
            background-color: rgba($accent-light, 0.1);
            color: $accent-light;
          }
        }
      }
    }
  }
  
  .GM__light-info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    .notification-toggle {
      margin-top: 0.5rem;
      border-top: 1px solid $light-border-color;
      padding-top: 5px;
      
      .GM__light-toggles-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
      }
      
      .GM__light-toggle-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 180px;
      }
      
      .GM__light-toggle-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-top: 0.5rem;
      }
      
      .GM__light-toggle-input {
        appearance: none;
        position: relative;
        width: 50px;
        height: 24px;
        border-radius: 25px;
        background-color: $light-text-muted;
        transition: background-color 0.3s ease;
        outline: none;
        cursor: pointer;
        
        &:checked {
          background-color: $light-success-color;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: white;
          transition: transform 0.3s ease;
        }
        
        &:checked::before {
          transform: translateX(26px);
        }
      }
      
      .GM__light-toggle-label {
        font-size: 14px;
        color: $light-text-secondary;
      }
    }
    
  }
}