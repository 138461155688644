// WsspConnection.module.scss
$dark-bg: #121212;
$dark-surface: #1E1E1E;
$primary-color: #25D366;
$text-color: #E0E0E0;
$status-disconnected: #FF4136;
$status-pending: #FFDC00;
$status-connected: #2ECC40;

.wsspConnectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: $dark-bg;
  padding: 2rem;
  box-sizing: border-box;

  .connectionWrapper {
    background-color: $dark-surface;
    border-radius: 16px;
    box-shadow: 0 15px 35px rgba(0,0,0,0.2);
    max-width: 500px;
    width: 100%;
    padding: 2rem;
    text-align: center;
  }

  .connectionHeader {
    margin-bottom: 2rem;

    h1 {
      color: $text-color;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .connectionStatusIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .statusDot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        
        &.disconnected { background-color: $status-disconnected; }
        &.pending { background-color: $status-pending; }
        &.connected { background-color: $status-connected; }
      }

      p {
        color: $text-color;
        opacity: 0.7;
      }
    }
  }

  .qrSection {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .qrCodeContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .qrCodeImage {
        max-width: 250px;
        border-radius: 12px;
        box-shadow: 0 10px 25px rgba(0,0,0,0.2);
      }

      .qrInstructions {
        margin-top: 1rem;
        color: $text-color;
        opacity: 0.6;
        font-size: 0.9rem;
      }
    }

    .noQrContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .qrPlaceholder {
        width: 250px;
        height: 250px;
        background-color: rgba(255,255,255,0.1);
        border-radius: 12px;
        animation: pulse 1.5s infinite;
      }
    }
  }

  .connectionActions {
    .logoutButton {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 0.75rem 1.5rem;
      border-radius: 8px;
      font-weight: 600;
      transition: all 0.3s ease;

      &:hover:not(:disabled) {
        background-color: darken($primary-color, 10%);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
}