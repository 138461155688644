.sidebar-container {
    width: 300px;
    background-color: #1f1f1f;
    color: #fff;
    padding: 1rem;
  
    .search-bar {
      display: flex;
      gap: 0.5rem;
  
      input {
        flex: 1;
        padding: 0.4rem;
        border-radius: 4px;
        border: none;
      }
    }
  
    .conversations-section {
      margin-top: 1rem;
  
      h3 {
        margin-bottom: 0.5rem;
      }
    }
  
    .conversations-list {
      .conversation-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 4px;
  
        &.active {
          background-color: #333;
        }
  
        .conversation-info {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
  
          .name {
            font-weight: bold;
          }
  
          .last-message {
            font-size: 0.8rem;
            opacity: 0.7;
          }
        }
  
        .unread-badge {
          background-color: green;
          padding: 0.2rem 0.5rem;
          border-radius: 10px;
          font-size: 0.8rem;
        }
      }
    }
  
    .global-chat-section {
      margin-top: 1rem;
  
      .global-chat-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 4px;
  
        &.active {
          background-color: #333;
        }
  
        .online-count {
          font-size: 0.8rem;
          opacity: 0.7;
        }
      }
    }
  
    .roles-section {
      margin-top: 1rem;
  
      .role-selector {
        width: 100%;
        padding: 0.4rem;
        border-radius: 4px;
        margin-bottom: 1rem;
      }
  
      .users-list {
        .user-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
          cursor: pointer;
          border-radius: 4px;
  
          &:hover {
            background-color: #333;
          }
  
          .status-indicator {
            width: 8px;
            height: 8px;
            border-radius: 50%;
  
            &.online {
              background-color: green;
            }
  
            &.offline {
              background-color: #666;
            }
          }
        }
      }
    }
  }