@import '../variables.scss';
@import './subdivisions/DashboardHumanResourceLight.scss';
@import './subdivisions/DashboardHumanResourceDark.scss';

// Contenedor principal
.dashboard-rrhh-container {
  padding: $spacing-xl;
  font-family: $font-primary;
  font-size: $font-size-base;
  height: 85vh;
  border-radius: 10px;
  
  &.theme-light {
    color: $light-text-primary;
  }
  
  &.theme-dark {
    background-color: $dark-bg-primary;
    color: $dark-text-primary;
  }
}