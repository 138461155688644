// BasicDataSection.scss (archivo principal)
@import './subdivisions/BasicDataSectionLight.scss';
@import './subdivisions/BasicDataSectionDark.scss';

// Variables comunes
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;

// Estilos compartidos para ambos temas
.GM__light-basic-data-section,
.GM__dark-basic-data-section {
  position: relative;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;

  .GM__light-section-title,
  .GM__dark-section-title {
    padding: 12px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .GM__light-data-form,
  .GM__dark-data-form {
    padding: 15px;
  }

  .GM__light-form-row,
  .GM__dark-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  .GM__light-input-group,
  .GM__dark-input-group {
    flex: 1;
    min-width: 160px;
    position: relative;
  }

  .GM__light-input-label,
  .GM__dark-input-label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
  }

  .GM__light-input,
  .GM__dark-input,
  .GM__light-select,
  .GM__dark-select {
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px;
    height: 36px;
    width: 100%;
    box-sizing: border-box;
  }

  .GM__light-form-actions,
  .GM__dark-form-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 0 0 auto;
  }

  .GM__light-button,
  .GM__dark-button {
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    border: none;
    
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .GM__light-consult-button,
  .GM__dark-consult-button {
    padding: 0 20px;
    min-width: 100px;
  }

  .GM__light-clear-button,
  .GM__dark-clear-button,
  .GM__light-pdf-button,
  .GM__dark-pdf-button,
  .GM__light-share-button,
  .GM__dark-share-button {
    width: 36px;
    height: 36px;
    padding: 0;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 18px;
      height: 18px;
    }
  }

  // Responsividad ajustada para mantener la distribución similar al diseño
  @media (max-width: 1100px) {
    .GM__light-form-row,
    .GM__dark-form-row {
      flex-wrap: wrap;
    }

    .GM__light-input-group,
    .GM__dark-input-group {
      flex: 1 0 calc(50% - 10px);
    }

    .GM__light-form-actions,
    .GM__dark-form-actions {
      flex: 0 0 auto;
      margin-left: auto;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    .GM__light-input-group,
    .GM__dark-input-group {
      flex: 1 0 calc(50% - 10px);
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .GM__light-input-group,
    .GM__dark-input-group {
      flex: 1 0 100%;
    }

    .GM__light-form-actions,
    .GM__dark-form-actions {
      width: 100%;
      margin-top: 10px;
      justify-content: center;
    }
  }
}