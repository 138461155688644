.operator-card {
  position: relative;
  width: 300px;
  height: 150px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(191, 149, 149, 0.25);
  overflow: hidden;
  transition: transform 0.3s ease;

  // Tema oscuro (por defecto)
  &.theme-dark {
    background: #000000;
    
    .card-header {
      .column {
        &.first {
          background: #000000;
          
          .first-margen {
            background: #1E1E1E;
          }
          
          .first-margenv1 {
            color: #ffffff;
          }
        }
        
        &.second {
          background: #000000;
        }
        
        .second-content,
        .second-margenv2,
        .second-margenv3 {
          background: #1E1E1E;
        }
        
        .second-margenv2 {
          &::before {
            background: #000000;
          }
        }
      }
    }
    
    .card-content {
      background: #1E1E1E;
      
      .info-wrapper {
        .info {
          .text {
            color: #ffffff;
          }
        }
      }
    }
  }
  
  // Tema claro
  &.theme-light {
    background: #F5F5F5;
    
    .card-header {
      .column {
        &.first {
          background: #F5F5F5;
          
          .first-margen {
            background: #E0E0E0;
          }
          
          .first-margenv1 {
            color: #333333;
          }
        }
        
        &.second {
          background: #F5F5F5;
        }
        
        .second-content,
        .second-margenv2,
        .second-margenv3 {
          background: #E0E0E0;
        }
        
        .second-margenv2 {
          &::before {
            background: #F5F5F5;
          }
        }
      }
    }
    
    .card-content {
      background: #E0E0E0;
      
      .info-wrapper {
        .info {
          .text {
            color: #333333;
          }
        }
      }
    }
  }

  .card-header {
    position: relative;
    height: 30%;
    display: flex;
  
    .column {
      position: relative;
      height: 100%;
      background: transparent;
  
      &.first {
        width: 120px;
        position: relative;
        border-radius: 0 0 0 0;
  
        .first-margen {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 10px;
          border-radius: 10px 0 10px 0;
          z-index: 10;
        }
        .first-margenv1 {
          padding: 6px 0px 0px 15px;
          font-size: 20px;
        }
      }
  
      &.second {
        flex: 1;
        position: relative;
        border-radius: 10 0 0 0;
        overflow: visible;
      }
  
      .second-margen {
        position: absolute;
        width: 100%;
        height: 10px;
        border-radius: 10px 10px 0 0;
      }
      .second-content {
        width: 182px;
        left: -2px;
        position: absolute;
        height: 100%;
        border-radius: 10px 10px 0 0;
      }
      .second-margenv2 {
        position: absolute;
        bottom: 10px;
        left: -15px;
        width: 160px;
        height: 15px;
        
        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          top: -5px;
          left: -7px;
          border-radius: 0 0 10px 0;
        }
      }
      .second-margenv3 {
        position: absolute;
        bottom: 0px;
        left: -10px;
        width: 160px;
        height: 10px;
      }
    }
  }
  

  .card-content {
    position: relative;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .image-wrapper {
      transform: rotate(45deg);
      position: relative;
      margin-left: 10px;

      .image-border {
        width: 64px;
        height: 64px;
        background: #ffffff;
        border: 2px solid #00a651;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        
        & img {
          transform: rotate(-45deg);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }

    .info-wrapper {
      flex: 1;
      margin-left: 20px;

      .info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .line {
          width: 4px;
          height: 20px;
          background: #00a651;
          margin-right: 10px;
        }

        .text {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

// Estilos para el enlace de tarjeta
.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  
  &:hover {
    .operator-card {
      transform: translateY(-5px);
    }
  }
}