// Variables para tema oscuro
$dark-background: #2b2b2b;
$dark-text: #ffffff;

// Variables para tema claro
$light-background: #ffffff;
$light-text: #000000;
$light-border: #e0e6ed;

// Estilos para tema oscuro
.GM__dark-sort-dropdown {
  margin: 0;
  padding: 0;
  width: 100%;

  .GM__dark-sort-select {
    width: 100%;
    color: $dark-text;
    background-color: $dark-background;
    
    // Personalización de componentes MUI
    .MuiSelect-select {
      color: $dark-text;
      background-color: $dark-background;
    }
    
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.23);
    }
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.5);
    }
    
    .MuiSvgIcon-root {
      color: $dark-text;
    }
  }
  
  .MuiPaper-root {
    background-color: $dark-background;
    color: $dark-text;
    
    .MuiMenuItem-root {
      color: $dark-text;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
      
      &.Mui-selected {
        background-color: rgba(255, 255, 255, 0.16);
        
        &:hover {
          background-color: rgba(255, 255, 255, 0.24);
        }
      }
    }
  }
}

// Estilos para tema claro
.GM__light-sort-dropdown {
  margin: 0;
  padding: 0;
  width: 100%;

  .GM__light-sort-select {
    width: 100%;
    color: $light-text;
    background-color: $light-background;
    
    // Personalización de componentes MUI
    .MuiSelect-select {
      color: $light-text;
      background-color: $light-background;
    }
    
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }
    
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.5);
    }
    
    .MuiSvgIcon-root {
      color: $light-text;
    }
  }
  
  .MuiPaper-root {
    background-color: $light-background;
    color: $light-text;
    border: 1px solid $light-border;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    
    .MuiMenuItem-root {
      color: $light-text;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      
      &.Mui-selected {
        background-color: rgba(76, 175, 80, 0.1);
        
        &:hover {
          background-color: rgba(76, 175, 80, 0.2);
        }
      }
    }
  }
}

// Estilos comunes para elementos internos
.GM__dark-sort-dropdown,
.GM__light-sort-dropdown {
  width: auto; // No ocupar todo el ancho
  min-width: 160px;
  max-width: 200px;
  
  .GM__dark-sort-select,
  .GM__light-sort-select {
    .MuiSelect-select {
      padding: 10px 14px;
      font-size: 14px;
    }
    
    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
    }
  }
}
.GM__dark-dropdown-item-with-flag,
.GM__light-dropdown-item-with-flag {
  display: flex;
  align-items: center;
  gap: 8px;
}

.GM__dark-country-flag,
.GM__light-country-flag {
  border-radius: 2px;
  object-fit: cover;
}

.GM__dark-dropdown-item-label,
.GM__light-dropdown-item-label {
  flex: 1;
}

// Modificaciones específicas para tema claro
.GM__light-dropdown-item-with-flag {
  color: $light-text;
}

// Modificaciones específicas para tema oscuro
.GM__dark-dropdown-item-with-flag {
  color: $dark-text;
}