// Variables
$button-colors: (
  light: (
    primary: (
      background: #007bff,
      color: #ffffff,
      hover: #0056b3,
      disabled: rgba(0, 123, 255, 0.5)
    ),
    secondary: (
      background: #6c757d,
      color: #ffffff,
      hover: #545b62,
      disabled: rgba(108, 117, 125, 0.5)
    ),
    tertiary: (
      background: transparent,
      color: #007bff,
      hover: rgba(0, 123, 255, 0.1),
      disabled: rgba(0, 123, 255, 0.3)
    )
  ),
  dark: (
    primary: (
      background: #00aaff,
      color: #1a1a1a,
      hover: #0088cc,
      disabled: rgba(0, 170, 255, 0.5)
    ),
    secondary: (
      background: #8899aa,
      color: #1a1a1a,
      hover: #667788,
      disabled: rgba(136, 153, 170, 0.5)
    ),
    tertiary: (
      background: transparent,
      color: #00aaff,
      hover: rgba(0, 170, 255, 0.2),
      disabled: rgba(0, 170, 255, 0.3)
    )
  )
);

// Mixins
@mixin button-variant($theme, $variant) {
  $theme-colors: map-get($button-colors, $theme);
  $variant-colors: map-get($theme-colors, $variant);
  
  background-color: map-get($variant-colors, background);
  color: map-get($variant-colors, color);
  
  &:hover:not(:disabled) {
    background-color: map-get($variant-colors, hover);
  }
  
  &:disabled {
    background-color: map-get($variant-colors, disabled);
    cursor: not-allowed;
    opacity: 0.7;
  }
}

// Base styles
.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
  }

  // Size variants
  &--small {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  &--medium {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  &--long {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    min-width: 200px;
  }

  // Theme and variant combinations
  @each $theme in ('light', 'dark') {
    &--#{$theme} {
      @each $variant in ('primary', 'secondary', 'tertiary') {
        &.custom-button--#{$variant} {
          @include button-variant($theme, $variant);
        }
      }
    }
  }

  // Disabled state
  &--disabled {
    pointer-events: none;
    
    .custom-button__text {
      opacity: 0.7;
    }
    
    .custom-button__icon {
      opacity: 0.7;
    }
  }

  // Loading state
  &--loading {
    cursor: wait;
    
    .custom-button__spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.5rem;
      height: 1.5rem;
      
      svg {
        animation: spin 1s linear infinite;
      }
    }
    
    .custom-button__text,
    .custom-button__icon {
      visibility: hidden;
    }
  }

  // Icon styles
  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    
    &--start {
      margin-right: 0.5rem;
    }
    
    &--end {
      margin-left: 0.5rem;
    }
    
    img, svg {
      width: 1.25em;
      height: 1.25em;
    }
  }

  // Dual icons
  &--dual-icons {
    .custom-button__text {
      margin: 0 0.5rem;
    }
  }
}

// Animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// Dark mode specific adjustments
@media (prefers-color-scheme: dark) {
  .custom-button {
    &--light {
      filter: brightness(0.9);
    }
  }
}

// Accessibility
@media (prefers-reduced-motion: reduce) {
  .custom-button {
    transition: none;
    
    &--loading .custom-button__spinner svg {
      animation: none;
    }
  }
}