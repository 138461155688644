.vehicle-card {
  position: relative;
  width: 300px;
  height: 150px;
  background: #9747FF;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(191, 149, 149, 0.25);
  overflow: hidden;

  .card-header {
    position: relative;
    height: 30%;
    display: flex;

    .column {
      position: relative;
      height: 100%;
      background: transparent;

      &.first {
        width: 120px;
        background: #9747FF;
        position: relative;
        border-radius: 0 0 0 0;

        .first-margen {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 10px;
          background: #1E1E1E;
          border-radius: 10px 0 10px 0;
          z-index: 10;
        }
        .first-margenv1 {
          padding: 5px 0 0 12px;
          font-size: 20px;
        }
      }

      &.second {
        flex: 1;
        background: #9747FF;
        position: relative;
        border-radius: 10 0 0 0;
        overflow: visible;
      }

      .second-margen {
        position: absolute;
        width: 100%;
        height: 10px;
        background: #1E1E1E;
        border-radius: 10px 10px 0 0;
      }

      .second-content {
        width: 182px;
        left: -2px;
        position: absolute;
        height: 100%;
        background: #1E1E1E;
        border-radius: 10px 10px 0 0;
      }

      .second-margenv2 {
        position: absolute;
        bottom: 10px;
        left: -15px;
        width: 160px;
        height: 15px;
        background: #1E1E1E;

        &::before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          background: #9747FF;
          top: -5px;
          left: -7px;
          border-radius: 0 0 10px 0;
        }
      }

      .second-margenv3 {
        position: absolute;
        bottom: 0px;
        left: -10px;
        width: 160px;
        height: 10px;
        background: #1E1E1E;
      }
    }
  }


  .card-content {
    position: relative;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #1E1E1E;

    .image-wrapper {
      transform: rotate(45deg);
      position: relative;
      margin-left: 10px;

      .image-border {
        width: 64px;
        height: 64px;
        background: #ffffff;
        border: 2px solid #00a651;
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        & img {
          transform: rotate(-45deg);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;

        }

      }
    }

    .info-wrapper {
      flex: 1;
      margin-left: 20px;

      .info {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .line {
          width: 4px;
          height: 20px;
          background: #00a651;
          margin-right: 10px;
        }

        .text {
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
// Estilos para vehículos combinados
// Ajuste completo para el contenedor combinado
.combined-vehicle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  
  .vehicle-card {
    flex: 1;
    min-width: 0; // Importante para permitir que se encoja
    max-width: 300px; // Ancho máximo de la tarjeta
    
    &.trailer {
      .image-border.trailer-border {
        border-color: #FFAA00;
      }
      
      .line.trailer-line {
        background: #FFAA00;
      }
    }
  }
  
  .vehicle-connector {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 36px;
    
    .connector-icon {
      width: 36px;
      height: 36px;
      background-color: #00a651;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      
      img {
        width: 18px;
        height: 18px;
      }
    }
    
    .connector-line {
      position: absolute;
      width: 4px;
      background-color: #00a651;
      height: 30px;
      
      &.top {
        top: 45px;
      }
      
      &.bottom {
        bottom: 45px;
      }
    }
  }
  
  // Estilos responsivos
  @media screen and (max-width: 900px) {
    flex-direction: column;
    
    .vehicle-card {
      max-width: 100%;
      width: 100%;
    }
    
    .vehicle-connector {
      height: 40px;
      width: 100%;
      flex-direction: row;
      
      .connector-line {
        height: 4px;
        width: 30px;
        
        &.top {
          top: auto;
          left: 45px;
        }
        
        &.bottom {
          bottom: auto;
          right: 45px;
        }
      }
    }
  }
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
  
  &:hover {
    .vehicle-card {
      transform: translateY(-5px);
      transition: transform 0.3s ease;
    }
  }
}
.card-link-combine {
  text-decoration: none;
  color: inherit;
  display: block;
  min-width: 740px;
  
  &:hover {
    .vehicle-card {
      transform: translateY(-5px);
      transition: transform 0.3s ease;
    }
  }
}