// Variables para tema oscuro
$dark-background: #1e1e1e;
$dark-card-background: #2a2a2a;
$dark-text-color: #ffffff;
$dark-text-secondary: #9e9e9e;
$dark-border-color: rgba(255, 255, 255, 0.1);
$dark-button-primary: #4dabf5;
$dark-button-primary-hover: #2196f3;
$dark-icon-color: #9e9e9e;
$dark-icon-hover-color: #ffffff;
$dark-badge-background: rgba(33, 150, 243, 0.2);
$dark-badge-color: #4dabf5;

.GM__dark-comments-locations-drawer {
  .MuiDrawer-paper {
    width: 480px;
    background-color: $dark-background;
    color: $dark-text-color;
  }

  .GM__dark-drawer-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .GM__dark-drawer-header {
    padding: 1.5rem;
    border-bottom: 1px solid $dark-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: $dark-text-color;
    }

    .GM__dark-header-actions {
      display: flex;
      gap: 1rem;
      
      button {
        background: transparent;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.15s ease;
        
        &:hover {
          transform: scale(1.1);
        }
        
        svg {
          width: 28px;
          height: 28px;
          fill: $dark-icon-color;
        }
        
        &:hover svg {
          fill: $dark-icon-hover-color;
        }
        
        &:focus {
          outline: none;
        }
      }
      .GM__dark-add-btn {
        background: transparent;
        border: 1px solid $dark-border-color;
        border-radius: 4px;
        cursor: pointer;
        color: $dark-button-primary;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        transition: background-color 0.2s ease;
        
        svg {
          width: 24px;
          height: 24px;
          fill: $dark-button-primary;
        }
        
        &:hover {
          background-color: rgba(77, 171, 245, 0.1);
        }
      }
    }
  }

  .GM__dark-drawer-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
  }

  .GM__dark-content-card {
    background: $dark-card-background;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    
    .GM__dark-card-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
      
      h3 {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 500;
        color: $dark-text-color;
      }
      
      .GM__dark-header-content {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        
        .GM__dark-primary-badge {
          background-color: $dark-badge-background;
          color: $dark-badge-color;
          font-size: 0.75rem;
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
        }
      }
      
      .GM__dark-action-buttons {
        display: flex;
        gap: 0.5rem;
        
        button {
          background: transparent;
          border: none;
          padding: 0.25rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.15s ease;
          
          &:hover {
            transform: scale(1.1);
          }
          
          svg {
            width: 22px;
            height: 22px;
            fill: $dark-icon-color;
          }
          
          &:hover svg {
            fill: $dark-icon-hover-color;
          }
          
          &:focus {
            outline: none;
          }
        }
      }
    }

    .GM__dark-card-body {
      color: $dark-text-color;
      margin-bottom: 1rem;
      
      p {
        margin: 0 0 0.5rem 0;
        line-height: 1.5;
      }
      
      .GM__dark-location-type {
        font-weight: 500;
        color: $dark-text-secondary;
      }
      
      .GM__dark-address {
        font-weight: 500;
      }
      
      .GM__dark-location-details {
        color: $dark-text-secondary;
      }
      
      .GM__dark-postal-code {
        color: $dark-text-secondary;
        font-size: 0.9rem;
      }
    }

    .GM__dark-card-footer {
      display: flex;
      justify-content: space-between;
      color: $dark-text-secondary;
      font-size: 0.85rem;
      padding-top: 1rem;
      border-top: 1px solid $dark-border-color;
    }
  }

  .GM__dark-empty-state {
    text-align: center;
    padding: 2rem;
    
    p {
      margin-bottom: 1.5rem;
      color: $dark-text-secondary;
    }
    
    .GM__dark-add-btn {
      background: transparent;
      border: 1px solid $dark-border-color;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      color: $dark-button-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transition: background-color 0.2s ease;
      
      svg {
        width: 24px;
        height: 24px;
        fill: $dark-button-primary;
      }
      
      &:hover {
        background-color: rgba(77, 171, 245, 0.1);
      }
    }
  }
}

// Estilos para formularios en tema oscuro
.GM__dark-location-form,
.GM__dark-comment-form {
  padding: 1.5rem;
  
  .MuiTextField-root {
    margin-bottom: 1.5rem;
    
    .MuiInputLabel-root,
    .MuiInputBase-input {
      color: $dark-text-color;
    }
    
    .MuiOutlinedInput-root {
      fieldset {
        border-color: $dark-border-color;
      }
      
      &:hover fieldset {
        border-color: rgba(255, 255, 255, 0.4);
      }
      
      &.Mui-focused fieldset {
        border-color: $dark-button-primary;
      }
    }
  }

  .GM__dark-text-field {
    width: 100%;
    
    .MuiInputLabel-root {
      color: $dark-text-color;
    }
    
    .MuiInputBase-root {
      color: $dark-text-color;
    }
  }

  .GM__dark-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;

    button {
      padding: 0.5rem 1.2rem;
      border-radius: 4px;
      font-weight: 500;
      cursor: pointer;
      font-size: 0.9rem;
      transition: all 0.2s ease;

      &.cancel {
        background: transparent;
        border: 1px solid $dark-border-color;
        color: $dark-text-color;
        
        &:hover {
          background-color: rgba(255, 255, 255, 0.08);
        }
      }

      &.submit {
        background: $dark-button-primary;
        border: none;
        color: #ffffff;
        
        &:hover {
          background: $dark-button-primary-hover;
        }
        
        &:disabled {
          background-color: rgba(255, 255, 255, 0.12);
          color: rgba(255, 255, 255, 0.26);
          cursor: not-allowed;
        }
      }
    }
  }
  
  .GM__dark-form-additional-fields {
    margin-top: 1.5rem;
    border-top: 1px solid $dark-border-color;
    padding-top: 1.5rem;

    .GM__dark-location-type-select {
      width: 100%;
      padding: 0.75rem;
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      color: $dark-text-color;
      border-radius: 4px;

      option {
        background-color: $dark-background;
      }
    }

    .MuiFormControlLabel-root {
      margin-top: 1rem;
      color: $dark-text-color;

      .MuiSwitch-root {
        .MuiSwitch-track {
          background-color: $dark-border-color;
        }
        
        .Mui-checked {
          color: $dark-button-primary;
          
          + .MuiSwitch-track {
            background-color: rgba(77, 171, 245, 0.5);
          }
        }
      }
    }
    
    .GM__dark-form-switch {
      margin-top: 1rem;
      
      .MuiFormControlLabel-label {
        color: $dark-text-color;
        font-size: 0.9rem;
      }
    }
  }
}

// Media queries
@media screen and (max-width: 768px) {
  .GM__dark-comments-locations-drawer {
    .MuiDrawer-paper {
      width: 100%;
    }
    
    .GM__dark-drawer-header {
      padding: 1rem;
      
      h2 {
        font-size: 1.3rem;
      }
    }
    
    .GM__dark-drawer-content {
      padding: 1rem;
    }
  }
}