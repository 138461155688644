// DashboardHumanResourceLight.scss - Versión mejorada

// Contenedor principal
.dashboard-rrhh-container {
  &.theme-light {
    background-color: $light-bg-primary;
    color: $light-text-primary;
    box-shadow: 0 4px 6px $light-shadow;
  }
}

// Sección de controles
.controls-section {
  margin-bottom: $spacing-2xl;
  border-radius: $border-radius;
  padding: $spacing-lg $spacing-xl;
  
  &.theme-light {
    background: $light-bg-secondary;
    box-shadow: 0 2px 4px 0 $light-shadow;
  }
}

// Contenedor de filtros
.filters-container {
  display: flex;
  gap: $spacing-lg;
  margin-bottom: $spacing-lg;
  
  .filter-group {
    flex: 1;
    min-width: 200px;

    input, select {
      width: 100%;
      padding: $spacing-sm;
      border-radius: $border-radius;
      font-size: $font-size-sm;
      transition: all 0.2s ease-in-out;
      
      &.theme-light {
        background-color: $light-bg-secondary;
        color: $light-text-primary;
        border: 1px solid $light-input-border;
        font-weight: 500;
        letter-spacing: 0.01em;
        
        &:focus {
          outline: none;
          border-color: $light-input-focus;
          box-shadow: 0 0 0 2px rgba($light-input-focus, 0.2);
        }
        
        &:hover:not(:disabled) {
          border-color: darken($light-input-border, 10%);
        }

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
          background-color: $light-hover;
        }
        
        &::placeholder {
          color: rgba($light-text-secondary, 0.8);
        }
      }
    }
  }
}

// Leyenda de estados
.status-legend {
  display: flex;
  gap: $spacing-2xl;
  padding: $spacing-md;
  justify-content: center;
  
  &.theme-light {
    border-top: 1px solid $light-border;
    color: $light-text-secondary;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    gap: $spacing-sm;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
  
  .status-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    
    &.expired {
      background-color: black;
    }
    
    &.critical {
      background-color: $status-critical-cell-light;
    }
    
    &.warning {
      background-color: $status-warning-cell-light;
    }
    
    &.valid {
      background-color: $status-valid-light;
    }
    
    &.not-required {
      background-color: $status-not-required-light;
    }
    
    &.empty {
      background-color: $status-empty-light;
    }
  }
}

// Contenedor de tabla
.documents-table-container {
  overflow-x: auto;
  border-radius: $border-radius;
  margin-bottom: $spacing-2xl;
  
  &.theme-light {
    background: $light-bg-secondary;
    box-shadow: 0 1px 3px 0 $light-shadow;
    
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $light-scrollbar-track;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $light-scrollbar-thumb;
      border-radius: 4px;
      
      &:hover {
        background: $light-scrollbar-hover;
      }
    }
  }
}

// Tabla de documentos
.documents-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: $font-size-sm;

  &.theme-light {
    th {
      background: $light-table-header;
      color: $light-text-primary;
      font-weight: 600;
      text-align: left;
      padding: 0.875rem $spacing-md;
      text-transform: uppercase;
      font-size: $font-size-xs;
      letter-spacing: 0.05em;
      min-width: 150px; // Ancho mínimo para columnas
      border-bottom: 2px solid $light-border;
    }

    td {
      padding: $spacing-md;
      white-space: nowrap;
      border-bottom: 1px solid $light-border;
      min-width: 150px; // Ancho mínimo para columnas de datos
      
      &.fixed-column {
        position: sticky;
        background: $light-bg-secondary;
        z-index: 10;
        border-right: 1px solid $light-border;
        
        &:nth-child(1) {
          left: 0;
          min-width: 190px;
          z-index: 20;
        }
        
        &:nth-child(2) {
          left: 190px;
          min-width: 160px;
          z-index: 19;
        }
        
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background: linear-gradient(to right, $light-shadow, transparent);
        }
      }
    }

    tr:hover:not(thead tr) {
      background-color: rgba($light-hover, 0.7);
    }

    .user-name {
      font-weight: 600;
      font-size: $font-size-sm;
      margin-bottom: $spacing-xs;
    }

    .user-email {
      font-size: $font-size-xs;
      color: $light-text-secondary;
      font-style: italic;
    }
  }
}

// Estados de documentos
.document-cell {
  &.expired {
    background-color: $status-expired-light;
    .theme-light & .document-info {
      .days-remaining {
        color: darken($status-expired-cell-light, 50%);
        font-weight: 600;
      }
    }
  }
  
  &.critical {
    background-color: $status-critical-light;
    
    .theme-light & .document-info {
      .days-remaining {
        color: darken($status-critical-cell-light, 30%);
        font-weight: 600;
      }
    }
  }
  
  &.warning {
    background-color: $status-warning-light;
    
    .theme-light & .document-info {
      .days-remaining {
        color: darken($status-warning-cell-light, 35%);
        font-weight: 600;
      }
    }
  }
  
  &.valid {
    background-color: $status-valid-light;
  }
  
  &.not-required {
    background-color: $status-not-required-light;
  }
  
  &.empty {
    background-color: $status-empty-light;
  }

  .document-info {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;
    
    .document-name {
      font-weight: 500;
      color: $light-text-primary;
      font-size: $font-size-sm;
      word-break: break-word;
      max-width: 200px;
    }
    
    .expiration-date,
    .days-remaining {
      font-size: $font-size-xs;
      color: $light-text-secondary;
    }
    
    .days-remaining {
      font-style: italic;
    }
  }

  .no-document {
    font-style: italic;
    padding: $spacing-xs 0;
    color: $light-text-secondary;
    font-size: $font-size-xs;
    text-align: center;
  }
}

// Filas con documentos expirados

// Paginación
.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-sm;
  padding: $spacing-lg;
  border-radius: $border-radius;
  
  &.theme-light {
    background: $light-bg-secondary;
    box-shadow: 0 1px 2px 0 $light-shadow;
  }

  .pagination-button {
    padding: $spacing-sm $spacing-lg;
    font-size: $font-size-sm;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    
    &.theme-light {
      background: $light-bg-secondary;
      color: $light-text-primary;
      border: 1px solid $light-border;
      
      &:hover:not(:disabled) {
        background: $light-hover;
        transform: translateY(-1px);
      }
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .pagination-numbers {
    display: flex;
    gap: $spacing-xs;
    
    .page-number {
      min-width: 2.2rem;
      height: 2.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-sm;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      font-weight: 500;
      
      &.theme-light {
        background: $light-bg-secondary;
        color: $light-text-primary;
        border: 1px solid $light-border;
        
        &:hover:not(.active) {
          background: $light-hover;
          transform: translateY(-1px);
        }
        
        &.active {
          background: #3b82f6;
          color: white;
          border-color: #3b82f6;
          font-weight: 600;
        }
      }
    }
  }

  .pagination-info {
    font-size: $font-size-xs;
    color: $light-text-secondary;
    padding: 0 $spacing-md;
  }
}

// Estados de carga y error
.dashboard-loading,
.dashboard-error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
  flex-direction: column;
  gap: $spacing-lg;
  
  &.theme-light {
    background: $light-bg-secondary;
    color: $light-text-primary;
    border-radius: $border-radius;
    box-shadow: 0 1px 3px 0 $light-shadow;
  }
  
  .loading-text {
    font-size: $font-size-base;
    color: $light-text-secondary;
    margin-top: $spacing-md;
  }
}

// Media queries
@media (max-width: 1024px) {
  .filters-container {
    flex-direction: column;
    
    .filter-group {
      min-width: 100%;
    }
  }
  
  .status-legend {
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacing-lg;
    padding: $spacing-sm;
    
    .legend-item {
      flex: 0 0 auto;
    }
  }

  .documents-table-container {
    margin: 0 -$spacing-md;
    border-radius: 0;
    
    .documents-table {
      td.fixed-column {
        &:nth-child(2) {
          left: 180px;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .dashboard-rrhh-container {
    &.theme-light {
      padding: $spacing-md;
    }
  }
  
  .documents-table {
    font-size: $font-size-xs;
    
    &.theme-light {
      th, td {
        padding: $spacing-sm;
        
        &.fixed-column {
          &:first-child {
            min-width: 150px;
          }
          
          &:nth-child(2) {
            left: 150px;
            min-width: 140px;
          }
        }
      }
    }
  }
  
  .pagination-controls {
    &.theme-light {
      flex-wrap: wrap;
      
      .pagination-info {
        width: 100%;
        text-align: center;
        order: -1;
        margin-bottom: $spacing-sm;
      }
    }
  }
}