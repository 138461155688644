.introduction-section {
    position: relative;
    overflow: hidden;
    color: #fff;
    padding-top: 30px;

    .introduction-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../../img/fondo_camiones.png');
        background-size: cover;
        background-position: center;
        filter: brightness(0.5);
        z-index: -1;
        height: 100%;
        width: 100%;
    }

    .introduction-container {
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .introduction-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: 20px;
    }

    // Header - Configuración base (desktop)
    .introduction-header {
        display: flex;
        align-items: center;
        text-align: center;
        padding-top: 50px;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .introduction-title-container {
            justify-content: center;
            align-items: center;
            text-align: center;
            flex: 1;
            min-width: 300px;
            padding-right: 30px;
        }

        .introduction-title {
            font-size: 2.8rem;
            font-weight: 700;
            margin: 0;
            color: white;
            line-height: 1.2;
        }

        .introduction-subtitle {
            font-size: 2.4rem;
            color: #0dd143;
            margin: 0;
            margin-bottom: 15px;
        }

        .introduction-description {
            font-size: 1.1rem;
            margin: 10px 0 0;
        }

        .introduction-image {
            width: 100%;
            flex: 0 0 auto;
            max-width: 400px;

            img {
                width: 100%;
                height: auto;
                border-radius: 10px;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
            }
        }
    }

    // Carousel - Configuración base (desktop)
    .introduction-carousel {
        top: -50px;
        position: relative;
        width: 100%;
        max-height: 400px;

        .carousel-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin-bottom: 15px;
            border-radius: 8px;
            padding: 10px 0;
            position: relative;
        }

        .carousel-wrapper {
            width: 98%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 260px;
            position: relative;
        }

        .carousel-slide {
            transition: all 0.5s ease;
            padding: 0 10px;
            position: absolute;
            width: 75%;
            max-width: 800px;

            &.active {
                z-index: 3;
                transform: translateX(0) scale(1);
                opacity: 1;
            }

            &.prev {
                z-index: 1;
                transform: translateX(-85%) scale(0.85);
                opacity: 0.7;
            }

            &.next {
                z-index: 1;
                transform: translateX(85%) scale(0.85);
                opacity: 0.7;
            }

            &.hidden {
                opacity: 0;
                z-index: 0;
                transform: translateX(0) scale(0.7);
                pointer-events: none;
            }
        }

        .servicio-card {
            background-color: rgba(255, 255, 255, 0.95);
            border-radius: 10px;
            overflow: hidden;
            display: flex;
            color: #333;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            max-height: 340px;

            .servicio-image {
                flex: 0 0 35%;
                max-width: 35%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .servicio-info {
                flex: 1;
                padding: 15px;
                display: flex;
                flex-direction: column;

                h3 {
                    margin-top: 0;
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 15px;
                    flex-grow: 1;
                    font-size: 0.9rem;
                    line-height: 1.4;
                    max-height: 80px;
                    overflow-y: auto;
                }

                .servicio-actions {
                    display: flex;
                    justify-content: space-between;

                    button {
                        padding: 8px 16px;
                        border: none;
                        border-radius: 5px;
                        font-weight: 500;
                        font-size: 0.9rem;
                        cursor: pointer;
                        transition: all 0.3s ease;

                        &.btn-info {
                            background-color: transparent;
                            color: #0dd143;
                            border: 1px solid #0dd143;

                            &:hover {
                                background-color: rgba(13, 209, 67, 0.1);
                            }
                        }

                        &.btn-cotizar {
                            background-color: #0dd143;
                            color: white;
                            display: flex;
                            align-items: center;

                            .icon-user {
                                margin-right: 6px;
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                background-image: url('../../svg/components/vehicle.svg');
                                background-size: contain;
                                background-repeat: no-repeat;
                            }

                            &:hover {
                                background-color: darken(#0dd143, 10%);
                            }
                        }
                    }
                }
            }
        }

        // Indicadores del carrusel - Más compactos
        .carousel-indicators {
            top: 10px;
            display: flex;
            justify-content: center;

            .indicator {
                width: 10px;
                height: 10px;
                background-color: rgba(255, 255, 255, 0.4);
                border-radius: 50%;
                margin: 0 4px;
                cursor: pointer;
                transition: all 0.3s ease;

                &.active {
                    background-color: #0dd143;
                    transform: scale(1.2);
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.7);
                }
            }
        }
    }

    // MEDIA QUERIES ORGANIZADOS
    // -------------------------

    // Pantallas grandes (tablets horizontales)
    @media (max-width: 992px) {
        .introduction-carousel {
            top: 10px;
            .carousel-slide {
                width: 45%;
                height: 300px;
                
                &.prev {
                    transform: translateX(-75%) scale(0.8);
                }
                
                &.next {
                    transform: translateX(75%) scale(0.8);
                }
            }
            
            .servicio-card {
                flex-direction: column;
                max-height: none;

                .servicio-image {
                    max-width: 100%;
                    height: 160px;
                }
            }
        }
    }

    // Tablets medianas
    @media (max-width: 768px) {
        .introduction-carousel {
            .carousel-slide {
                width: 65%;
                &.prev,
                &.next {
                    opacity: 0.5;
                }
                
                &.prev {
                    transform: translateX(-65%) scale(0.7);
                }
                
                &.next {
                    transform: translateX(65%) scale(0.7);
                }
            }
        }
    }

    // Tablets pequeñas (577px-779px) - Nuevo breakpoint específico
    @media (min-width: 577px) and (max-width: 779px) {
        // Estilos de header tipo columna para tablets pequeñas
        .introduction-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding-top: 50px;
            margin-bottom: 20px;
            width: 100%;

            .introduction-title-container {
                min-width: 100%;
                padding-right: 0;
                margin-bottom: 15px;
                align-items: center;
                text-align: center;
            }

            .introduction-title {
                font-size: 2rem;
            }

            .introduction-subtitle {
                font-size: 1.8rem;
                margin-bottom: 8px;
            }

            .introduction-description {
                width: 100%;
                font-size: 1rem;
                margin-top: 5px;
            }

            .introduction-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            
                img {
                    width: 200px;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                }
            }
        }

        // Ajustar posición del carousel sin cambiar su estructura
        .introduction-carousel {
            top: 75px;
        }
    }

    // Móviles - Estilos consolidados
    @media (max-width: 576px) {
        padding-top: 15px;

        .introduction-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 50px;
            margin-bottom: 20px;
            width: 100%;

            .introduction-title-container {
                text-align: center;
                min-width: 100%;
                padding-right: 0;
                margin-bottom: 15px;
                align-items: center;
            }

            .introduction-title {
                font-size: 1.8rem;
            }

            .introduction-subtitle {
                font-size: 1.5rem;
                margin-bottom: 8px;
            }

            .introduction-description {
                font-size: 0.9rem;
                margin-top: 5px;
            }

            .introduction-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                img {
                    width: 200px;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                }
            }

        }

        .introduction-carousel {
            
            top: -10px;
            .carousel-slide {
                width: 89%;
            }
        }
    }
}