// Variables para tema claro
$light-background: #ffffff;
$light-card-background: #f5f5f5;
$light-text-color: #333333;
$light-text-secondary: #666666;
$light-border-color: rgba(0, 0, 0, 0.1);
$light-button-primary: #1976d2;
$light-button-primary-hover: #1565c0;
$light-icon-color: #757575;
$light-icon-hover-color: #333333;
$light-badge-background: #e3f2fd;
$light-badge-color: #1976d2;

.GM__light-comments-locations-drawer {
  .MuiDrawer-paper {
    width: 480px;
    background-color: $light-background;
    color: $light-text-color;
  }

  .GM__light-drawer-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .GM__light-drawer-header {
    padding: 1.5rem;
    border-bottom: 1px solid $light-border-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
      color: $light-text-color;
    }

    .GM__light-header-actions {
      display: flex;
      gap: 1rem;
      
      button {
        background: transparent;
        border: none;
        padding: 0.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.15s ease;
        
        &:hover {
          transform: scale(1.1);
        }
        
        svg {
          width: 28px;
          height: 28px;
          fill: $light-icon-color;
        }
        
        &:hover svg {
          fill: $light-icon-hover-color;
        }
        
        &:focus {
          outline: none;
        }
      }
    }
  }

  .GM__light-drawer-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
  }

  .GM__light-content-card {
    background: $light-card-background;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    
    .GM__light-card-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 1rem;
      
      h3 {
        margin: 0;
        font-size: 1.1rem;
        font-weight: 500;
        color: $light-text-color;
      }
      
      .GM__light-header-content {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        
        .GM__light-primary-badge {
          background-color: $light-badge-background;
          color: $light-badge-color;
          font-size: 0.75rem;
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
        }
      }
      
      .GM__light-action-buttons {
        display: flex;
        gap: 0.5rem;
        
        button {
          background: transparent;
          border: none;
          padding: 0.25rem;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.15s ease;
          
          &:hover {
            transform: scale(1.1);
          }
          
          svg {
            width: 22px;
            height: 22px;
            fill: $light-icon-color;
          }
          
          &:hover svg {
            fill: $light-icon-hover-color;
          }
          
          &:focus {
            outline: none;
          }
        }
      }
    }

    .GM__light-card-body {
      color: $light-text-color;
      margin-bottom: 1rem;
      
      p {
        margin: 0 0 0.5rem 0;
        line-height: 1.5;
      }
      
      .GM__light-location-type {
        font-weight: 500;
        color: $light-text-secondary;
      }
      
      .GM__light-address {
        font-weight: 500;
      }
      
      .GM__light-location-details {
        color: $light-text-secondary;
      }
      
      .GM__light-postal-code {
        color: $light-text-secondary;
        font-size: 0.9rem;
      }
    }

    .GM__light-card-footer {
      display: flex;
      justify-content: space-between;
      color: $light-text-secondary;
      font-size: 0.85rem;
      padding-top: 1rem;
      border-top: 1px solid $light-border-color;
    }
  }

  .GM__light-empty-state {
    text-align: center;
    padding: 2rem;
    
    p {
      margin-bottom: 1.5rem;
      color: $light-text-secondary;
    }
    
    .GM__light-add-btn {
      background: transparent;
      border: 1px solid $light-border-color;
      padding: 0.75rem 1.5rem;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      color: $light-button-primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transition: background-color 0.2s ease;
      
      svg {
        width: 24px;
        height: 24px;
        fill: $light-button-primary;
      }
      
      &:hover {
        background-color: rgba(25, 118, 210, 0.05);
      }
    }
  }
}

// Estilos para formularios en tema claro
.GM__light-location-form,
.GM__light-comment-form {
  padding: 1.5rem;
  
  .MuiTextField-root {
    margin-bottom: 1.5rem;
    
    .MuiInputLabel-root,
    .MuiInputBase-input {
      color: $light-text-color;
    }
    
    .MuiOutlinedInput-root {
      fieldset {
        border-color: $light-border-color;
      }
      
      &:hover fieldset {
        border-color: $light-text-secondary;
      }
      
      &.Mui-focused fieldset {
        border-color: $light-button-primary;
      }
    }
  }

  .GM__light-text-field {
    width: 100%;
    
    .MuiInputLabel-root {
      color: $light-text-color;
    }
    
    .MuiInputBase-root {
      color: $light-text-color;
    }
  }

  .GM__light-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;

    button {
      padding: 0.5rem 1.2rem;
      border-radius: 4px;
      font-weight: 500;
      cursor: pointer;
      font-size: 0.9rem;
      transition: all 0.2s ease;

      &.cancel {
        background: transparent;
        border: 1px solid $light-border-color;
        color: $light-text-color;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }

      &.submit {
        background: $light-button-primary;
        border: none;
        color: #ffffff;
        
        &:hover {
          background: $light-button-primary-hover;
        }
        
        &:disabled {
          background-color: rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.26);
          cursor: not-allowed;
        }
      }
    }
  }
  
  .GM__light-form-additional-fields {
    margin-top: 1.5rem;
    border-top: 1px solid $light-border-color;
    padding-top: 1.5rem;

    .GM__light-location-type-select {
      width: 100%;
      padding: 0.75rem;
      background-color: $light-background;
      border: 1px solid $light-border-color;
      color: $light-text-color;
      border-radius: 4px;

      option {
        background-color: $light-background;
      }
    }

    .MuiFormControlLabel-root {
      margin-top: 1rem;
      color: $light-text-color;
      
      .MuiSwitch-root {
        .MuiSwitch-track {
          background-color: rgba(0, 0, 0, 0.23);
        }
        
        .Mui-checked {
          color: $light-button-primary;
          
          + .MuiSwitch-track {
            background-color: rgba(25, 118, 210, 0.5);
          }
        }
      }
    }
    
    .GM__light-form-switch {
      margin-top: 1rem;
      
      .MuiFormControlLabel-label {
        color: $light-text-color;
        font-size: 0.9rem;
      }
    }
  }
}

// Media queries
@media screen and (max-width: 768px) {
  .GM__light-comments-locations-drawer {
    .MuiDrawer-paper {
      width: 100%;
    }
    
    .GM__light-drawer-header {
      padding: 1rem;
      
      h2 {
        font-size: 1.3rem;
      }
    }
    
    .GM__light-drawer-content {
      padding: 1rem;
    }
  }
}