.sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #1a1a1a;
  color: #ffffff;

  &.dark {
    background: #F7F6F3;
    background: linear-gradient(to right, #2A2D34 50%, #F7F6F3 50%);
  }

  &.expanded {
    width: 250px;
    background: linear-gradient(to right, #1a1a1a 50%, #d9d9d9 50%);
  }

  &.collapsed {
    width: 60px;
    background: #1a1a1a;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    background: #d9d9d9;

    &.dark {
      background: #F7F6F3;
    }

    &.expanded {
      height: 135px;
      border-radius: 0 0 40px 40px;
    }

    &.collapsed {
      margin: auto;
      height: 50px;
      width: 50px;
      border-radius: 100%;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      height: 100%;

      .sidebar-header-logo {
        width: 230px;
        height: 130px;
        align-self: center;
      }

      .sidebar-header-logov2 {
        width: 30px;
        height: 30px;
        align-self: center;
      }
    }
  }

  &-hide-panel {
    margin-bottom: 0.5rem;
  }

  &-body {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 0;
    background: #1a1a1a;
    position: relative;
    border-radius: 0 40px 0 0;

    &.dark {
      background: #2A2D34;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 4px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &-routes {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 0;
        width: 2px;
        background: rgba(255, 255, 255, 0.1);
      }

      &::after {
        content: "";
        position: absolute;
        left: 6px;
        width: 10px;
        height: 10px;
        background: #00a651;
        border-radius: 50%;
        transform: translateY(calc(var(--indicator-offset, 0px) + 50%));
        transition: transform 0.3s ease;
      }

      &-route {
        display: flex;
        align-items: center;
        padding: 0.75rem 1rem;
        transition: all 0.2s ease;
        position: relative;
        background: none;
        border: none;
        width: 100%;
        text-align: left;
        color: inherit;
        font: inherit;
        cursor: pointer;
        appearance: none;

        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }

        &.active {
          background: rgba(255, 255, 255, 0.1);
        }

        &:focus {
          outline: 2px solid #00a651;
          outline-offset: 2px;
        }

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          margin-right: 1rem;
          color-scheme: dark;
        }

        &-name {
          font-size: 0.9rem;
          white-space: nowrap;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  &-footer {
    padding: 1rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    background: #1a1a1a;

    &.dark {
      background: #2A2D34;
    }

    &-upload {
      align-items: center;
      justify-items: center;
    }

    &-user {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.9);
      }

      small {
        color: rgba(255, 255, 255, 0.6);
        text-transform: capitalize;
      }
    }

    .theme-switch {
      .sidebar-body-routes-route-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .MuiSwitch-root {
          margin: 0;
          padding: 7px;
        }
      }
    }
  }
}