// Variables para el tema claro
$light-background: #ffffff;
$light-text: #2c3e50;
$light-text-secondary: #7f8c9a;
$light-border: #e0e6ed;
$light-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
$light-hover: rgba(0, 0, 0, 0.04);
$light-primary: #007bff;
$light-primary-hover: #0056b3;
$light-expired: #ff4444;
$light-about-to-expire: #ffbb33;
$light-chip-text: #ffffff;

// Variables para el tema oscuro
$dark-background: #1a1a1a;
$dark-background-secondary: #2a2a2a;
$dark-text: #ffffff;
$dark-text-secondary: #aaaaaa;
$dark-border: #444444;
$dark-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
$dark-hover: rgba(255, 255, 255, 0.08);
$dark-primary: #3a8af7;
$dark-primary-hover: #2c6bc7;
$dark-expired: #d32f2f;
$dark-about-to-expire: #f57c00;
$dark-chip-text: #ffffff;

// Tema claro
.GM__light-document-filters-container {
  width: calc(100% - 2rem);
  background-color: $light-background;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: $light-shadow;
  color: $light-text;

  .GM__light-filters-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: stretch;
    }

    .GM__light-expiration-status-section {
      flex-shrink: 0;

      .GM__light-expiration-status-btn {
        text-transform: none;
        justify-content: flex-start;
        padding: 6px 8px;
        min-width: auto;
        color: $light-text;
        
        &:hover {
          background-color: $light-hover;
        }
      }

      .GM__light-expiration-status {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
        }

        .GM__light-status-item {
          display: flex;
          align-items: center;
          font-size: 14px;
          white-space: nowrap;
          color: $light-text;

          .GM__light-status-chip {
            margin: 0 4px;
            
            &.GM__light-expired {
              background-color: $light-expired;
              color: $light-chip-text;
            }
            
            &.GM__light-about-to-expire {
              background-color: $light-about-to-expire;
              color: $light-chip-text;
            }
          }
        }
      }
    }

    .GM__light-right-section {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-grow: 1;
      justify-content: flex-end;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
      }

      .GM__light-filter-controls {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        .GM__light-search-field {
          width: 200px;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .GM__light-filter-select {
          width: 200px;

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .GM__light-multi-download-btn {
        background-color: $light-primary;
        color: $light-chip-text;
        border: none;
        padding: 10px 20px;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s ease;
        white-space: nowrap;

        @media (max-width: 768px) {
          width: 100%;
        }

        &:hover {
          background-color: $light-primary-hover;
        }
      }
    }
  }
}

// Tema oscuro
.GM__dark-document-filters-container {
  width: calc(100% - 2rem);
  background-color: $dark-background;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: $dark-shadow;
  color: $dark-text;

  .GM__dark-filters-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: stretch;
    }

    .GM__dark-expiration-status-section {
      flex-shrink: 0;

      .GM__dark-expiration-status-btn {
        text-transform: none;
        justify-content: flex-start;
        padding: 6px 8px;
        min-width: auto;
        color: $dark-text;
        
        &:hover {
          background-color: $dark-hover;
        }
      }

      .GM__dark-expiration-status {
        display: flex;
        gap: 1rem;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
        }

        .GM__dark-status-item {
          display: flex;
          align-items: center;
          font-size: 14px;
          white-space: nowrap;
          color: $dark-text;

          .GM__dark-status-chip {
            margin: 0 4px;
            
            &.GM__dark-expired {
              background-color: $dark-expired;
              color: $dark-chip-text;
            }
            
            &.GM__dark-about-to-expire {
              background-color: $dark-about-to-expire;
              color: $dark-chip-text;
            }
          }
        }
      }
    }

    .GM__dark-right-section {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-grow: 1;
      justify-content: flex-end;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: stretch;
      }

      .GM__dark-filter-controls {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        .GM__dark-search-field {
          width: 200px;
          
          .MuiOutlinedInput-root {
            background-color: $dark-background-secondary;
            color: $dark-text;
            
            .MuiOutlinedInput-notchedOutline {
              border-color: $dark-border;
            }
            
            &:hover .MuiOutlinedInput-notchedOutline {
              border-color: rgba(255, 255, 255, 0.4);
            }
            
            &.Mui-focused .MuiOutlinedInput-notchedOutline {
              border-color: $dark-primary;
            }
          }
          
          .MuiInputBase-input {
            color: $dark-text;
            
            &::placeholder {
              color: $dark-text-secondary;
              opacity: 1;
            }
          }
          
          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .GM__dark-filter-select {
          width: 200px;
          
          .MuiSelect-select {
            background-color: $dark-background-secondary;
            color: $dark-text;
          }
          
          .MuiOutlinedInput-notchedOutline {
            border-color: $dark-border;
          }
          
          &:hover .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.4);
          }
          
          .MuiSvgIcon-root {
            color: $dark-text;
          }

          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }

      .GM__dark-multi-download-btn {
        background-color: $dark-primary;
        color: $dark-chip-text;
        border: none;
        padding: 10px 20px;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.2s ease;
        white-space: nowrap;

        @media (max-width: 768px) {
          width: 100%;
        }

        &:hover {
          background-color: $dark-primary-hover;
        }
      }
    }
  }
}