@import './subdivisions/VehicleTablesLight.scss';
@import './subdivisions/VehicleTablesDark.scss';
@import './subdivisions/ModalVehicleTablesLight.scss';
@import './subdivisions/ModalVehicleTablesDark.scss';

// Variables globales compartidas
:root {
  --transition-speed: 0.25s;
  --border-radius: 8px;
  --input-radius: 4px;
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 10px;
  --spacing-lg: 20px;
  --spacing-xl: 25px;
}

// Estilos base compartidos por ambos temas
%scrollbar-base {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
}

%card-base {
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

%flex-container {
  display: flex;
  flex-direction: column;
}

%flex-row {
  display: flex;
  flex-direction: row;
}

%input-base {
  padding: 8px 12px;
  border-radius: var(--input-radius);
  font-size: 14px;
  transition: all var(--transition-speed) ease;
}

// Estilos generales de MUI a sobrescribir 
.MuiTabs-root {
  min-height: 48px;
}

.MuiTab-root {
  min-height: 48px;
  text-transform: none;
  font-weight: 500;
}

.MuiAccordion-root {
  &::before {
    display: none;
  }
}

// Estilos compartidos para componentes específicos
.GM__modal-container-light,
.GM__modal-container-dark {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@import './subdivisions/VehicleDocumentRequirementsManagerLight.scss';
@import './subdivisions/VehicleDocumentRequirementsManagerDark.scss';

$bg-light: #f8f9fa;
$panel-bg-light: #ffffff;
$text-primary-light: #2c3e50;
$text-secondary-light: #6c757d;
$border-color-light: #e0e6ed;
$tab-active-color-light: #007bff;
$tab-hover-bg-light: rgba(0, 0, 0, 0.05);
$shadow-light: 0 2px 10px rgba(0, 0, 0, 0.05);

.GM__light-dashboard-vehicle {
  padding: 1rem;
  min-height: min-content;
  width: calc(100% - 35px);
  color: $text-primary-light;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $text-primary-light;
  }

  .GM__light-settings-tabs {
    border-bottom: 1px solid $border-color-light;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    .GM__light-tab-indicator-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .GM__light-tab-indicator {
        position: absolute;
        bottom: -1px;
        height: 3px;
        background-color: $tab-active-color-light;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 3px 3px 0 0;
        z-index: 1;
      }

      .GM__light-tab-button {
        padding: 0.75rem 1.25rem;
        background: transparent;
        border: none;
        font-weight: 500;
        color: $text-secondary-light;
        cursor: pointer;
        transition: color 0.3s ease, background-color 0.3s ease;
        position: relative;
        z-index: 2;

        &:hover {
          background-color: $tab-hover-bg-light;
          color: $text-primary-light;
        }

        &.GM__light-active {
          color: $tab-active-color-light;
          font-weight: 600;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

}

// Variables para el tema oscuro
$bg-dark: #121212;
$panel-bg-dark: #1e1e1e;
$text-primary-dark: #ffffff;
$text-secondary-dark: #aaaaaa;
$border-color-dark: #333333;
$tab-active-color-dark: #00b3ff;
$tab-hover-bg-dark: rgba(255, 255, 255, 0.05);
$shadow-dark: 0 2px 10px rgba(0, 0, 0, 0.3);

// Estilos del componente SettingVehicle con tema oscuro
.GM__dark-dashboard-vehicle {
  padding: 1rem;
  min-height: min-content;
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  color: $text-primary-dark;

  h1 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: $text-primary-dark;
  }

  .GM__dark-settings-tabs {
    border-bottom: 1px solid $border-color-dark;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    .GM__dark-tab-indicator-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .GM__dark-tab-indicator {
        position: absolute;
        bottom: -1px;
        height: 3px;
        background-color: $tab-active-color-dark;
        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        border-radius: 3px 3px 0 0;
        z-index: 1;
      }

      .GM__dark-tab-button {
        padding: 0.75rem 1.25rem;
        background: transparent;
        border: none;
        font-weight: 500;
        color: $text-secondary-dark;
        cursor: pointer;
        transition: color 0.3s ease, background-color 0.3s ease;
        position: relative;
        z-index: 2;

        &:hover {
          background-color: $tab-hover-bg-dark;
          color: $text-primary-dark;
        }

        &.GM__dark-active {
          color: $tab-active-color-dark;
          font-weight: 600;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

}

// Variables para paginación y filtros - Tema Claro
$pagination-bg-light: $panel-bg-light;
$pagination-active-bg-light: $tab-active-color-light;
$pagination-hover-bg-light: rgba(0, 123, 255, 0.1);
$pagination-text-light: $text-secondary-light;
$pagination-active-text-light: white;
$pagination-border-light: $border-color-light;
$pagination-disabled-light: #e9ecef;
$select-border-light: $border-color-light;
$select-focus-light: $tab-active-color-light;

// Variables para paginación y filtros - Tema Oscuro
$pagination-bg-dark: $panel-bg-dark;
$pagination-active-bg-dark: $tab-active-color-dark;
$pagination-hover-bg-dark: rgba(0, 179, 255, 0.2);
$pagination-text-dark: $text-secondary-dark;
$pagination-active-text-dark: $bg-dark;
$pagination-border-dark: $border-color-dark;
$pagination-disabled-dark: #2a2a2a;
$select-border-dark: $border-color-dark;
$select-focus-dark: $tab-active-color-dark;

// Estilos para tema claro
.GM__light-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  
  .GM__light-custom-pagination {
    .MuiPagination-ul {
      display: flex;
      list-style: none;
      padding: 0;
      
      li {
        margin: 0 0.25rem;
        
        .MuiPaginationItem-root {
          font-size: 0.9rem;
          min-width: 36px;
          height: 36px;
          border-radius: 4px;
          color: $pagination-text-light;
          background-color: $pagination-bg-light;
          border: 1px solid $pagination-border-light;
          transition: all 0.2s ease-in-out;
          
          &:hover {
            background-color: $pagination-hover-bg-light;
            text-decoration: none;
          }
          
          &.Mui-selected {
            background-color: $pagination-active-bg-light;
            color: $pagination-active-text-light;
            font-weight: 500;
            
            &:hover {
              background-color: darken($pagination-active-bg-light, 5%);
            }
          }
          
          &.Mui-disabled {
            background-color: $pagination-disabled-light;
            color: rgba($text-secondary-light, 0.5);
            pointer-events: none;
          }
        }
      }
    }
  }
  
  .GM__light-pagination-info {
    font-size: 0.85rem;
    color: $text-secondary-light;
    white-space: nowrap;
  }
}

// Estilos para filtros - Tema Claro
.GM__light-filter-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  
  .GM__light-search-input {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    height: 38px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid $border-color-light;
    background-color: $panel-bg-light;
    color: $text-primary-light;
    font-size: 0.9rem;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: $tab-active-color-light;
      box-shadow: 0 0 0 3px rgba($tab-active-color-light, 0.2);
    }
    
    &::placeholder {
      color: $text-secondary-light;
      opacity: 0.7;
    }
  }
  
  .GM__light-limit-select {
    height: 38px;
    padding: 0 0.75rem;
    border-radius: 4px;
    border: 1px solid $select-border-light;
    background-color: $panel-bg-light;
    color: $text-primary-light;
    font-size: 0.9rem;
    cursor: pointer;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: $select-focus-light;
      box-shadow: 0 0 0 3px rgba($select-focus-light, 0.2);
    }
    
    option {
      padding: 0.5rem;
    }
  }
  
  .GM__light-add-button {
    background-color: $tab-active-color-light;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    
    &:hover {
      background-color: darken($tab-active-color-light, 8%);
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($tab-active-color-light, 0.3);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    
    .GM__light-search-input {
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
    
    .GM__light-limit-select {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
}

// Estilos para tema oscuro
.GM__dark-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
  
  .GM__dark-custom-pagination {
    .MuiPagination-ul {
      display: flex;
      list-style: none;
      padding: 0;
      
      li {
        margin: 0 0.25rem;
        
        .MuiPaginationItem-root {
          font-size: 0.9rem;
          min-width: 36px;
          height: 36px;
          border-radius: 4px;
          color: $pagination-text-dark;
          background-color: $pagination-bg-dark;
          border: 1px solid $pagination-border-dark;
          transition: all 0.2s ease-in-out;
          
          &:hover {
            background-color: $pagination-hover-bg-dark;
            text-decoration: none;
          }
          
          &.Mui-selected {
            background-color: $pagination-active-bg-dark;
            color: $pagination-active-text-dark;
            font-weight: 500;
            
            &:hover {
              background-color: darken($pagination-active-bg-dark, 10%);
            }
          }
          
          &.Mui-disabled {
            background-color: $pagination-disabled-dark;
            color: rgba($text-secondary-dark, 0.5);
            pointer-events: none;
          }
        }
      }
    }
  }
  
  .GM__dark-pagination-info {
    font-size: 0.85rem;
    color: $text-secondary-dark;
    white-space: nowrap;
  }
}

// Estilos para filtros - Tema Oscuro
.GM__dark-filter-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  
  .GM__dark-search-input {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    height: 38px;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid $border-color-dark;
    background-color: lighten($panel-bg-dark, 5%);
    color: $text-primary-dark;
    font-size: 0.9rem;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: $tab-active-color-dark;
      box-shadow: 0 0 0 3px rgba($tab-active-color-dark, 0.2);
    }
    
    &::placeholder {
      color: $text-secondary-dark;
      opacity: 0.7;
    }
  }
  
  .GM__dark-limit-select {
    height: 38px;
    padding: 0 0.75rem;
    border-radius: 4px;
    border: 1px solid $select-border-dark;
    background-color: lighten($panel-bg-dark, 5%);
    color: $text-primary-dark;
    font-size: 0.9rem;
    cursor: pointer;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: $select-focus-dark;
      box-shadow: 0 0 0 3px rgba($select-focus-dark, 0.2);
    }
    
    option {
      padding: 0.5rem;
      background-color: $panel-bg-dark;
    }
  }
  
  .GM__dark-add-button {
    background-color: $tab-active-color-dark;
    color: $bg-dark;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-weight: 500;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    
    &:hover {
      background-color: darken($tab-active-color-dark, 8%);
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba($tab-active-color-dark, 0.3);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    
    .GM__dark-search-input {
      max-width: 100%;
      margin-bottom: 0.5rem;
    }
    
    .GM__dark-limit-select {
      margin-bottom: 0.5rem;
      width: 100%;
    }
  }
}