// _UserProfileInfoDark.scss
$dark-primary-color: #2A2D34;
$dark-secondary-color: #1a1c21;
$dark-accent-color: #00A561;
$dark-text-primary: #E8E9EA;
$dark-text-secondary: #B8B9BA;
$dark-error-color: #dc3545;
$dark-success-color: #28a745;
$dark-border-radius: 8px;
$dark-transition-speed: 0.3s;

@mixin dark-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin dark-card-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

// Componente de información del perfil
.GM__dark-profile-user-info {
  margin-bottom: 10px;
  position: relative;
  
  .profile-header {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    min-height: 500px;
    margin-bottom: 0 0 20px 0;
    
    .profile-image-container {
      .rotated-rectangle {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $dark-primary-color;
        border-radius: $dark-border-radius;
        overflow: hidden;
        
        .profile-image-wrapper {
          display: flex;
          flex-direction: column; 
          width: 100%;
          height: 100%;
          
          .profile-main-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            z-index: 2;
            
            .profile-image {
              width: 100%;
              height: 100%;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          
          .profile-backdrop {
            width:100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            z-index: 1;
            
            img {
              width: 100%;
              height: 100%;
              object-position: center;
              opacity: 0.7;
            }
          }
        }
      }
    }

    .profile-info {
      background-color: $dark-primary-color;
      border-radius: $dark-border-radius;
      padding: 2rem;
      @include dark-card-shadow;

      .info-display {
        h2 {
          font-size: 2rem;
          color: $dark-text-primary;
          margin-bottom: 2rem;
          font-weight: 600;
          border-bottom: 2px solid rgba($dark-accent-color, 0.3);
          padding-bottom: 1rem;
        }

        .info-sections {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;

          .info-column {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .info-section {
            background-color: rgba($dark-secondary-color, 0.4);
            border-radius: $dark-border-radius;
            padding: 1rem;
            border: 1px solid rgba($dark-text-secondary, 0.1);

            .section-title {
              color: $dark-accent-color;
              font-size: 1.2rem;
              margin-bottom: 1rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              
              &::after {
                content: '';
                flex: 1;
                height: 1px;
                background: rgba($dark-accent-color, 0.3);
                margin-left: 1rem;
              }
            }

            .section-content {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .info-item {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;

                .label {
                  color: $dark-text-secondary;
                  font-size: 0.9rem;
                }

                .value {
                  color: $dark-text-primary;
                  font-size: 1rem;

                  &.status {
                    display: inline-flex;
                    align-items: center;
                    padding: 0.25rem 0.75rem;
                    border-radius: 12px;
                    font-size: 0.9rem;
                    
                    &:not(:empty) {
                      &::before {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                      }
                    }
                  }
                }
              }
              
              .footer-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .view-more-btn {
                  background-color: rgba($dark-accent-color, 0.1);
                  color: $dark-accent-color;
                  padding: 0.5rem 1rem;
                  border-radius: $dark-border-radius;
                  border: none;
                  cursor: pointer;
                  transition: all $dark-transition-speed ease;
                  
                  &:hover {
                    background-color: rgba($dark-accent-color, 0.2);
                  }
                }
              }
              
              .empty-state {
                text-align: center;
                padding: 1rem;
                
                .add-btn {
                  background-color: rgba($dark-accent-color, 0.1);
                  color: $dark-accent-color;
                  padding: 0.5rem 1rem;
                  border-radius: $dark-border-radius;
                  border: none;
                  margin-top: 0.5rem;
                  cursor: pointer;
                  transition: all $dark-transition-speed ease;
                  
                  &:hover {
                    background-color: rgba($dark-accent-color, 0.2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .edit-toggle-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 40px;
    height: 40px;
    background-color: rgba($dark-accent-color, 0.1);
    border: 1px solid rgba($dark-accent-color, 0.2);
    border-radius: 50%;
    cursor: pointer;
    @include dark-flex-center;
    transition: all $dark-transition-speed ease;

    svg {
      width: 20px;
      height: 20px;
      fill: $dark-accent-color;
      transition: fill $dark-transition-speed ease;
    }

    &:hover {
      background-color: $dark-accent-color;
      transform: scale(1.05);

      svg {
        fill: $dark-text-primary;
      }
    }
  }
  
  // Estilos adicionales para estados específicos
  .info-item .value.status {
    &:contains("Activo"), 
    &:contains("Sí"),
    &:contains("Aceptada") {
      background-color: rgba($dark-success-color, 0.1);
      color: lighten($dark-success-color, 20%);
      
      &::before {
        background-color: $dark-success-color;
      }
    }

    &:contains("Inactivo"), 
    &:contains("No"),
    &:contains("Pendiente") {
      background-color: rgba($dark-error-color, 0.1);
      color: lighten($dark-error-color, 20%);
      
      &::before {
        background-color: $dark-error-color;
      }
    }
  }
}

// Media queries específicas
@media (max-width: 1024px) {
  .GM__dark-profile-user-info {
    .profile-header {
      grid-template-columns: 1fr;

      .profile-image-container {
        max-height: 400px;
        .profile-image-wrapper {
          .profile-main-image {
            width: 100%;
          }
          
          .profile-backdrop {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .GM__dark-profile-user-info {
    .profile-header {
      .profile-info {
        .info-sections {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}