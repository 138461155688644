// Variables para tema oscuro
$dark-primary-color: #ffffff;
$dark-secondary-color: #c4c4c4;
$dark-background-color: #1E1E1E;
$dark-border-radius: 12px;
$dark-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$dark-spacing-unit: 8px;

// Mixins útiles
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin card-container($bg-color, $radius, $shadow) {
  background-color: $bg-color;
  border-radius: $radius;
  box-shadow: $shadow;
  padding: $dark-spacing-unit * 2;
}

.GM__dark-role-card {
  @include card-container($dark-background-color, $dark-border-radius, $dark-box-shadow);
  width: 100%;
  min-height: 170px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-align: left;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  &:focus {
    outline: 2px solid $dark-primary-color;
    outline-offset: 2px;
  }
  
  .GM__dark-card-header {
    @include flex-between;
    margin-bottom: 16px;
    
    .GM__dark-card-title {
      font-size: 1.25rem;
      font-weight: 600;
      color: $dark-primary-color;
      margin: 0;
    }
    
    .GM__dark-card-counter {
      font-size: 0.875rem;
      color: $dark-secondary-color;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: rgba($dark-secondary-color, 0.1);
    }
  }
  
  .GM__dark-avatar-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-top: 8px;
    
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .GM__dark-avatar-button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }
    
    &:focus {
      outline: 2px solid $dark-primary-color;
      outline-offset: 2px;
    }
  }

  .GM__dark-action-icon-button {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    margin-top: 25px;
    
    &:focus {
      outline: 2px solid $dark-primary-color;
      outline-offset: 2px;
    }
  }
  
  .GM__dark-action-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
    
    &:hover {
      transform: scale(1.1);
    }
  }
  
  .GM__dark-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba($dark-secondary-color, 0.2);
    @include flex-center;
    
    .GM__dark-placeholder {
      font-size: 0.75rem;
      color: $dark-primary-color;
    }
  }
}