.floating-card {
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 240px;
    padding: 10px 10px 1px 10px;
  }

  .card-icon {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 30px;
    height: auto;
    transform: rotate(0deg);
    object-fit: contain;
    cursor: pointer;
  }

  .card-title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }

  .card-value {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }

  .card-divider {
    width: 100%;
    height: 5px;
    background-color: currentColor;
    margin-bottom: 10px;
    padding: 0 0 2px 0;
    border-radius: 0 0 10px 10px;
  }
}