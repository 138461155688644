.public-layout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
  .public-content {
    height: calc(100vh - 82px);
    margin-top: 80px;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    
    // Asegurar que los hijos directos ocupen el alto completo
    > .home-page > .section {
      min-height: calc(100vh - 82px);
      height: auto;
      scroll-snap-align: start;
      scroll-snap-stop: normal;
    }
    
    &::-webkit-scrollbar {
      width: 6px;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(13, 209, 67, 0.6);
      border-radius: 3px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
  }
}

// src/page/public/PublicPages.scss
.section {
  min-height: calc(100vh - 82px);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .section-content {
    width: 100%;
    margin: 0 auto;
    
    h1 {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
      position: relative;
      
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 80px;
        height: 4px;
        background-color: #0dd143;
      }
    }
  }
  
}

// Animaciones de entrada para elementos
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-block {
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0;
  
  // Delay para elementos secuenciales
  &:nth-child(2) { animation-delay: 0.2s; }
  &:nth-child(3) { animation-delay: 0.4s; }
}