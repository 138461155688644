// Variables para tema claro
$light-background: #ffffff;
$light-text-color: #333333;
$light-secondary-color: #666666;
$light-hover-background: rgba(0, 123, 255, 0.18);
$light-border-color: #e0e0e0;
$light-header-bg: #f5f5f5;
$light-error-color: #e66;
$light-icon-size: 24px;
$light-spacing-unit: 8px;

// Temas específicos para botones
$light-preview-btn-bg: #f0f0f0;
$light-preview-btn-hover-bg: #e0e0e0;
$light-update-btn-bg: #e6f7ff;
$light-update-btn-hover-bg: #bae7ff;
$light-delete-btn-bg: #fff1f0;
$light-delete-btn-hover-bg: #ffd4d2;
$light-comment-btn-bg: #f0f5ff;
$light-comment-btn-hover-bg: #d6e4ff;
$light-save-btn-bg: #e6f7e6;
$light-save-btn-hover-bg: #bae7ba;

.GM__light-documents-section {
  padding: 20px;
  
  .GM__light-document-type-table {
    margin-bottom: 30px;
    border: 1px solid $light-border-color;
    border-radius: 8px;
    overflow: hidden;

    .GM__light-table-header {
      background-color: $light-header-bg;
      padding: 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $light-border-color;

      h3 {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: $light-text-color;
      }

      p {
        margin: 0;
        font-size: 14px;
        color: $light-secondary-color;
        flex-grow: 1;
        padding: 0 20px;
      }

      .GM__light-add-document-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 8px;
        display: flex;
        align-items: center;
        transition: transform 0.2s ease;

        &:hover {
          transform: translateY(-2px);
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .GM__light-table {
      width: 100%;
      border-collapse: collapse;
      background-color: $light-background;

      th {
        background-color: #fafafa;
        padding: 12px;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        color: $light-text-color;
        border-bottom: 2px solid $light-border-color;
      }

      td {
        padding: 12px;
        border-bottom: 1px solid $light-border-color;
        vertical-align: middle;
        color: $light-text-color;

        &:first-child {
          width: 60px;
          text-align: center;

          .GM__light-status-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
      
      .GM__light-action-buttons {
        padding: 15px;
        border-bottom: 1px solid $light-border-color;
        vertical-align: middle;
        min-width: 150px;
        
        .GM__light-action-buttons-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: flex-start;
          
          button {
            flex: 0 0 calc(33.33% - 6px);
            min-width: 40px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 4px;
            transition: background-color 0.2s ease, transform 0.1s ease;
            
            &:hover {
              transform: translateY(-2px);
            }
            
            .GM__light-action-icon {
              width: 20px;
              height: 20px;
              object-fit: contain;
            }
          }
          
          .GM__light-preview-btn {
            background-color: $light-preview-btn-bg;
            &:hover {
              background-color: $light-preview-btn-hover-bg;
            }
          }
          
          .GM__light-update-btn {
            background-color: $light-update-btn-bg;
            &:hover {
              background-color: $light-update-btn-hover-bg;
            }
          }
          
          .GM__light-save-btn {
            background-color: $light-save-btn-bg;
            &:hover {
              background-color: $light-save-btn-hover-bg;
            }
          }
          
          .GM__light-delete-btn {
            background-color: $light-delete-btn-bg;
            &:hover {
              background-color: $light-delete-btn-hover-bg;
            }
          }
          
          .GM__light-coment-btn {
            background-color: $light-comment-btn-bg;
            &:hover {
              background-color: $light-comment-btn-hover-bg;
            }
          }
        }
      }

      .GM__light-file-upload {
        .GM__light-file-label {
          display: inline-block;
          padding: 8px 12px;
          background-color: #f0f0f0;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          color: $light-text-color;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #e0e0e0;
          }
        }
      }

      .GM__light-file-name {
        color: $light-text-color;
      }

      .GM__light-checkbox {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      tr {
        &:hover {
          background-color: rgba(250, 250, 250, 0.3);
        }

        &:last-child td {
          border-bottom: none;
        }
      }
      
      .GM__light-empty-document {
        .GM__light-no-documents {
          text-align: center;
          color: $light-secondary-color;
          padding: 20px;
        }
      }
    }
  }

  // Estilos específicos para los componentes personalizados
  .GM__light-input-field, 
  .GM__light-date-picker {
    width: 100%;
  }
  
  .GM__light-input-field {
    min-width: 150px;
  }
  
  .GM__light-date-picker {
    min-width: 150px;
  }
}

// Media queries
@media screen and (max-width: 768px) {
  .GM__light-documents-section {
    padding: 16px;
    
    .GM__light-document-type-table {
      .GM__light-table-header {
        flex-direction: column;
        gap: 8px;
        
        h3, p {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-documents-section {
    padding: 12px;
  }
}