.annotation-manager {
    padding: 15px;
    display: flex;
    flex-direction: column;
    
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      
      h3 {
        margin: 0;
        font-size: 1.2rem;
        color: #333;
      }
    }
    
    &__form-container {
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 15px;
    }
    
    &__form {
      .helper-text {
        font-size: 0.85rem;
        color: #666;
        font-style: italic;
      }
      
      .form-buttons {
        display: flex;
        gap: 10px;
        margin-top: 15px;
      }
    }
    
    &__list {
      flex: 1;
      overflow-y: auto;
      
      .annotation-item {
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.2s;
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        }
        
        &.active {
          border-color: #2196f3;
          box-shadow: 0 0 0 1px #2196f3;
        }
        
        &__header {
          padding: 10px;
          display: flex;
          align-items: center;
        }
        
        &__color {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 10px;
        }
        
        &__title {
          margin: 0;
          flex: 1;
          font-size: 1rem;
        }
        
        &__actions {
          display: flex;
          gap: 5px;
          
          button {
            background: none;
            border: none;
            font-size: 1rem;
            color: #666;
            cursor: pointer;
            padding: 5px;
            
            &:hover {
              color: #2196f3;
            }
          }
        }
        
        &__details {
          padding: 0 10px 10px;
          border-top: 1px solid #eee;
        }
        
        &__description {
          margin: 10px 0;
          font-size: 0.9rem;
          color: #666;
        }
        
        &__coords {
          font-size: 0.85rem;
          color: #777;
          margin-bottom: 10px;
        }
        
        &__edit {
          margin-top: 10px;
        }
      }
    }
    
    .no-data-message {
      text-align: center;
      color: #666;
      padding: 20px;
      font-style: italic;
    }
  }