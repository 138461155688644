.response-modal {
    .modal-title {
      background-color: #f5f5f5;
      padding: 16px 24px;
      
      .MuiTypography-h5 {
        font-weight: 600;
        color: #2c3e50;
      }
    }
  
    .modal-content {
      padding: 24px;
      min-height: 200px;
      
      .duplicate-records-container {
        margin-top: 20px;
        
        .duplicate-record-item {
          padding: 12px 0;
          
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          
          .MuiDivider-root {
            margin-top: 12px;
          }
        }
      }
    }
  
    .modal-actions {
      padding: 16px 24px;
      background-color: #f5f5f5;
    }
  }