.card-transition {
  position: relative;
  width: 300px;
  height: 150px;
  border-radius: 8px;
  margin: 0 10px;
  overflow: hidden;
  display: flex;
  transition: transform 0.3s ease;
  
  &.light {
    background-color: #ffffff;
    color: #333333;
  }
  
  &.dark {
    background-color: #2d2d2d;
    color: #ffffff;
  }

  .card-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
  }

  .card-content {
    flex: 1;
    padding: 15px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  .card-direction {
    font-size: 0.9rem;
    color: #666666;
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:hover {
      text-decoration: underline;
    }

    .dark & {
      color: #b0b0b0;
    }
  }

  .card-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;

    .placa {
      font-weight: 600;
      font-size: 0.9rem;
    }

    .count {
      font-size: 0.8rem;
      color: #888888;
      
      .dark & {
        color: #a0a0a0;
      }
    }
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}