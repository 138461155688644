.public-header {
  width: 100%;
  background: white;
  border-bottom: 2px solid #0dd143;
  position: fixed;
  top: 0;
  z-index: 1000;
  
  .container {
    margin: 0 auto;
    padding: 0 20px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; // Para posicionamiento del menú colapsable
  }

  .logo-container {
    cursor: pointer;
    padding-top: 100px;
    
    // Para desktop logo
    .desktop-logo {
      max-height: 100%;
      max-width: 280px;
      object-fit: contain;
      transform: translateX(-10%);
    }
    
    // Para mobile logo
    .mobile-logo {
      height: 40px;
      width: auto;
      object-fit: contain;
    }
  }

  // Navegación desktop
  .navigation.desktop-nav {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;

    .nav-links {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      
      li {
        margin: 0 15px;
      }

      .nav-link {
        color: #333;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        transition: color 0.3s;
        padding: 8px 0;
        cursor: pointer;
        
        &:hover {
          color: #0dd143;
        }
        
        &.active {
          color: #0dd143;
          position: relative;
          
          &:after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #0dd143;
          }
        }
      }
    }
  }

  // Navegación móvil
  .mobile-nav {
    display: none; // Por defecto oculto
    position: relative;
    
    .section-chip {
      cursor: pointer;
      font-weight: 500;
      padding: 4px 8px;
      height: 36px;
      
      &:hover {
        background-color: rgba(13, 209, 67, 0.1);
      }
    }
    
    .mobile-menu-collapse {
      position: absolute;
      top: 45px;
      left: 0;
      right: 0;
      width: 220px;
      margin: 0 auto;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      z-index: 1100;
    }
    
    .mobile-menu {
      padding: 8px 0;
      
      .MuiListItem-root {
        padding: 12px 16px;
        font-size: 15px;
        color: #333;
        transition: all 0.3s ease;
        
        &:hover {
          color: #0dd143;
          background-color: rgba(13, 209, 67, 0.1);
        }
        
        &.active-item {
          color: #0dd143;
          background-color: rgba(13, 209, 67, 0.05);
          font-weight: 500;
        }
      }
    }
  }

  .auth-actions {
    flex: 0 0 auto;
    margin-left: 20px;
    
    .login-button {
      display: inline-block;
      padding: 8px 24px;
      background-color: #333;
      color: white;
      text-decoration: none;
      border-radius: 4px;
      font-weight: 500;
      transition: background-color 0.3s;
      
      &:hover {
        background-color: #0dd143;
      }
    }
  }
  
  // Media queries para responsividad
  @media (max-width: 767px) {
    .container {
      padding: 0 16px;
    }
    
    .logo-container {
      position: static;
      transform: none;
      margin-right: auto;
      padding-top: 0;
      
      img {
        transform: none;
      }
    }
    
    .navigation.desktop-nav {
      display: none; // Ocultar navegación desktop
    }
    
    .mobile-nav {
      display: block; // Mostrar navegación móvil
    }
    
    .auth-actions {
      margin-left: 16px;
      
      .login-button {
        padding: 6px 16px;
        font-size: 14px;
      }
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 0 10px;
    }
    
    .auth-actions {
      margin-left: 10px;
      
      .login-button {
        padding: 4px 12px;
        font-size: 13px;
      }
    }
  }
}