// Variables de diseño
$primary-color: #3f51b5;
$secondary-color: #f50057;
$background-color: #f5f5f500;
$background-content-color: #1E1E1E;
$card-background: #ffffff;
$text-color: #ffffff;

// Breakpoints
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 576px;

.trends-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  padding: 20px;
  height: 85vh;
  background-color: $background-color;
  overflow: auto;

  .trends-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .trends-title {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color;
    }
  }

  .charts {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height:100%;
    align-items: center;

    @media screen and (min-width: $tablet-breakpoint) {
      flex-direction: row;
    }

    .section-1 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column; // Cambiamos a columna para disposición vertical
      gap: 20px;
      .MuiPaper-root {
        background: $background-content-color ;
      }
    
      // Mantenemos los breakpoints existentes
      @media screen and (min-width: $tablet-breakpoint) {
        width: 60%;
      }
    
      @media screen and (min-width: $desktop-breakpoint) {
        width: 70%;
      }
    }

    .section-2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .MuiPaper-root {
        background: $background-content-color ;
      }

      @media screen and (min-width: $tablet-breakpoint) {
        width: 40%;
      }

      @media screen and (min-width: $desktop-breakpoint) {
        width: 30%;
      }

    }
  }

}