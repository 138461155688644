.document-types-table-container {
  margin: 2rem 0;
  font-family: "Roboto", sans-serif;
  background: #2A2D34;
  padding: 25px 10px ;
  border-radius: 30px;
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: #ffffff;
    }

    .add-button {
      background-color: #007bff;
      color: #fff;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .document-types-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #f5f5f5;

      th {
        padding: 0.75rem;
        text-align: left;
        font-weight: 600;
        color: #000000;
        border-bottom: 2px solid #ccc;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #eee;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #fafafa24;
        }

        td {
          padding: 0.75rem;
          color: #e0e0e0;

          .status-badge {
            padding: 0.25rem 0.75rem;
            border-radius: 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            display: inline-block;

            &.active {
              background-color: #e8f5e9;
              color: #2e7d32;
            }

            &.inactive {
              background-color: #fafafa;
              color: #121212;
            }
          }
        }

        .actions-cell {
          display: flex;
          gap: 0.5rem;

          .edit-button {
            background-color: #ffc107;
            color: #000;
            padding: 0.5rem 0.75rem;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
              background-color: #e0a800;
            }
          }

          .delete-button {
            background-color: #dc3545;
            color: #fff;
            padding: 0.5rem 0.75rem;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:hover {
              background-color: #c82333;
            }
          }
        }
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    .custom-pagination {
      .MuiPaginationItem-root {
        font-size: 14px;
        min-width: 32px;
        height: 32px;
        margin: 0 4px;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
        color: white;

        &.Mui-selected {
          background-color: #007bff;
          color: white;
        }

        &:hover {
          background-color: rgba(0, 123, 255, 0.1);
        }
      }
    }
  }
  
}
.document-type-modal {
  .MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .MuiModal-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2A2D34;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px;
    outline: none;
    padding: 2rem;

    h3 {
      margin: 0 0 1.5rem 0;
      font-size: 1.3rem;
      color: #ffffff;
      font-weight: 600;
    }

    div {
      margin-bottom: 1.25rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #e0e0e0;

        &:has(input[type="checkbox"]) {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
        }
      }

      input, 
      textarea, 
      select {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid #404348;
        border-radius: 4px;
        font-size: 0.95rem;
        color: #e0e0e0;
        background-color: #363940;
        transition: border-color 0.2s ease;

        &:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
        }

        &::placeholder {
          color: #808080;
        }
      }

      span {
        display: block;
        margin-top: 0.5rem;
        color: #ff4d4d;
        font-size: 0.875rem;
      }
    }

    div:last-child {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 0;

      button {
        padding: 0.625rem 1.25rem;
        border: none;
        border-radius: 4px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &[type="submit"] {
          background-color: #007bff;
          color: #ffffff;

          &:hover {
            background-color: #0056b3;
          }
        }

        &[type="button"] {
          background-color: #404348;
          color: #ffffff;

          &:hover {
            background-color: #4a4d54;
          }
        }
      }
    }
  }
}