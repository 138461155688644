// Variables
$header-height: 300px;
$header-bg-color: #ffffff;
$layout-bg-color: #f5f5f5;
$border-color: #0070C0;
$border-radius: 8px;

// Contenedor principal
.app-header-main-container {
    width: 100%;
    height: $header-height;
    position: relative;
    background: #222;
    border-radius: 21px;
}

// Wrapper que contiene las secciones principales
.app-header-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

// Sección del logo (85% altura)
.app-header-logo-section {
    width: 100%;
    height: 85%;
    background-color: $header-bg-color;
    border-radius: 20px 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0 0 0 20px;
    }

    &-logo-container {
        .app-header-logo {
            padding-top: 30px;
            max-width: 500px;
            height: auto;
        }
    }
}

// Clases para la sección inferior con temas
.light-app-header-bottom-section,
.dark-app-header-bottom-section {
    width: 100%;
    height: 15%;
    display: flex;
}

// Área del switch
.app-header-switch-area {
    width: 100px;
    height: 100%;
    position: relative;

    &-background {
        width: 100%;
        height: 100%;
        background-color: $header-bg-color;
        border-top: none;
        border-radius: 0 0 0 $border-radius;
        display: flex;
    }
}

// Contenedor del switch
.theme-switch {
    &-container {
        width: 90px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #222;
        border-radius: 0 0 0 $border-radius;

        .sidebar-body-routes-route-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-spacer {
        width: 10px;
        height: 100%;
        border-radius: 0 10px 0 0;
        background: #222;
    }
}

// Espacio inferior derecho
.app-header-bottom-spacer {
    flex: 1;
    height: 100%;
    background-color: $header-bg-color;
    border-top: none;
    border-left: none;
    border-radius: 0 0 $border-radius 10px;
}