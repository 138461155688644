// Fuentes
$font-primary: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
$font-size-base: 0.8125rem; // 13px
$font-size-sm: 0.75rem;    // 12px
$font-size-xs: 0.6875rem;  // 11px

// Colores tema claro
$light-bg-primary: #f8fafc;
$light-bg-secondary: #ffffff;
$light-text-primary: #1e293b;
$light-text-secondary: #64748b;
$light-border: #e2e8f0;
$light-hover: #f1f5f9;
$light-shadow: rgba(0, 0, 0, 0.05);
$light-input-border: #e2e8f0;
$light-input-focus: #93c5fd;
$light-table-header: #f8fafc;
$light-scrollbar-track: #f1f5f9;
$light-scrollbar-thumb: #cbd5e1;
$light-scrollbar-hover: #94a3b8;

// Colores tema oscuro
$dark-bg-primary: #1a1a1a;
$dark-bg-secondary: #2d2d2d;
$dark-text-primary: #e2e8f0;
$dark-text-secondary: #94a3b8;
$dark-border: #404040;
$dark-hover: #404040;
$dark-shadow: rgba(0, 0, 0, 0.2);
$dark-input-border: #404040;
$dark-input-focus: #3b82f6;
$dark-table-header: #262626;
$dark-scrollbar-track: #262626;
$dark-scrollbar-thumb: #404040;
$dark-scrollbar-hover: #525252;

// Estados de documentos
$status-expired-light: rgb(0, 0, 0);
$status-expired-dark: rgba(239, 68, 68, 0.764);
$status-expired-cell-light: rgb(239, 68, 68);
$status-expired-cell-dark: rgba(239, 68, 68, 0.25);

$status-critical-light: rgba(251, 146, 60, 0.343);
$status-critical-dark: rgba(251, 146, 60, 0.762);
$status-critical-cell-light: rgba(251, 146, 60, 0.35);
$status-critical-cell-dark: rgba(251, 146, 60, 0.25);

$status-warning-light: rgba(250, 204, 21, 0.25);
$status-warning-dark: rgba(250, 204, 21, 0.15);
$status-warning-cell-light: rgba(250, 204, 21, 0.35);
$status-warning-cell-dark: rgba(250, 204, 21, 0.25);

$status-valid-light: rgba(34, 197, 94, 0.396);
$status-valid-dark: rgba(34, 197, 94, 0.288);

$status-not-required-light: rgba(148, 163, 184, 0.2);
$status-not-required-dark: rgba(148, 163, 184, 0.1);

$status-empty-light: rgba(203, 213, 225, 0.2);
$status-empty-dark: rgba(203, 213, 225, 0.1);

// Espaciados
$spacing-xs: 0.25rem;
$spacing-sm: 0.5rem;
$spacing-md: 0.75rem;
$spacing-lg: 1rem;
$spacing-xl: 1.25rem;
$spacing-2xl: 1.5rem;

// Bordes
$border-radius: 0.375rem;