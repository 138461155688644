// VehicleProfile.scss - Archivo principal
@import "./subdivisions/VehicleProfileInfoDark.scss";
@import "./subdivisions/VehicleProfileInfoLight.scss";

// Variables comunes
$primary-color: #2A2D34;
$secondary-color: #1a1c21;
$accent-color: #00A561;
$text-primary: #E8E9EA;
$text-secondary: #B8B9BA;
$error-color: #dc3545;
$success-color: #28a745;
$border-radius: 8px;
$transition-speed: 0.3s;

// Mixins reutilizables
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin card-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

@mixin input-focus {
  &:focus {
    outline: none;
    border-color: $accent-color;
    box-shadow: 0 0 0 2px rgba($accent-color, 0.2);
  }
}

// Estilos generales
.GM__dark-vehicle-profile, .GM__light-vehicle-profile {
  // Contenedor de filtros de documentos
  .GM__dark-multi-download-container, .GM__light-multi-download-container {
    margin-bottom: 20px;
  }

  // Estilos para mensajes de error
  .GM__dark-error-message, .GM__light-error-message {
    padding: 15px;
    border-radius: $border-radius;
    margin-bottom: 20px;
    font-weight: 500;
    text-align: center;
  }

  .GM__dark-error-message {
    background-color: rgba($error-color, 0.1);
    color: lighten($error-color, 20%);
    border: 1px solid rgba($error-color, 0.3);
  }

  .GM__light-error-message {
    background-color: rgba($error-color, 0.05);
    color: $error-color;
    border: 1px solid rgba($error-color, 0.2);
  }

  /* Tablas de documentos */
  .documents-section {
    background-color: #2A2D34;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    h3 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;

      th, td {
        padding: 10px;
        text-align: left;
        border: 1px solid #ddd;
      }

      th {
        background-color: #f4f4f4;
        color: #000;
      }

      td {
        background-color: #2A2D34;
        color: #fff;
        text-align: center;
      }

      td:last-child {
        display: flex;
        justify-content: center;
        gap: 10px;

        button {
          padding: 5px 10px;
          border: none;
          border-radius: 3px;
          color: #fff;
          cursor: pointer;
          font-weight: 500;
          transition: background-color 0.2s ease;

          &:nth-child(1) {
            background-color: #28a745;
            &:hover { background-color: #218838; }
          }

          &:nth-child(2) {
            background-color: #ffc107;
            color: #000;
            &:hover { background-color: #e0a800; }
          }

          &:nth-child(3) {
            background-color: #dc3545;
            &:hover { background-color: #c82333; }
          }
        }
      }
    }
  }
}
// Estilos para elementos modificados
.GM__light-modified-row, .GM__dark-modified-row {
  background-color: rgba(255, 252, 127, 0.1);
}

.GM__light-input-field.modified,
.GM__light-date-picker.modified,
.GM__light-file-label.modified {
  border-color: #1976d2;
  box-shadow: 0 0 0 1px rgba(25, 118, 210, 0.25);
}

.GM__dark-input-field.modified,
.GM__dark-date-picker.modified,
.GM__dark-file-label.modified {
  border-color: #90caf9;
  box-shadow: 0 0 0 1px rgba(144, 202, 249, 0.25);
}

.GM__light-modified-btn, .GM__dark-modified-btn {
  background-color: #1976d2;
  color: white;
  
  &:hover {
    background-color: #1565c0;
  }
  
  &:disabled {
    background-color: rgba(25, 118, 210, 0.5);
    cursor: not-allowed;
  }
}

// Estilo para checkboxes modificados
.GM__light-checkbox.modified, .GM__dark-checkbox.modified {
  outline: 2px solid #1976d2;
  outline-offset: 1px;
}
// Media queries para responsividad
@media (max-width: 1024px) {
  .GM__dark-vehicle-profile, .GM__light-vehicle-profile {
    .profile-header {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 768px) {
  .GM__dark-vehicle-profile, .GM__light-vehicle-profile {
    .info-sections {
      grid-template-columns: 1fr !important;
    }
  }
}