.calculator-container {
  width: 100%;
  position: relative;
  padding-bottom: 20px;
  
    
    // Tema claro (por defecto)
    .success-message {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #4CAF50;
      color: white;
      padding: 12px 20px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 1000;
      animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
    }
    
    .calculating-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      
      .calculating-content {
        background-color: white;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        text-align: center;
        
        .spinner {
          width: 50px;
          height: 50px;
          border: 5px solid #f3f3f3;
          border-top: 5px solid #00C853;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin: 0 auto 15px;
        }
        
        p {
          font-size: 16px;
          color: #333;
          margin: 0;
        }
      }
    }
    
    .calculator-results {
      margin-top: 20px;
    }
    
    // Animaciones
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
  
  // Tema oscuro
  .GM__dark-calculator-container {
    width: 100%;
    min-height: 100%;
    position: relative;
    padding-bottom: 20px;
    background-color: #222;
    color: #f0f0f0;
    
    .success-message {
      position: fixed;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00C853;
      color: white;
      padding: 12px 20px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      z-index: 1000;
      animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
    }
    
    .calculating-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      
      .calculating-content {
        background-color: #333;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        text-align: center;
        
        .spinner {
          width: 50px;
          height: 50px;
          border: 5px solid #444;
          border-top: 5px solid #00C853;
          border-radius: 50%;
          animation: spin 1s linear infinite;
          margin: 0 auto 15px;
        }
        
        p {
          font-size: 16px;
          color: #f0f0f0;
          margin: 0;
        }
      }
    }
    
    .calculator-results {
      margin-top: 20px;
    }
    
    // Animaciones (mismas que tema claro)
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }