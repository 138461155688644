// Variables de diseño
$primary-color: #3f51b5;
$secondary-color: #f50057;
$background-color: #f5f5f500;
$background-content-color: #1E1E1E;
$card-background: #ffffff;
$text-color: #ffffff;

// Breakpoints
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 576px;

.hotspots-container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  padding: 20px;
  height: 85vh;
  background-color: $background-color;
  overflow: auto;

  .hotspots-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .hotspots-title {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color;
    }
  }
  .hotspots-content {
    height: 100%;
  }
  .hotspots-download-section {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .download-button {
      display: flex;
      align-items: center;
      background-color: $card-background;
      border-radius: 8px;
      padding: 10px;
      gap: 10px;

      .month-selector {
        min-width: 100px;
      }
    }

    .download-period-btn,
    .download-year-btn {
      text-transform: none;
    }
  }

}