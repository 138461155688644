// src/assets/scss/views/Servicios.scss
.servicios-page-section {
  position: relative;
  color: #333;
  background-color: #fff;
  min-height: calc(100vh - 82px);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible; // Mantener visible
  
  // Establecer contexto de apilamiento explícito
  isolation: isolate;

  .servicios-dots-container {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 500px;  // Limitar ancho explícitamente
    height: 300px; // Limitar altura explícitamente
    pointer-events: none;
    overflow: visible; // Permitir que los puntos sean visibles fuera del contenedor
    
    &.right-dots {
      top: auto;
      left: auto;
      right: 0;
      bottom: 0;
    }
  }
  .servicios-dots-background {
    position: relative;
    width: 100%;
    height: 100%;
    
    &.right {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0.5;
      background-image: radial-gradient(#0dd143 8px, transparent 8px);
      background-size: 25px 25px;
      transform: scale(0.7);
    }
  }


  .servicios-page-container {
    position: relative;
    z-index: 99;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    flex: 1;
  }

  .servicios-page-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 60px;
    color: #333;
    
    .servicios-highlight {
      color: #0dd143;
    }
  }

  .servicios-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    margin-bottom: 50px; // Añadir margen inferior
    
    @media (min-width: 992px) {
      grid-template-columns: 1fr;
    }
  }

  .servicio-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    opacity: 1 !important;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
    }
  }

  .servicio-card {
    // Mantener estilos existentes para desktop
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .servicio-image {
      // Mantener estilos existentes
      flex: 0 0 30%;
      background-color: #f5f5f5;
      
      @media (max-width: 768px) {
        flex: none;
        height: 200px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;
        
        &:hover {
          transform: scale(1.05);
        }
      }
    }


    .servicio-info {
      // Mantener estilos existentes
      flex: 1;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 1.8rem;
        margin-top: 0;
        margin-bottom: 15px;
        color: #333;
        font-weight: 600;
      }

      p {
        margin-bottom: 25px;
        color: #666;
        line-height: 1.6;
        flex-grow: 1;
      }

      // Contenedor para acciones
      .servicio-actions {
        display: flex;
        align-items: center;
        gap: 15px;
        
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: stretch;
          width: 100%;
        }

        .btn-cotizar {
          align-self: flex-start;
          background-color: #0dd143;
          color: white;
          border: none;
          border-radius: 30px;
          padding: 10px 25px;
          font-weight: 500;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;
          box-shadow: 0 4px 10px rgba(13, 209, 67, 0.3);
          
          @media (max-width: 576px) {
            align-self: center;
            width: 100%;
          }

          &:hover {
            background-color: darken(#0dd143, 10%);
            box-shadow: 0 6px 15px rgba(13, 209, 67, 0.4);
            transform: translateY(-2px);
          }
          
          &:active {
            transform: translateY(0);
          }
        }
        
        // Nuevo botón para ver vehículos (móvil)
        .btn-ver-vehiculos {
          display: none; // Oculto en desktop
          background-color: transparent;
          color: #0dd143;
          border: 1px solid #0dd143;
          border-radius: 30px;
          padding: 10px 25px;
          font-weight: 500;
          font-size: 1rem;
          cursor: pointer;
          transition: all 0.3s ease;
          
          @media (max-width: 576px) {
            display: block; // Visible en móvil
            width: 100%;
          }
          
          &:hover {
            background-color: rgba(13, 209, 67, 0.1);
          }
        }
      }
    }
  }
  .servicio-details {
    padding: 25px 30px;
    background: #f8f9fa;
    border-top: 1px solid #eee;
    
    // Por defecto se muestra en desktop
    display: block;
    
    // Para móvil, ocultar por defecto
    @media (max-width: 576px) {
      display: none; // Ocultar inicialmente en móvil
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s ease, padding 0.3s ease;
      padding-top: 0;
      padding-bottom: 0;
      
      // Cuando se activa la clase visible
      &.visible {
        display: block;
        max-height: 1000px; // Valor alto para permitir la expansión
        padding: 25px 30px;
      }
    }
    
    // Mantener los estilos existentes para el contenido
    .vehicle-group {
      margin-bottom: 20px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }

    h4 {
      font-size: 1.1rem;
      font-weight: 600;
      margin: 10px 0;
      color: #333;
      letter-spacing: 0.5px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      display: grid;
      grid-template-columns: 1fr;
      gap: 8px;
      
      @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }
      
      // Reducir a una columna en móvil
      @media (max-width: 576px) {
        grid-template-columns: 1fr;
      }

      li {
        padding: 6px 0;
        color: #555;
        font-size: 0.95rem;
        position: relative;
        transition: transform 0.2s ease;
        
        &:hover {
          transform: translateX(5px);
          color: #0dd143;
        }
      }
    }
  }
}


// Animación para la entrada de los elementos
[data-aos="fade-up"] {
  opacity: 0.2;
  transform: translateY(20px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  
  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}

.servicio-item {
  opacity: 1 !important;
}