// Importar los temas
@import './subdivisions/VehicleInfoSectionLight.scss';
@import './subdivisions/VehicleInfoSectionDark.scss';

// Variables compartidas
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// Estilos responsivos compartidos
@media screen and (max-width: $breakpoint-lg) {
  .GM__light-vehicle-info-section,
  .GM__dark-vehicle-info-section {
    .GM__light-info-grid,
    .GM__dark-info-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .GM__light-vehicle-info-section,
  .GM__dark-vehicle-info-section {
    .GM__light-section-title,
    .GM__dark-section-title {
      font-size: 20px;
      margin-bottom: 16px;
    }
    
    .GM__light-info-grid,
    .GM__dark-info-grid {
      gap: 16px;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .GM__light-vehicle-info-section,
  .GM__dark-vehicle-info-section {
    .GM__light-info-grid,
    .GM__dark-info-grid {
      grid-template-columns: 1fr;
    }
    
    .GM__light-input-group,
    .GM__dark-input-group {
      margin-bottom: 12px;
    }
  }
}