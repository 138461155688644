// RegisterSectionLight.scss

// Variables para tema claro
$light-background-primary: #f8f9fa;
$light-background-secondary: #ffffff;
$light-background-tertiary: #f1f3f5;
$light-text-primary: #212529;
$light-text-secondary: #6c757d;
$light-text-muted: #adb5bd;
$light-border-color: #dee2e6;
$light-accent-color: #0d6efd;
$light-accent-hover: #0b5ed7;
$light-success-color: #198754;
$light-warning-color: #ffc107;
$light-danger-color: #dc3545;
$light-border-radius: 8px;
$light-input-bg: #fff;
$light-input-border: #ced4da;
$light-shadow-color: rgba(0, 0, 0, 0.05);

// Wrapper principal con tema claro
.GM__light-register-section-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $light-background-tertiary;
  color: $light-text-primary;
  font-family: 'Roboto', sans-serif;
  padding: 1.5rem;
  min-height: 100vh;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $light-background-tertiary;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-border-color;
    border-radius: 4px;

    &:hover {
      background: darken($light-border-color, 10%);
    }
  }

  // Contenedor de la sección de registro
  .GM__light-register-section-container {
    width: 100%;
    max-width: 1280px;
    animation: fadeIn 0.3s ease;

    // Sección de contenido principal
    .GM__light-content-section {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      // Sección principal (datos personales)
      .GM__light-main-section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        background-color: $light-background-secondary;
        border-radius: $light-border-radius;
        box-shadow: 0 2px 10px $light-shadow-color;
        padding: 2rem;
        animation: slideIn 0.3s ease;

        // Columna izquierda
        .GM__light-left-column {
          display: flex;
          flex-direction: column;
          gap: 1.25rem;
        }

        // Columna derecha
        .GM__light-right-column {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .GM__light-photo-section {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            background-color: $light-background-tertiary;
            border-radius: $light-border-radius;
            min-height: 200px;
          }

          .GM__light-additional-info {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            width: 100%;
            
            .GM__light-notification-toggle {
              margin-top: 0.5rem;
              border-top: 1px solid $light-border-color;
              padding-top: 1rem;
              
              .GM__light-toggles-container {
                display: flex;
                justify-content: flex-start;
                flex-direction: row; 
                align-items: flex-start;
                gap: 2rem;
              }
              .GM__light-toggle-wrapper {
                display: flex;
                flex-direction: column;
                min-width: 180px;
              }
              .GM__light-toggle-container {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                margin-top: 0.5rem;
              }
              
              .GM__light-toggle-input {
                appearance: none;
                position: relative;
                width: 50px;
                height: 24px;
                border-radius: 25px;
                background-color: $light-text-muted;
                transition: background-color 0.3s ease;
                outline: none;
                cursor: pointer;
                
                &:checked {
                  background-color: $light-success-color;
                }
                
                &::before {
                  content: '';
                  position: absolute;
                  top: 2px;
                  left: 2px;
                  width: 20px;
                  height: 20px;
                  border-radius: 50%;
                  background-color: white;
                  transition: transform 0.3s ease;
                }
                
                &:checked::before {
                  transform: translateX(26px);
                }
              }
              
              .GM__light-toggle-label {
                font-size: 14px;
                color: $light-text-secondary;
              }
            }
          }
        }

        // Grupos de input compartidos
        .GM__light-input-group {
          width: 100%;
          
          .GM__light-input-label {
            display: block;
            margin-bottom: 0.5rem;
            font-weight: 500;
            color: $light-text-primary;
            font-size: 0.9rem;
          }
        }
      }

      // Sección de contacto
      .GM__light-contact-section {
        background-color: $light-background-secondary;
        border-radius: $light-border-radius;
        box-shadow: 0 2px 10px $light-shadow-color;
        padding: 2rem;
        animation: slideIn 0.3s ease;

        .GM__light-section-title {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1.5rem;
          color: $light-text-primary;
          padding-bottom: 0.75rem;
          border-bottom: 1px solid $light-border-color;
        }

        .GM__light-contact-row {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 1.5rem;
          margin-bottom: 2rem;

          .GM__light-phone-group {
            .GM__light-input-group {
              .GM__light-input-label {
                display: block;
                margin-bottom: 0.5rem;
                font-weight: 500;
                color: $light-text-primary;
                font-size: 0.9rem;
              }
              
              .GM__light-phone-input-container {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              }
            }
          }

          .GM__light-email-group {
            display: flex;
            flex-direction: column;
            
            .GM__light-input-label {
              display: block;
              margin-bottom: 0.5rem;
              font-weight: 500;
              color: $light-text-primary;
              font-size: 0.9rem;
            }
          }
        }
        
        .GM__light-location-section {
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid $light-border-color;
        }
      }

      // Sección de documentos
      .GM__light-documents-section {
        background-color: $light-background-secondary;
        border-radius: $light-border-radius;
        box-shadow: 0 2px 10px $light-shadow-color;
        padding: 1rem;
        animation: slideIn 0.3s ease;
        
        .GM__light-document-type-table {
          margin-bottom: 2rem;
          border: 1px solid $light-border-color;
          border-radius: $light-border-radius;
          overflow: hidden;
          
          .GM__light-table-header {
            background-color: $light-background-tertiary;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $light-border-color;
            
            .GM__light-table-title {
              margin: 0;
              font-size: 1.1rem;
              font-weight: 600;
              color: $light-text-primary;
            }
            
            .GM__light-table-description {
              flex: 1;
              margin: 0 1rem;
              font-size: 0.9rem;
              color: $light-text-secondary;
            }
            
            .GM__light-add-document-btn {
              background-color: $light-accent-color;
              color: white;
              border: none;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: all 0.2s ease;
              
              &:hover {
                background-color: $light-accent-hover;
                transform: translateY(-2px);
              }
              
              .GM__light-icon-image {
                width: 16px;
                height: 16px;
                filter: brightness(0) invert(1);
              }
            }
          }
          
          .GM__light-document-table {
            width: 100%;
            border-collapse: collapse;
            
            .GM__light-table-header-cell {
              padding: 0.75rem;
              font-weight: 600;
              border-bottom: 2px solid $light-border-color;
              text-align: left;
              background-color: $light-background-tertiary;
              color: $light-text-primary;
              font-size: 0.85rem;
              text-transform: uppercase;
            }
            
            .GM__light-table-row {
              &:nth-child(even) {
                background-color: rgba($light-background-tertiary, 0.5);
              }
              
              &:hover {
                background-color: rgba($light-accent-color, 0.05);
              }
              
              .GM__light-table-cell {
                padding: 0.75rem;
                border-bottom: 1px solid $light-border-color;
                vertical-align: middle;
                
                &.GM__light-status-cell {
                  width: 60px;
                  text-align: center;
                  
                  .GM__light-status-icon {
                    width: 24px;
                    height: 24px;
                  }
                }
                
                &.GM__light-file-cell {
                  .GM__light-file-upload {
                    .GM__light-file-label {
                      display: inline-block;
                      padding: 0.5rem 0.75rem;
                      background-color: $light-background-tertiary;
                      border-radius: 4px;
                      cursor: pointer;
                      border: 1px solid $light-border-color;
                      font-size: 0.85rem;
                      transition: all 0.2s ease;
                      
                      &:hover {
                        background-color: darken($light-background-tertiary, 5%);
                        border-color: $light-accent-color;
                      }
                    }
                  }
                }
                
                &.GM__light-date-cell {
                  min-width: 150px;
                }
                
                &.GM__light-description-cell {
                  min-width: 200px;
                }
                
                &.GM__light-checkbox-cell {
                  text-align: center;
                  width: 80px;
                  
                  .GM__light-checkbox {
                    width: 18px;
                    height: 18px;
                    accent-color: $light-accent-color;
                    cursor: pointer;
                  }
                }
                
                &.GM__light-actions-cell {
                  width: 100px;
                  text-align: center;
                  
                  .GM__light-delete-btn {
                    background-color: $light-danger-color;
                    color: white;
                    border: none;
                    padding: 0.5rem 0.75rem;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    font-size: 0.85rem;
                    
                    &:hover {
                      background-color: darken($light-danger-color, 10%);
                      transform: translateY(-2px);
                    }
                  }
                }
              }
            }
            
            .GM__light-empty-row {
              .GM__light-empty-message {
                padding: 1.5rem;
                text-align: center;
                color: $light-text-secondary;
                font-style: italic;
                background-color: $light-background-tertiary;
                border-radius: 4px;
                margin: 1rem;
              }
            }
          }
        }
      }

      // Sección del botón guardar
      .GM__light-save-section {
        display: flex;
        justify-content: flex-end;
        padding: 1.5rem;
        background-color: $light-background-secondary;
        border-radius: $light-border-radius;
        box-shadow: 0 2px 10px $light-shadow-color;

        .GM__light-save-button {
          background-color: $light-accent-color;
          color: white;
          border: none;
          padding: 0.75rem 2rem;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease;
          box-shadow: 0 2px 5px rgba($light-accent-color, 0.3);

          &:hover {
            background-color: $light-accent-hover;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba($light-accent-color, 0.4);
          }
        }
      }
    }
  }
}