// Variables para el tema oscuro
$background-dark: #121212;
$background-darker: #1e1e1e;
$border-color-dark: #333;
$border-color-darker: #444;
$text-color-dark: #fff;
$text-color-muted-dark: #999;
$accent-color-dark: #4CAF50;
$accent-hover-dark: #3f8f3f;
$danger-color-dark: #f44336;
$danger-hover-dark: #d32f2f;

.GM__dark-add-vehicle-container {
  height: 80vh;
  background-color: $background-dark;
  align-items: center;
  padding: 1rem;
  min-height: min-content;
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  
  .GM__dark-page-title {
    color: $text-color-dark;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .GM__dark-vehicle-form-container {
    width: 100%;
    max-width: 1200px;
    background: $background-darker;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    margin-bottom: 2rem;
    
    .GM__dark-stepper {
      padding: 24px 16px;
      background-color: $background-darker;
      border-bottom: 1px solid $border-color-dark;
      
      .MuiStepIcon-root {
        color: $accent-color-dark;
        
        &.MuiStepIcon-active {
          color: $accent-color-dark;
        }
        
        &.MuiStepIcon-completed {
          color: $accent-color-dark;
        }
      }
      
      .MuiStepLabel-label {
        color: $text-color-dark;
        
        &.MuiStepLabel-active {
          color: $text-color-dark;
          font-weight: 600;
        }
        
        &.MuiStepLabel-completed {
          color: $text-color-muted-dark;
        }
      }
    }
    
    .GM__dark-step-content {
      padding: 10px;
      min-height: 400px;
      
      // Contenido específico para VehicleTypeSelector
      .vehicle-type-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 16px;
        
        .vehicle-type-card {
          background-color: $background-darker;
          border: 1px solid $border-color-dark;
          border-radius: 8px;
          padding: 16px;
          text-align: center;
          cursor: pointer;
          transition: all 0.2s ease;
          
          &:hover {
            border-color: $accent-color-dark;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
          }
          
          &.selected {
            border: 2px solid $accent-color-dark;
            background-color: rgba($accent-color-dark, 0.1);
          }
          
          img {
            width: 80px;
            height: 80px;
            object-fit: contain;
            margin-bottom: 12px;
            filter: brightness(0.9);
          }
          
          h3 {
            font-size: 16px;
            margin: 0;
            color: $text-color-dark;
          }
        }
      }
      
      // Contenido específico para VehicleInfoSection
      .vehicle-info-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        
        .form-field {
          margin-bottom: 16px;
          
          label {
            display: block;
            font-size: 14px;
            margin-bottom: 8px;
            color: $text-color-dark;
          }
          
          input, select {
            width: 100%;
            padding: 10px 12px;
            border: 1px solid $border-color-dark;
            border-radius: 4px;
            font-size: 14px;
            color: $text-color-dark;
            background-color: $background-dark;
            
            &:focus {
              outline: none;
              border-color: $accent-color-dark;
            }
          }
        }
      }
      
      // Contenido específico para ContactSectionAddVehicle
      .contact-form {
        .form-row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          margin-bottom: 24px;
          
          @media (max-width: 768px) {
            grid-template-columns: 1fr;
          }
        }
        
        .date-range {
          display: flex;
          gap: 16px;
          align-items: center;
          
          .date-field {
            flex: 1;
          }
        }
      }
      
      // Contenido específico para DocumentsSection
      .documents-section {
        .operation-selector {
          margin-bottom: 24px;
          
          label {
            display: block;
            margin-bottom: 8px;
            font-weight: 500;
            color: $text-color-dark;
          }
        }
        
        .documents-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          gap: 20px;
          margin-bottom: 24px;
          
          .document-card {
            border: 1px solid $border-color-dark;
            border-radius: 8px;
            padding: 16px;
            position: relative;
            background-color: $background-dark;
            
            &.required {
              border-left: 3px solid $accent-color-dark;
            }
            
            .required-badge {
              position: absolute;
              top: 8px;
              right: 8px;
              background-color: $accent-color-dark;
              color: $text-color-dark;
              font-size: 12px;
              padding: 2px 8px;
              border-radius: 12px;
            }
            
            .document-actions {
              display: flex;
              justify-content: space-between;
              margin-top: 16px;
            }
          }
        }
        
        .documents-table {
          width: 100%;
          border-collapse: collapse;
          
          th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid $border-color-dark;
            color: $text-color-dark;
          }
          
          th {
            background-color: $background-dark;
            font-weight: 600;
          }
          
          tr:hover {
            background-color: lighten($background-darker, 5%);
          }
          
          .action-column {
            width: 100px;
            text-align: center;
          }
        }
      }
    }
    
    .GM__dark-navigation-buttons {
      display: flex;
      justify-content: space-between;
      padding: 24px 32px;
      border-top: 1px solid $border-color-dark;
      
      .GM__dark-back-button {
        background-color: transparent;
        color: $text-color-dark;
        border: 1px solid $border-color-dark;
        
        &:hover {
          background-color: rgba(255, 255, 255, 0.05);
        }
        
        &:disabled {
          color: $text-color-muted-dark;
          border-color: $border-color-darker;
        }
      }
      
      .GM__dark-next-button,
      .GM__dark-submit-button {
        background-color: $accent-color-dark;
        color: white;
        
        &:hover {
          background-color: $accent-hover-dark;
        }
        
        &:disabled {
          background-color: darken($accent-color-dark, 15%);
          opacity: 0.7;
        }
      }
    }
  }
}