// DashboardHumanResourceDark.scss - Versión mejorada

// Contenedor principal
.dashboard-rrhh-container {
  &.theme-dark {
    background-color: $dark-bg-primary;
    color: $dark-text-primary;
    box-shadow: 0 4px 6px $dark-shadow;
  }
}

// Sección de controles
.controls-section {
  &.theme-dark {
    background: $dark-bg-secondary;
    box-shadow: 0 2px 4px 0 $dark-shadow;
    border-radius: $border-radius;
    padding: $spacing-lg $spacing-xl;
  }
}

// Filtros para tema oscuro
.filters-container {
  .filter-group {
    input, select {
      &.theme-dark {
        background-color: lighten($dark-bg-secondary, 5%);
        color: $dark-text-primary;
        border: 1px solid $dark-input-border;
        font-weight: 500;
        letter-spacing: 0.01em;
        
        &:focus {
          outline: none;
          border-color: $dark-input-focus;
          box-shadow: 0 0 0 2px rgba($dark-input-focus, 0.3);
        }

        &:hover:not(:disabled) {
          border-color: lighten($dark-input-border, 15%);
        }

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
          background-color: $dark-hover;
        }

        &::placeholder {
          color: rgba($dark-text-secondary, 0.7);
        }
      }
    }
  }
}

// Leyenda de estados
.status-legend {
  &.theme-dark {
    border-top: 1px solid $dark-border;
    color: $dark-text-secondary;
    display: flex;
    justify-content: center;
    padding: $spacing-md $spacing-lg;
  }
  
  .status-dot {
    &.expired {
      background-color: $status-expired-cell-dark;
    }
    
    &.critical {
      background-color: $status-critical-cell-dark;
    }
    
    &.warning {
      background-color: $status-warning-cell-dark;
    }
    
    &.valid {
      background-color: $status-valid-dark;
    }
    
    &.not-required {
      background-color: $status-not-required-dark;
    }
    
    &.empty {
      background-color: $status-empty-dark;
    }
  }

  .legend-item {
    .theme-dark & {
      font-size: $font-size-sm;
      font-weight: 500;
      letter-spacing: 0.02em;
    }
  }
}

// Contenedor de tabla
.documents-table-container {
  &.theme-dark {
    background: $dark-bg-secondary;
    box-shadow: 0 2px 4px 0 $dark-shadow;
    border-radius: $border-radius;
    
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $dark-scrollbar-track;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $dark-scrollbar-thumb;
      border-radius: 4px;
      
      &:hover {
        background: $dark-scrollbar-hover;
      }
    }
  }
}

// Tabla de documentos
.documents-table {
  &.theme-dark {
    th {
      background: $dark-table-header;
      color: $dark-text-primary;
      font-weight: 600;
      padding: 0.875rem $spacing-md;
      text-transform: uppercase;
      font-size: $font-size-xs;
      letter-spacing: 0.05em;
      min-width: 150px; // Ancho mínimo para columnas
      border-bottom: 2px solid $dark-border;
    }

    td {
      border-bottom: 1px solid $dark-border;
      padding: $spacing-md;
      min-width: 150px; // Ancho mínimo para columnas de datos
      
      &.fixed-column {
        background: $dark-bg-secondary;
        border-right: 1px solid $dark-border;
        position: sticky;
        z-index: 10;
        
        &:first-child {
          min-width: 190px;
          left: 0;
          z-index: 20;
        }
        
        &:nth-child(2) {
          min-width: 160px;
          left: 190px;
          z-index: 19;
        }
        
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background: linear-gradient(to right, $dark-shadow, transparent);
        }
      }
    }

    tr:hover:not(thead tr) {
      background-color: rgba($dark-hover, 0.7);
    }

    .user-name {
      font-weight: 600;
      font-size: $font-size-sm;
      margin-bottom: $spacing-xs;
    }

    .user-email {
      font-size: $font-size-xs;
      color: $dark-text-secondary;
      font-style: italic;
    }
  }
}

// Estados de documentos para tema oscuro
.document-cell {
  &.expired {
    background-color: $status-expired-dark;
    
    .theme-dark & .document-info {
      .days-remaining {
        color: lighten($status-expired-cell-dark, 40%);
        font-weight: 600;
      }
    }
  }
  
  &.critical {
    background-color: $status-critical-dark;
    
    .theme-dark & .document-info {
      .days-remaining {
        color: lighten($status-critical-cell-dark, 40%);
        font-weight: 600;
      }
    }
  }
  
  &.warning {
    background-color: $status-warning-dark;
    
    .theme-dark & .document-info {
      .days-remaining {
        color: lighten($status-warning-cell-dark, 30%);
        font-weight: 600;
      }
    }
  }
  
  &.valid {
    background-color: $status-valid-dark;
  }
  
  &.not-required {
    background-color: $status-not-required-dark;
  }
  
  &.empty {
    background-color: $status-empty-dark;
  }

  .document-info {
    .theme-dark & {
      display: flex;
      flex-direction: column;
      gap: $spacing-xs;
      
      .document-name {
        color: $dark-text-primary;
        font-weight: 500;
        font-size: $font-size-sm;
        word-break: break-word;
        max-width: 200px; 
      }
      
      .expiration-date {
        color: $dark-text-secondary;
        font-size: $font-size-xs;
      }
      
      .days-remaining {
        color: $dark-text-secondary;
        font-size: $font-size-xs;
        font-style: italic;
      }
    }
  }

  .no-document {
    .theme-dark & {
      color: rgba($dark-text-secondary, 0.7);
      font-style: italic;
      padding: $spacing-xs 0;
      font-size: $font-size-xs;
      text-align: center;
    }
  }
}

// Filas con documentos expirados
tr.expired-row {
  .theme-dark & {
    background-color: rgba($status-expired-dark, 0.2);
    
    &:hover {
      background-color: rgba($status-expired-dark, 0.3);
    }
    
    td:not([class*="document-cell"]) {
      border-bottom-color: rgba($status-expired-cell-dark, 0.3);
    }
  }
}

// Paginación
.pagination-controls {
  &.theme-dark {
    background: $dark-bg-secondary;
    box-shadow: 0 1px 3px 0 $dark-shadow;
    padding: $spacing-lg;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $spacing-md;
  }

  .pagination-button {
    &.theme-dark {
      background: lighten($dark-bg-secondary, 5%);
      color: $dark-text-primary;
      border: 1px solid $dark-border;
      padding: $spacing-sm $spacing-lg;
      font-size: $font-size-sm;
      font-weight: 500;
      border-radius: $border-radius;
      transition: all 0.2s ease;
      
      &:hover:not(:disabled) {
        background: $dark-hover;
        transform: translateY(-1px);
      }
      
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .pagination-numbers {
    .page-number {
      &.theme-dark {
        background: lighten($dark-bg-secondary, 5%);
        color: $dark-text-primary;
        border: 1px solid $dark-border;
        min-width: 2.2rem;
        height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-sm;
        border-radius: $border-radius;
        font-weight: 500;
        transition: all 0.2s ease;
        
        &:hover:not(.active) {
          background: $dark-hover;
          transform: translateY(-1px);
        }
        
        &.active {
          background: #3b82f6;
          color: white;
          border-color: #3b82f6;
          font-weight: 600;
        }
      }
    }
  }

  .pagination-info {
    color: $dark-text-secondary;
    font-size: $font-size-sm;
    padding: 0 $spacing-md;
  }
}

// Estados de carga y error
.dashboard-loading,
.dashboard-error {
  &.theme-dark {
    background: $dark-bg-secondary;
    color: $dark-text-primary;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px 0 $dark-shadow;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    flex-direction: column;
    gap: $spacing-lg;
    
    .loading-text {
      font-size: $font-size-base;
      color: $dark-text-secondary;
      margin-top: $spacing-md;
    }
  }
}

// Media queries para responsividad
@media (max-width: 1024px) {
  .documents-table-container {
    &.theme-dark {
      margin: 0 -$spacing-md;
      
      .documents-table {
        th, td {
          &.fixed-column {
            &:nth-child(2) {
              left: 180px;
            }
          }
        }
      }
    }
  }
  
  .status-legend {
    &.theme-dark {
      flex-wrap: wrap;
      justify-content: center;
      gap: $spacing-md;
    }
  }
}

@media (max-width: 640px) {
  .dashboard-rrhh-container {
    &.theme-dark {
      padding: $spacing-md;
    }
  }
  
  .documents-table {
    &.theme-dark {
      font-size: $font-size-xs;
      
      th, td {
        padding: $spacing-sm;
        
        &.fixed-column {
          &:first-child {
            min-width: 150px;
          }
          
          &:nth-child(2) {
            left: 150px;
            min-width: 140px;
          }
        }
      }
    }
  }
  
  .pagination-controls {
    &.theme-dark {
      flex-wrap: wrap;
      
      .pagination-info {
        width: 100%;
        text-align: center;
        order: -1;
        margin-bottom: $spacing-sm;
      }
    }
  }
}