// BasicDataSectionLight.scss
.GM__light-basic-data-section {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;

  .GM__light-section-title {
    background-color: #00C853;
    color: #ffffff;
    border-bottom: 1px solid #00B848;
  }

  .GM__light-data-form {
    background-color: #ffffff;
  }

  .GM__light-input-label {
    color: #444444;
  }

  .GM__light-input,
  .GM__light-select {
    border: 1px solid #d0d0d0;
    background-color: #ffffff;
    color: #333333;
    transition: border-color 0.2s;

    &:focus {
      border-color: #00C853;
      outline: none;
    }

    &:disabled {
      background-color: #f5f5f5;
      color: #888888;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #aaaaaa;
    }
  }

  .GM__light-consult-button {
    background-color: #00C853;
    color: white;
    transition: background-color 0.2s;
    
    &:hover:not(:disabled) {
      background-color: #00B848;
    }
  }

  .GM__light-clear-button {
    background-color: #f44336;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #e53935;
    }
  }

  .GM__light-pdf-button {
    background-color: #2196F3;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #1E88E5;
    }
  }

  .GM__light-share-button {
    background-color: #673AB7;
    color: white;
    
    &:hover:not(:disabled) {
      background-color: #5E35B1;
    }
  }
}