// Variables para el tema oscuro
$background-dark: #121212;
$surface-dark: #1e1e1e;
$border-dark: #333333;
$text-primary-dark: #ffffff;
$text-secondary-dark: #aaaaaa;
$accent-dark: #4CAF50;
$input-background-dark: #2d2d2d;
$input-border-dark: #444444;
$input-focus-dark: #4CAF50;
$input-hover-dark: #333333;
$label-dark: #f0f0f0;
$dark-text-muted: #6c757d;
$dark-border-color: #343a40;
$dark-success-color: #0bb783;
$dark-text-secondary: #adb5bd;

.GM__dark-vehicle-info-section {
  padding: 24px 0;
  background-color: $surface-dark;
  border-radius: 8px;
  
  .GM__dark-section-title {
    font-size: 22px;
    font-weight: 600;
    color: $text-primary-dark;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-dark;
  }
  
  .GM__dark-input-group {
    margin-bottom: 20px;
    
    .GM__dark-input-label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $label-dark;
    }
    
    // Estilos para los inputs personalizados
    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid $input-border-dark;
      border-radius: 4px;
      font-size: 14px;
      color: $text-primary-dark;
      background-color: $input-background-dark;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      
      &:hover {
        background-color: $input-hover-dark;
      }
      
      &:focus {
        outline: none;
        border-color: $input-focus-dark;
        box-shadow: 0 0 0 2px rgba($input-focus-dark, 0.2);
      }
      
      &::placeholder {
        color: $text-secondary-dark;
      }
    }
    
    // Ajustes para SortDropdown
    .GM__dark-sort-dropdown {
      .dropdown-toggle {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $input-border-dark;
        border-radius: 4px;
        font-size: 14px;
        color: $text-primary-dark;
        background-color: $input-background-dark;
        transition: border-color 0.2s ease, box-shadow 0.2s ease;
        
        &:hover {
          background-color: $input-hover-dark;
        }
      }
      
      .dropdown-menu {
        border: 1px solid $border-dark;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
        background-color: $surface-dark;
        
        .dropdown-item {
          color: $text-primary-dark;
          padding: 8px 12px;
          
          &:hover {
            background-color: $input-hover-dark;
          }
          
          &.active {
            background-color: rgba($accent-dark, 0.2);
            color: $accent-dark;
          }
        }
      }
    }
  }
  
  .GM__dark-info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    .notification-toggle {
      margin-top: 0.5rem;
      border-top: 1px solid $light-border-color;
      padding-top: 5px;
      
      .GM__dark-toggles-container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
      }
      
      .GM__dark-toggle-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 180px;
      }
      
      .GM__dark-toggle-container {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        margin-top: 0.5rem;
      }
      
      .GM__dark-toggle-input {
        appearance: none;
        position: relative;
        width: 50px;
        height: 24px;
        border-radius: 25px;
        background-color: $light-text-muted;
        transition: background-color 0.3s ease;
        outline: none;
        cursor: pointer;
        
        &:checked {
          background-color: $light-success-color;
        }
        
        &::before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: white;
          transition: transform 0.3s ease;
        }
        
        &:checked::before {
          transform: translateX(26px);
        }
      }
      
      .GM__dark-toggle-label {
        font-size: 14px;
        color: $light-text-secondary;
      }
    }
  }
}