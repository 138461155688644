.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #2b2b2b;
  color: #fff;
  height: 100%;
  position: relative;
  
  // Estado cuando no hay chat seleccionado
  .no-chat-selected {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    
    h2 {
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      font-size: 1.2rem;
    }
  }

  // Encabezado del chat
  .chat-header {
    padding: 1rem;
    border-bottom: 1px solid #1E1E1E;
    background-color: #333;
    position: sticky;
    top: 0;
    z-index: 10;

    .global-chat-header {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .header-title {
        h3 {
          margin: 0;
          font-size: 1.2rem;
          font-weight: 600;
        }
      }

      .online-users-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        min-height: 40px;
        position: relative;

        .nav-button {
          color: #fff;
          padding: 4px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          
          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          &.prev {
            position: absolute;
            left: 0;
            z-index: 2;
          }

          &.next {
            position: absolute;
            right: 0;
            z-index: 2;
          }
        }

        .users-chips {
          flex: 1;
          overflow: hidden;
          margin: 0 30px;
          justify-content: flex-start;
          padding: 0 4px;

          .user-chip {
            background-color: rgba(255, 255, 255, 0.1);
            color: #fff;
            transition: all 0.3s ease;
            min-width: 100px;
            
            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
              cursor: pointer;
            }

            .MuiChip-icon {
              color: #fff;
            }

            .MuiChip-label {
              max-width: 150px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  // Cuerpo principal del chat
  .chat-body {
    flex: 1;
    padding: 1.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    scroll-behavior: smooth;

    // Indicador de escritura
    .typing-indicator {
      font-size: 0.85rem;
      color: rgba(255, 255, 255, 0.7);
      padding: 0.5rem;
      font-style: italic;
      margin: 0.5rem 0;
    }
  }

  // Contenedor de mensajes
  .message-container {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    margin-bottom: 0.5rem;
    position: relative;
    background: transparent;

    &.own-message {
      align-self: flex-end;
      width: 100%;
      height: auto;
      
      .message-content {
        background-color: #2A2D34;
        border-radius: 15px 15px 0 15px;
        margin-left: auto;

        .message-text {
          color: #fff;
        }

        .message-timestamp {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }

    &:not(.own-message) {
      align-self: flex-start;
      width: 100%;
      height: auto;
      
      .message-content {
        background-color: #34495e;
        border-radius: 15px 15px 15px 0;

        .message-text {
          color: #fff;
        }
      }
    }

    .message-content {
      padding: 0.8rem 1rem;
      position: relative;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
      width: 100%;
      height: auto;
      
      .message-author {
        display: block;
        font-size: 0.85rem;
        font-weight: 600;
        color: #00f7ff;
        margin-bottom: 0.3rem;
      }

      .message-text {
        margin: 0;
        font-size: 0.95rem;
        line-height: 1.4;
        word-wrap: break-word;
      }

      .message-timestamp {
        display: block;
        font-size: 0.75rem;
        color: rgba(255, 255, 255, 0.7);
        margin-top: 0.4rem;
        text-align: right;
      }

      .edited-indicator {
        font-size: 0.75rem;
        color: rgba(255, 255, 255, 0.6);
        font-style: italic;
        margin-left: 0.5rem;
      }
    }

    // Mensajes con respuesta
    .replied-message {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 0.5rem;
      border-radius: 8px;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      max-width: 100%;

      .reply-author {
        color: #00f7ff;
        font-size: 0.8rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
        display: block;
      }

      p {
        margin: 0;
        opacity: 0.8;
        word-break: break-word;
      }
    }

    // Archivos adjuntos
    .message-attachments {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      max-width: 100%;

      .attachment {
        max-width: 200px;
        
        .attachment-image {
          width: 100%;
          border-radius: 8px;
          object-fit: cover;
          max-height: 200px;
        }

        .attachment-file {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 0.5rem;
          border-radius: 4px;
          text-decoration: none;
          color: #fff;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          i {
            font-size: 1.2rem;
          }

          span {
            font-size: 0.85rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 150px;
          }
        }
      }
    }

    // Acciones del mensaje
    .message-actions {
      position: absolute;
      top: -1.5rem;
      right: 0;
      display: flex;
      gap: 0.5rem;
      opacity: 0;
      transition: opacity 0.2s ease;
      background-color: #2b2b2b;
      padding: 0.3rem;
      border-radius: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      button {
        background-color: #444;
        border: none;
        color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background-color: #555;
          transform: scale(1.1);
        }

        i {
          font-size: 0.8rem;
        }
      }
    }

    &:hover .message-actions {
      opacity: 1;
    }
  }

  // Vista previa de archivos adjuntos
  .attachments-preview {
    padding: 0.8rem;
    border-top: 1px solid #444;
    background-color: #333;

    .attachment-item {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      .attachment-preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .file-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #444;
        padding: 0.5rem;
        
        .file-icon {
          width: 50px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.5rem;
          
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .file-name {
          font-size: 0.7rem;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          color: rgba(255, 255, 255, 0.8);
          margin-top: 0.25rem;
        }
      }

      .remove-attachment {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        background-color: rgba(0, 0, 0, 0.6);
        border: none;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
          transform: scale(1.1);
        }
      }
    }
  }

  // Vista previa de respuesta
  .reply-preview {
    padding: 0.8rem;
    border-top: 1px solid #444;
    background-color: #333;
    display: flex;
    align-items: center;
    gap: 1rem;

    .reply-content {
      flex: 1;
      border-left: 3px solid #2ecc71;
      padding-left: 0.8rem;

      .reply-author {
        font-size: 0.85rem;
        color: #00f7ff;
        font-weight: 600;
        display: block;
        margin-bottom: 0.2rem;
      }

      .reply-text {
        margin: 0;
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close-reply {
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      opacity: 0.7;
      padding: 0.4rem;
      border-radius: 50%;
      transition: all 0.2s ease;
      
      &:hover {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.1);
        transform: scale(1.1);
      }
    }
  }

  // Pie de chat con formulario de entrada
  .chat-footer {
    border-top: 1px solid #444;
    padding: 0.8rem;
    background-color: #333;
    position: sticky;
    bottom: 0;

    form {
      .message-input-container {
        display: flex;
        gap: 0.8rem;
        align-items: center;

        input[type="text"] {
          flex: 1;
          padding: 0.8rem;
          border-radius: 20px;
          border: 1px solid #444;
          outline: none;
          background-color: #2b2b2b;
          color: #fff;
          font-size: 0.95rem;
          transition: border-color 0.2s ease;

          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }

          &:focus {
            border-color: #2ecc71;
          }
        }

        .input-actions {
          display: flex;
          gap: 0.8rem;

          button {
            padding: 0.8rem;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease;
            
            &.attachment-button {
              background-color: #444;
              color: #fff;
              width: 40px;
              height: 40px;
              
              &:hover {
                background-color: #555;
                transform: scale(1.1);
              }
            }

            &[type="submit"] {
              background-color: #efefef;
              color: #fff;
              width: 40px;
              height: 40px;
              
              &:hover {
                background-color: #27ae60;
                transform: scale(1.1);
              }

              &:disabled {
                background-color: #666;
                cursor: not-allowed;
                transform: none;
              }
            }

            i {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}