// Variables de diseño
$primary-color: #3f51b5;
$secondary-color: #f50057;
$background-color: #f5f5f500;
$background-content-color: #1E1E1E;
$card-background: #ffffff;
$text-color: #ffffff;

// Breakpoints
$desktop-breakpoint: 1200px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 576px;

.repeatOffenders-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  padding: 20px;
  height: 85vh;
  background-color: $background-color;
  overflow: auto;

  .repeatOffenders-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .repeatOffenders-title {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color;
    }
  }
  .repeatOffenders-content {
    height: 100%;
  }

}