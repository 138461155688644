.map-controls {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    &__drawing,
    &__zoom,
    &__types {
      background-color: white;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      
      .control-btn {
        border: none;
        background: none;
        padding: 10px;
        font-size: 1.2rem;
        cursor: pointer;
        color: #555;
        
        &:hover {
          color: #2196f3;
        }
        
        &.active {
          color: #2196f3;
          background-color: #e3f2fd;
        }
        
        &.cancel-btn {
          color: #f44336;
          font-size: 0.9rem;
          
          &:hover {
            color: #d32f2f;
          }
        }
      }
    }
    
    &__drawing-info {
      position: absolute;
      top: 200px;
      right: 0;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      padding: 10px;
      width: 250px;
      
      .drawing-indicator {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        
        .pulse {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #4caf50;
          animation: pulse 1.5s infinite;
        }
        
        span {
          font-size: 0.9rem;
          color: #555;
        }
      }
    }
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
  }