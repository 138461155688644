// Variables globales para colores y estilos comunes
$primary-color: #007bff;
$secondary-color: #404348;
$background-dark: #2A2D34;
$text-light: #ffffff;
$text-muted: #e0e0e0;
$border-color: #404348;
$hover-color: #0056b3;
$danger-color: #dc3545;
$warning-color: #ffc107;
$success-color: #2e7d32;

// Estilos de la tabla principal
.custom-table-container {
  margin: 2rem 0;
  font-family: "Roboto", sans-serif;
  background: $background-dark;
  padding: 25px 10px;
  border-radius: 30px;

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-light;
    }
    .table-controls {
      display: flex;
      gap: 1rem;
      align-items: center;
  
      .search-container {
        .search-input {
          padding: 0.5rem 1rem;
          border: 1px solid $border-color;
          border-radius: 4px;
          background-color: lighten($background-dark, 5%);
          color: $text-muted;
          min-width: 250px;
          font-size: 0.9rem;
  
          &:focus {
            outline: none;
            border-color: $primary-color;
            box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
          }
  
          &::placeholder {
            color: darken($text-muted, 30%);
          }
        }
      }
  
      .filter-container {
        .role-filter {
          padding: 0.5rem 2rem 0.5rem 1rem;
          border: 1px solid $border-color;
          border-radius: 4px;
          background-color: lighten($background-dark, 5%);
          color: $text-muted;
          font-size: 0.9rem;
          cursor: pointer;
          appearance: none;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23ffffff' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 0.7rem center;
          min-width: 180px;
  
          &:focus {
            outline: none;
            border-color: $primary-color;
            box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
          }
  
          option {
            background-color: $background-dark;
            color: $text-muted;
          }
        }
      }
    }
  }

  .table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: #f5f5f5;

      th {
        padding: 0.75rem;
        text-align: left;
        font-weight: 600;
        color: #000000;
        border-bottom: 2px solid #ccc;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #eee;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(250, 250, 250, 0.14);
        }

        td {
          padding: 0.75rem;
          color: $text-muted;
        }
      }
    }
  }

  // Estilos para los indicadores de estado
  .status-indicator {
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: inline-block;

    &.status-active {
      background-color: #e8f5e9;
      color: $success-color;
    }

    &.status-inactive {
      background-color: #fafafa;
      color: #121212;
    }
  }

  // Estilos para los botones
  .btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;

    &.btn-primary {
      background-color: $primary-color;
      color: $text-light;

      &:hover {
        background-color: $hover-color;
      }
    }

    &.btn-warning {
      background-color: $warning-color;
      color: #000;

      &:hover {
        background-color: darken($warning-color, 10%);
      }
    }

    &.btn-danger {
      background-color: $danger-color;
      color: $text-light;

      &:hover {
        background-color: darken($danger-color, 10%);
      }
    }

    &.btn-secondary {
      background-color: $secondary-color;
      color: $text-light;

      &:hover {
        background-color: lighten($secondary-color, 5%);
      }
    }

    &.btn-sm {
      padding: 0.5rem 0.75rem;
      font-size: 0.875rem;
    }
  }

  .table-actions {
    display: flex;
    gap: 0.5rem;
  }
}

// Estilos de paginación
.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;

  .custom-pagination {
    .MuiPaginationItem-root {
      font-size: 14px;
      min-width: 32px;
      height: 32px;
      margin: 0 4px;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      color: $text-light;

      &.Mui-selected {
        background-color: $primary-color;
        color: $text-light;
      }

      &:hover {
        background-color: rgba($primary-color, 0.1);
      }
    }
  }
}

// Estilos del modal y formulario
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $background-dark;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  outline: none;
  padding: 2rem;

  .modal-title {
    margin: 0 0 1.5rem 0;
    font-size: 1.3rem;
    color: $text-light;
    font-weight: 600;
  }

  .custom-form {
    .form-group {
      margin-bottom: 1.25rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: $text-muted;
      }

      &.form-check {
        .checkbox-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;
        }
      }

      .form-control {
        width: 100%;
        padding: 0.75rem;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-size: 0.95rem;
        color: $text-muted;
        background-color: lighten($background-dark, 5%);
        transition: border-color 0.2s ease;

        &:focus {
          outline: none;
          border-color: $primary-color;
          box-shadow: 0 0 0 2px rgba($primary-color, 0.2);
        }

        &::placeholder {
          color: #808080;
        }
      }

      .form-error {
        display: block;
        margin-top: 0.5rem;
        color: $danger-color;
        font-size: 0.875rem;
      }
    }

    .form-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }
}