// Variables para tema claro
$light-background: #ffffff;
$light-panel-background: #f5f5f5;
$light-text-color: #333333;
$light-secondary-color: #666666;
$light-border-color: #e0e0e0;
$light-accent-color: #3a86ff;
$light-hover-background: rgba(58, 134, 255, 0.1);
$light-active-background: rgba(58, 134, 255, 0.2);
$light-error-color: #e66;
$light-spacing-unit: 8px;

.GM__light-operator-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background: transparent;
  color: $light-text-color;
  
  .GM__light-operator-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $light-spacing-unit * 3;
    gap: $light-spacing-unit * 2;
    flex-wrap: wrap;
    
    .GM__light-operator-filter-container {
      flex: 0 0 auto;
      height: 100%;
      min-width: 150px; // Asegura un ancho mínimo para el botón de filtro
      
      // Asegura que los elementos dentro del contenedor de filtro ocupen todo el ancho
      > button, > div {
        width: 100%;
        height: 40px; // Altura fija que coincide con los otros elementos
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .GM__light-search-container {
      flex: 1;
      min-width: 200px;
      display: flex;
      justify-content: flex-end; // Alinea el contenido a la derecha
      
      .GM__light-search-wrapper {
        border: 1px solid #e0e0e0;
        border-radius: 20px;
        overflow: hidden;
        width: 100%; // Ocupa todo el ancho disponible
        max-width: 400px; // Limita el ancho máximo
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: auto; // Empuja el elemento hacia la derecha
  
        .GM__light-search-input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0 15px;
          background: transparent;
          font-size: 0.9rem;
          height: 100%;
          color: $light-text-color;
  
          &::placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }
  
        .GM__light-search-button {
          padding: 8px;
          color: $light-text-color;
  
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
    
    .GM__light-actions-container {
      flex: 0 0 auto;
      
      .GM__light-add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        background-color: $light-accent-color;
        color: white; // Cambiado para mejorar contraste
        border: none;
        border-radius: 8px;
        padding: $light-spacing-unit $light-spacing-unit * 2;
        cursor: pointer;
        font-weight: 500;
        transition: background-color 0.2s ease;
        white-space: nowrap;
        
        &:hover {
          background-color: darken($light-accent-color, 10%);
        }
        
        svg {
          font-size: 20px;
        }
      }
    }
  }
  
  .GM__light-operator-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: $light-spacing-unit * 3;
    margin-bottom: $light-spacing-unit * 4;
  }
  
  .GM__light-pagination-container {
    display: flex;
    justify-content: center;
    margin-top: $light-spacing-unit * 3;
  }
  
  .GM__light-error {
    text-align: center;
    padding: $light-spacing-unit * 4;
    font-size: 1.125rem;
    color: $light-error-color;
  }
}

// Media queries
@media screen and (max-width: 992px) {
  .GM__light-operator-container {
    .GM__light-operator-header {
      flex-direction: column;
      align-items: stretch;
      
      .GM__light-operator-filter-container {
        width: 100%;
        height: 100%;
      }
      
      .GM__light-search-container {
        .GM__light-search-wrapper {
          width: 100%;
          max-width: none;
        }
      }
      
      .GM__light-actions-container {
        align-self: flex-end;
        margin-top: $light-spacing-unit;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .GM__light-operator-container {
    .GM__light-operator-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    
    .GM__light-operator-header {
      .GM__light-actions-container {
        align-self: stretch;
        
        .GM__light-add-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-operator-container {
    padding: $light-spacing-unit;
    
    .GM__light-operator-grid {
      grid-template-columns: 1fr;
    }
  }
}