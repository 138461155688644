$primary-color: #ffffff;
$secondary-color: #c4c4c4;
$background-color: #1E1E1E;
$border-radius: 12px;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
$spacing-unit: 8px;
// Mixins útiles
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin card-container {
  background-color: $background-color;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $spacing-unit * 2;
}