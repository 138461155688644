.loading-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  z-index: 1000;

  &__sprite {
    position: relative;
    width: 300px;
    height: 300px;
    background-image: url('../../img/barril_animadov2.png');
    background-size: 900% 100%;
    animation: animateBarril 1.5s steps(9) infinite;

    @keyframes animateSprite {
      from {
        background-position: 0;
      }

      to {
        background-position: -900%;
      }
    }
  }
}

.loading-animation-download {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(3px);
  z-index: 1000;

  &__sprite {
    position: relative;
    width: 100px;
    height: 300px;
    background-image: url('../../img/animacion_carga_abeja_verde.png');
    background-size: 1500% 100%;
    transform: translateY(-90px); 
    animation: animateAbeja 2s steps(15, end) infinite;
  }
}

@keyframes animateBarril {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -900% 0;
  }
}

@keyframes animateAbeja {
  from {
    background-position: 0% 0;
  }

  to {
    background-position: -100% 0;
  }
}