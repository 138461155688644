// Variables para el tema oscuro
$background-dark: #121212;
$surface-dark: #1e1e1e;
$border-color-dark: #333333;
$text-primary-dark: #ffffff;
$text-secondary-dark: #aaaaaa;
$accent-color-dark: #4CAF50;
$input-background-dark: #2d2d2d;
$input-border-dark: #444444;
$input-focus-dark: #4CAF50;
$input-hover-dark: #363636;
$label-color-dark: #f0f0f0;
$divider-dark: #333333;
$shadow-dark: 0 2px 8px rgba(0, 0, 0, 0.3);

.GM__dark-contact-section {
  padding: 28px 0;
  background-color: $surface-dark;
  
  .GM__dark-section-title {
    font-size: 22px;
    font-weight: 600;
    color: $text-primary-dark;
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color-dark;
  }
  
  .GM__dark-subsection-title {
    font-size: 18px;
    font-weight: 600;
    color: $text-primary-dark;
    margin: 24px 0 16px 0;
  }
  
  .GM__dark-contact-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  
  .GM__dark-input-group {
    margin-bottom: 8px;
    
    .GM__dark-input-label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      color: $label-color-dark;
    }
    
    // Estilos para inputs personalizados
    input[type="text"],
    input[type="number"] {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid $input-border-dark;
      border-radius: 8px;
      font-size: 14px;
      color: $text-primary-dark;
      background-color: $input-background-dark;
      transition: border-color 0.2s ease, box-shadow 0.2s ease;
      
      &:hover {
        background-color: $input-hover-dark;
      }
      
      &:focus {
        outline: none;
        border-color: $input-focus-dark;
        box-shadow: 0 0 0 2px rgba($input-focus-dark, 0.2);
      }
      
      &::placeholder {
        color: $text-secondary-dark;
      }
    }
  }
  
  .GM__dark-phone-group {
    grid-column: span 2;
  }
  
  .GM__dark-phone-input-container {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    
    .GM__dark-sort-dropdown {
      width: 160px;
      flex-shrink: 0;
      
      .dropdown-toggle {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid $input-border-dark;
        border-radius: 8px;
        font-size: 14px;
        color: $text-primary-dark;
        background-color: $input-background-dark;
        transition: all 0.2s ease;
        
        &:hover {
          background-color: $input-hover-dark;
        }
      }
      
      .dropdown-menu {
        border: 1px solid $border-color-dark;
        border-radius: 8px;
        box-shadow: $shadow-dark;
        background-color: $surface-dark;
        
        .dropdown-item {
          color: $text-primary-dark;
          padding: 8px 12px;
          
          &:hover {
            background-color: $input-hover-dark;
          }
          
          &.active {
            background-color: rgba($accent-color-dark, 0.2);
            color: $accent-color-dark;
          }
        }
      }
    }
    
    input {
      flex-grow: 1;
    }
  }
  
  .GM__dark-location-section {
    margin-top: 32px;
    padding-top: 20px;
    border-top: 1px solid $divider-dark;
    
    .location-map-container {
      margin-top: 16px;
      border: 1px solid $border-color-dark;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: $shadow-dark;
      
      .map-search-input {
        border: 1px solid $input-border-dark;
        padding: 10px;
        font-size: 14px;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 12px;
        background-color: $input-background-dark;
        color: $text-primary-dark;
        
        &:focus {
          border-color: $accent-color-dark;
          outline: none;
        }
      }
    }
  }
  
  // Estilos para CustomDatePicker
  .GM__dark-datepicker-wrapper {
    position: relative;
    
    input {
      padding-right: 36px;
    }
    
    .datepicker-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      pointer-events: none;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: brightness(0.8) invert(1); // Invierte color para tema oscuro
      }
    }
  }
  
  // Estilos para react-datepicker
  .react-datepicker-wrapper {
    width: 100%;
    
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  
  .react-datepicker {
    font-family: inherit;
    border: 1px solid $border-color-dark;
    border-radius: 8px;
    box-shadow: $shadow-dark;
    background-color: $surface-dark;
    color: $text-primary-dark;
    
    .react-datepicker__header {
      background-color: $input-hover-dark;
      border-bottom: 1px solid $border-color-dark;
    }
    
    .react-datepicker__current-month,
    .react-datepicker__day-name {
      color: $text-primary-dark;
    }
    
    .react-datepicker__day {
      color: $text-primary-dark;
    }
    
    .react-datepicker__day--selected {
      background-color: $accent-color-dark;
      color: white;
    }
    
    .react-datepicker__day:hover {
      background-color: $input-hover-dark;
    }
    
    .react-datepicker__day--disabled {
      color: $text-secondary-dark;
    }
  }
}