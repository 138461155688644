.left-panel {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 350px;
  height: calc(100vh - 120px);
  background-color: rgba(255, 255, 255, 0.68);
  backdrop-filter: blur(5px); // Efecto de desenfoque para mejorar legibilidad
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;

  &__header {
    padding: 15px;
    border-bottom: 1px solid rgba(224, 224, 224, 0.7); // Borde semi-transparente

    h2 {
      margin: 0 0 10px 0;
      font-size: 1.4rem;
      color: #333;
    }
  }

  &__tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .react-tabs__tab-list {
      margin: 0;
      padding: 0 15px;
      border-bottom: 1px solid rgba(224, 224, 224, 0.7);
    }

    .react-tabs__tab {
      display: inline-block;
      border: none;
      border-bottom: 3px solid transparent;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 12px 12px;
      cursor: pointer;
      color: #666;
      font-weight: 500;
      transition: all 0.2s ease;

      &--selected {
        color: #2196f3;
        border-bottom: 3px solid #2196f3;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover:not(&--selected) {
        color: #0d8aee;
        border-bottom: 3px solid rgba(33, 150, 243, 0.3);
      }
    }

    .react-tabs__tab-panel {
      display: none;
      flex: 1;
      overflow-y: auto;
      padding: 0;
      
      // Estilizar la barra de desplazamiento
      &::-webkit-scrollbar {
        width: 6px;
      }
      
      &::-webkit-scrollbar-track {
        background: rgba(240, 240, 240, 0.5);
      }
      
      &::-webkit-scrollbar-thumb {
        background: rgba(180, 180, 180, 0.6);
        border-radius: 3px;
      }

      &--selected {
        display: block;
        max-height: calc(100vh - 130px); // Altura ajustada considerando header y tabs
      }
    }
  }

  .btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;

    &-primary {
      background-color: #2196f3;
      color: white;

      &:hover {
        background-color: #0d8aee;
      }
    }

    &-success {
      background-color: #4caf50;
      color: white;

      &:hover {
        background-color: #43a047;
      }
    }

    &-danger {
      background-color: #f44336;
      color: white;

      &:hover {
        background-color: #e53935;
      }
    }

    &:disabled {
      background-color: rgba(204, 204, 204, 0.8);
      cursor: not-allowed;
    }
  }

  .form-control {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.8);

    &:focus {
      border-color: #2196f3;
      outline: none;
      background-color: rgba(255, 255, 255, 0.95);
    }
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: block;
      margin-bottom: 5px;
      font-weight: 500;
      color: #555;
    }
  }

  .no-data-message {
    padding: 20px;
    text-align: center;
    color: #666;
  }
}

// Media query para ajustar en pantallas pequeñas
@media (max-height: 700px) {
  .left-panel {
    height: calc(100vh - 30px);
    top: 15px;
    
    .react-tabs__tab-panel--selected {
      max-height: calc(100vh - 115px);
    }
  }
}