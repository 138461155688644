@import './themes/RegisterSectionDark.scss';
@import './themes/RegisterSectionLight.scss';

// Mixins compartidos para ambos temas
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin card-shadow($opacity: 0.08) {
  box-shadow: 0 4px 10px rgba(0, 0, 0, $opacity);
}

@mixin input-focus($color) {
  &:focus {
    outline: none;
    border-color: $color;
    box-shadow: 0 0 0 2px rgba($color, 0.2);
  }
}

// Animaciones compartidas
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(10px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

// Media queries compartidas
@media (max-width: 1024px) {
  [class*="GM__"]:where([class*="-register-section-wrapper"]) {
    [class*="GM__"]:where([class*="-main-section"]) {
      grid-template-columns: 1fr !important;

      [class*="GM__"]:where([class*="-right-column"]) {
        order: -1;

        [class*="GM__"]:where([class*="-photo-section"]) {
          margin-bottom: 2rem;
        }
      }
    }

    [class*="GM__"]:where([class*="-contact-section"]) {
      [class*="GM__"]:where([class*="-contact-row"]) {
        grid-template-columns: repeat(2, 1fr) !important;
        
        [class*="GM__"]:where([class*="-email-group"]) {
          grid-column: 1 / -1;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  [class*="GM__"]:where([class*="-register-section-wrapper"]) {
    [class*="GM__"]:where([class*="-contact-section"]) {
      [class*="GM__"]:where([class*="-contact-row"]) {
        grid-template-columns: 1fr !important;
      }
    }

    [class*="GM__"]:where([class*="-documents-section"]) {
      [class*="GM__"]:where([class*="-document-table"]) {
        display: block;
        overflow-x: auto;
      }
    }
  }
}