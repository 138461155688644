// AcercaDeNosotros.scss - Optimizado con correcciones para navegación móvil

.acerca-section {
  position: relative;
  width: 100%;
  height: calc(100vh - 82px);
  background-color: #fff;
  padding: 20px 0;
  overflow: hidden;
  
  .acerca-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }
  
  // Navegación de tabs - Modificada para mantener alineación horizontal
  .acerca-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 40px; // Valor base para desktop
    border-bottom: 1px solid #eee;
    
    .acerca-tab {
      position: relative;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 10px 25px 15px;
      margin: 0 20px;
      cursor: pointer;
      transition: color 0.3s ease;
      
      &:hover {
        color: #0dd143;
      }
      
      .acerca-highlight {
        color: #0dd143;
      }
      
      &.active {
        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: #0dd143;
          animation: fadeIn 0.3s ease;
        }
      }
    }
  }
  
  // Contenedor para los contenidos de cada tab
  .acerca-content {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease;
    height: calc(100vh - 150px);
    min-height: 500px;
    position: relative;
    overflow: hidden;
    
    &.active {
      display: block;
      opacity: 1;
      
      // Contenedor específico para la imagen SVG
      .svg-container {
        width: 100%;
        height: calc(100vh - 180px);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        
        // Estilos específicos para la imagen SVG
        img {
          max-width: 150%;
          max-height: 150%;
          object-fit: contain;
          position: relative;
          transition: transform 0.3s ease;
          animation: scaleIn 0.5s ease forwards;
          right: -270px;
        }
      }
    }
  }
  
  // Historia específica
  .acerca-historia-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    .historia-background {
      position: absolute;
      top: 0;
      right: 0;
      width: 40%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      .extractor-svg {
        max-width: 100%;
        height: auto;
        opacity: 0.2;
      }
    }
    
    .historia-content {
      position: relative;
      z-index: 2;
      width: 100%;
      
      .historia-header {
        text-align: center;
        margin-bottom: 30px;
        
        h2 {
          font-size: 2.5rem;
          font-weight: 600;
          
          .acerca-highlight {
            color: #0dd143;
          }
        }
      }
      
      .historia-body {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        
        .historia-image {
          flex: 1;
          min-width: 300px;
          position: relative;
          
          img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
          }
          
          .image-size {
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #007bff;
            color: white;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 0.85rem;
          }
        }
        
        .historia-text {
          flex: 1;
          min-width: 300px;
          
          p {
            font-size: 1.2rem;
            line-height: 1.8;
            text-align: justify;
            
            .acerca-highlight {
              color: #0dd143;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  
  // Animaciones
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes scaleIn {
    from { transform: scale(0.98); opacity: 0.8; }
    to { transform: scale(1); opacity: 1; }
  }
  
  // Media queries para responsividad
  @media screen and (min-width: 1601px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 160%;
        right: -300px;
      }
    }
  }
  
  @media screen and (max-width: 1600px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 150%;
        right: -270px;
      }
    }
  }
  
  @media screen and (max-width: 1440px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 140%;
        right: -230px;
      }
    }
  }
  
  @media screen and (max-width: 1300px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 130%;
        right: -190px;
      }
    }
  }
  
  @media screen and (max-width: 1200px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 120%;
        right: -150px;
      }
    }
  }
  
  @media screen and (max-width: 1100px) {
    .acerca-content.active {
      .svg-container img {
        max-width: 110%;
        right: -100px;
      }
    }
  }
  
  // Tablets grandes y dispositivos medianos
  @media screen and (max-width: 992px) {
    .acerca-section {
      height: auto;
      min-height: 100vh;
    }
    
    .acerca-tabs {
      margin-bottom: 30px; // Reducir espacio en tablets
    }
    
    .acerca-content {
      height: auto;
      min-height: 450px;
      
      &.active .svg-container {
        height: 450px;
        
        img {
          max-width: 135%;
          max-height: 450px;
          right: -150px;
        }
      }
    }
    
    .acerca-historia-container {
      .historia-background {
        opacity: 0.6;
      }
      
      .historia-content {
        .historia-header h2 {
          font-size: 2rem;
        }
        
        .historia-body {
          flex-direction: column;
          
          .historia-text p {
            font-size: 1.1rem;
          }
        }
      }
    }
  }
  
  // Tablets pequeñas
  @media screen and (max-width: 850px) {
    .acerca-content.active .svg-container {
      img {
        max-width: 150%;
        right: -170px;
      }
    }
  }
  
  // Tablets y dispositivos más pequeños
  @media screen and (max-width: 768px) {
    .acerca-tabs {
      // Mantener horizontal, pero reducir espaciado
      margin-bottom: 20px;
      
      .acerca-tab {
        font-size: 1.3rem;
        padding: 6px 12px 10px;
        margin: 0 8px;
      }
    }
    
    .acerca-content {
      min-height: 400px;
      
      &.active .svg-container {
        height: 400px;
        padding: 0;
        
        img {
          // Configuración para vista móvil - imagen centrada
          max-width: 175%;
          max-height: 400px;
          right: -200px;
        }
      }
    }
    
    .acerca-historia-container {
      .historia-background {
        position: relative;
        width: 100%;
        height: 0px;
        opacity: 0.15;
        margin-bottom: 20px;
      }
      
      .historia-content {
        .historia-header h2 {
          font-size: 1.3rem;
        }
        
        .historia-body {
          gap: 20px;
          
          .historia-image, .historia-text {
            flex: 100%;
          }
          
          .historia-text p {
            font-size: 1rem;
          }
        }
      }
    }
  }
  
  // Dispositivos móviles medianos
  @media screen and (max-width: 650px) {
    .acerca-tabs {
      margin-bottom: 15px; // Reducir aún más el espacio
      
      .acerca-tab {
        font-size: 1.2rem;
        padding: 5px 10px 8px;
        margin: 0 6px;
      }
    }
    
    .acerca-content.active .svg-container {
      img {
        max-width: 200%;
        right: -225px;
      }
    }
  }
  
  // Dispositivos móviles pequeños
  @media screen and (max-width: 576px) {
    .acerca-tabs {
      // Mantener horizontal y compactar más
      flex-direction: row;
      margin-bottom: 10px;
      
      .acerca-tab {
        font-size: 1.1rem;
        padding: 4px 8px 6px;
        margin: 0 5px;
      }
    }
    
    .acerca-content {
      min-height: 350px;
      
      &.active .svg-container {
        height: 350px;
        
        img {
          max-width: 280%;
          max-height: 410px;
          right: -70px;
        }
      }
    }
  }
  
  // Móviles muy pequeños
  @media screen and (max-width: 450px) {
    .acerca-tabs {
      .acerca-tab {
        font-size: 1rem;
        padding: 3px 6px 5px;
        margin: 0 4px;
      }
    }
    
    .acerca-content.active .svg-container {
      img {
        max-width: 240%;
        right: -30px;
      }
    }
  }
  
  // Móviles extremadamente pequeños
  @media screen and (max-width: 375px) {
    .acerca-tabs {
      .acerca-tab {
        font-size: 0.9rem;
        padding: 3px 5px 4px;
        margin: 0 3px;
      }
    }
    
    .acerca-content.active .svg-container {
      img {
        max-width: 260%;
        right: -30px;
      }
    }
  }
}