// Variables para tema oscuro
$dark-background: #121212;
$dark-panel-background: #1e1e1e;
$dark-text-color: #ffffff;
$dark-secondary-color: #aaaaaa;
$dark-border-color: #333333;
$dark-accent-color: #4e96ff;
$dark-hover-background: rgba(78, 150, 255, 0.15);
$dark-active-background: rgba(78, 150, 255, 0.25);
$dark-error-color: #ff6666;
$dark-spacing-unit: 8px;

.GM__dark-operator-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background: transparent;
  color: $dark-text-color;
  
  .GM__dark-operator-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $dark-spacing-unit * 3;
    gap: $dark-spacing-unit * 2;
    flex-wrap: wrap;
    
    .GM__dark-operator-filter-container {
      flex: 0 0 auto;
      min-width: 150px; 
      
      > button, > div {
        width: 100%;
        height: 40px;
      }
    }
    
    .GM__dark-search-container {
      flex: 1;
      min-width: 200px;
      display: flex;
      justify-content: flex-end; // Alinea el contenido a la derecha
      
      .GM__dark-search-wrapper {
        border: 1px solid $dark-border-color;
        border-radius: 20px;
        overflow: hidden;
        width: 100%; // Ocupa todo el ancho disponible
        max-width: 400px; // Limita el ancho máximo
        height: 40px;
        display: flex;
        align-items: center;
        margin-left: auto; // Empuja el elemento hacia la derecha
        background-color: rgba(255, 255, 255, 0.05);
  
        .GM__dark-search-input {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 0 15px;
          background: transparent;
          font-size: 0.9rem;
          height: 100%;
          color: $dark-text-color;
  
          &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
  
        .GM__dark-search-button {
          padding: 8px;
          color: $dark-secondary-color;
  
          &:hover {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
      }
    }
    
    .GM__dark-actions-container {
      flex: 0 0 auto;
      
      .GM__dark-add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $dark-spacing-unit;
        background-color: $dark-accent-color;
        color: $dark-text-color;
        border: none;
        border-radius: 8px;
        padding: $dark-spacing-unit $dark-spacing-unit * 2;
        cursor: pointer;
        font-weight: 500;
        transition: background-color 0.2s ease;
        white-space: nowrap;
        
        &:hover {
          background-color: lighten($dark-accent-color, 10%);
        }
        
        svg {
          font-size: 20px;
        }
      }
    }
  }
  
  .GM__dark-operator-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: $dark-spacing-unit * 3;
    margin-bottom: $dark-spacing-unit * 4;
  }
  
  .GM__dark-pagination-container {
    display: flex;
    justify-content: center;
    margin-top: $dark-spacing-unit * 3;
  }
  
  .GM__dark-error {
    text-align: center;
    padding: $dark-spacing-unit * 4;
    font-size: 1.125rem;
    color: $dark-error-color;
  }
}

// Media queries
@media screen and (max-width: 992px) {
  .GM__dark-operator-container {
    .GM__dark-operator-header {
      flex-direction: column;
      align-items: stretch;
      
      .GM__dark-operator-filter-container {
        width: 100%;
      }
      
      .GM__dark-search-container {
        .GM__dark-search-wrapper {
          width: 100%;
          max-width: none;
        }
      }
      
      .GM__dark-actions-container {
        align-self: flex-end;
        margin-top: $dark-spacing-unit;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .GM__dark-operator-container {
    .GM__dark-operator-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    
    .GM__dark-operator-header {
      .GM__dark-actions-container {
        align-self: stretch;
        
        .GM__dark-add-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__dark-operator-container {
    padding: $dark-spacing-unit;
    
    .GM__dark-operator-grid {
      grid-template-columns: 1fr;
    }
  }
}