// Variables tema oscuro
$background-dark: #111;
$background-darker: #000;
$border-color-dark: #333;
$border-color-darker: #444;
$text-color-dark: #fff;
$text-color-muted-dark: #777;
$accent-color-dark: #4CAF50;
$accent-hover-dark: #3f3;
$danger-color-dark: #f00;
$danger-hover-dark: #d00;

.GM__dark-dashboard-vehicle {
  width: 100%;
  height: 87vh;
  background: $background-darker;
  color: $text-color-dark;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  header {
    background: #1E1E1E;
    border-color: #333;
    
    .header-right {
      .settings-icon-wrapper {
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        
        .settings-icon {
          filter: invert(1);
        }
      }
    }
  }
  main {
    flex: 1;
    overflow-y: auto;
    background-color: $background-dark;
    padding: 20px;
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $background-darker;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $border-color-dark;
      border-radius: 4px;
      
      &:hover {
        background: $border-color-darker;
      }
    }
    
    // Estilo para tarjetas originales (mantiene compatibilidad)
    .vehicle-card {
      border: 1px solid $border-color-dark;
      padding: 16px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      background-color: $background-darker;
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
      }

      h3 {
        font-size: 18px;
        margin: 0;
        color: $text-color-dark;
        font-weight: 600;
      }

      p {
        margin: 8px 0;
        color: $text-color-muted-dark;
        font-size: 14px;
      }
    }
    
    // Nuevos estilos para el contenedor de resultados
    .vehicles-grid {
      width: 100%;
    }
    
    .loading-indicator {
      background-color: rgba(255, 255, 255, 0.05);
      color: $text-color-dark;
    }
    
    .no-results {
      background-color: rgba(255, 255, 255, 0.03);
      color: $text-color-muted-dark;
    }
    
    // Estilos específicos para tarjetas combinadas en tema oscuro
    .combined-vehicle-container {
      .vehicle-card {
        margin: 0; // Resetear margen para componentes combinados
        
        &.trailer {
          .image-border.trailer-border {
            border-color: #FFAA00; 
          }
          
          .line.trailer-line {
            background: #FFAA00;
          }
        }
      }
      
      .vehicle-connector {
        .connector-icon {
          background-color: $accent-color-dark;
        }
        
        .connector-line {
          background-color: $accent-color-dark;
        }
      }
    }
  }

  footer {
    margin-top: auto;
    padding: 20px;
    text-align: center;
    border-top: 1px solid $border-color-dark;
    background: $background-darker;
  }
}