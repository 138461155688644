.aliados-section {
  background-color: #f7fcfa;
  overflow: hidden;
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .aliados-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-weight: 700;
    color: #333;
    
    &-highlight {
      color: #00a651; // Color verde para "Aliados"
    }
    
    // Reducción del título para móviles
    @media (max-width: 768px) {
      font-size: 2rem;
    }
    
    @media (max-width: 576px) {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-width: 900px; // Limitar el ancho para mostrar 4 logos
    margin: 0 auto;
    
    &:last-of-type {
      margin-bottom: 3rem;
    }
  }
  

  .carousel {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .logo-card {
    flex: 0 0 auto;
    min-width: 200px; // Ajusta según necesites
    width: 22%; // Aproximadamente 4 por fila (con algo de margen)
    height: 100px;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0.5rem;
    
    @media (max-width: 576px) {
      min-width: 160px;
      height: 80px;
    }
    
    img {
      max-width: 100%;
      max-height: 80%;
      object-fit: contain;
    }
  }
  
  // Estilos para los aliados terciarios - Vista Desktop
  .aliados-terciarios {
    width: 100%;
    padding: 1rem 0;
    
    &.desktop-view {
      display: block;
      
      @media (max-width: 768px) {
        display: none; // Ocultar en móviles
      }
    }
    
    &.mobile-view {
      display: none; // Ocultar en desktop
      
      @media (max-width: 768px) {
        display: block; // Mostrar en móviles
      }
    }
    
    &-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    
    // Carousel para móviles
    &-carousel {
      overflow: hidden;
      position: relative;
      width: 100%;
      
      .carousel-slide {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
      }
    }
    
    .carousel-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin-bottom: 1rem;
      
      .nav-btn {
        background-color: #00a651;
        color: white;
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s;
        
        &:hover {
          background-color: darken(#00a651, 10%);
        }
        
        &:active {
          transform: scale(0.95);
        }
      }
    }
    
    .carousel-indicators {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1rem;
      
      .indicator {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ddd;
        cursor: pointer;
        transition: background-color 0.3s;
        
        &.active {
          background-color: #00a651;
          transform: scale(1.2);
        }
      }
    }
    
    .aliado-terciario-item {
      background-color: white;
      padding: 1rem;
      border-radius: 0.25rem;
      text-align: center;
      font-size: 0.9rem;
      color: #555;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s, box-shadow 0.3s;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
      }
      
      // Vista desktop
      .desktop-view & {
        &:nth-child(odd) {
          text-align: left;
          padding-left: 6rem;
        }
        
        &:nth-child(even) {
          text-align: right;
          padding-right: 6rem;
        }
      }
      
      // Vista móvil - ambos elementos centrados
      .mobile-view & {
        text-align: center;
        padding: 1.5rem 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}