// Variables de diseño
$primary-color: #3f51b5;
$secondary-color: #f50057;
$background-color: #f5f5f500;
$background-content-color: #1E1E1E;
$card-background: #ffffff;
$text-color: #ffffff;


.dashboard-container  {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 20px;
  padding: 20px;
  height: 85vh;
  background-color: $background-color;
  overflow: auto;
  

  // Sección de Título y Fecha
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboard-title {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color;
    }

    .chip-pointer {
      cursor: pointer;
    }
  }

  // Cards
  .cards-data {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); // Responsivo para las tarjetas
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .charts {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Responsivo para los gráficos
    margin-top: 2rem;
    .MuiPaper-root {
      background: $background-content-color ;
    }

    .chart {
      background-color: #f5f5f5;
      border-radius: 8px;
    }
  }

  // Sección de Gráficas
  .dashboard-charts-section {
    display: flex;
    gap: 20px;

    .charts-column-primary {
      flex: 8;
      background-color: rgba($primary-color, 0.1);
      min-height: 300px;
    }

    .charts-column-secondary {
      flex: 4;
      background-color: rgba($secondary-color, 0.1);
      min-height: 300px;
    }
  }

  // Sección de Botones de Descarga
  .dashboard-download-section {
    padding: 30px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .download-button {
      display: flex;
      align-items: center;
      background-color: $card-background;
      border-radius: 8px;
      padding: 10px;
      gap: 10px;

      .month-selector {
        min-width: 100px;
      }
    }

    .download-period-btn,
    .download-year-btn {
      text-transform: none;
    }
  }
  .events {
    padding: 0 0;
    width: 100%;
    height: auto;
    .carousel {
      justify-content: center;
      margin: 0 auto;
      width: 100%;
      max-width: 76vw;
      height: auto;
    }
  }
  // Sección de Puntos Críticos
  .dashboard-critical-points {
    width: 100%;
    background-color: rgba($text-color, 0.1);
    min-height: 200px;
  }
}
