@import './subdivisions/DashboardVehicleLight.scss';
@import './subdivisions/DashboardDark.scss';

// Estilos compartidos para las tarjetas
.vehicles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  width: 100%;
}

.loading-indicator, .no-results {
  width: 100%;
  padding: 40px;
  text-align: center;
  border-radius: 8px;
  font-size: 16px;
}

// Estilos para el conector de vehículos combinados
.combined-vehicle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  
  .vehicle-connector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    position: relative;
    
    .connector-icon {
      width: 36px;
      height: 36px;
      background-color: #00a651;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
    
    .connector-line {
      position: absolute;
      width: 4px;
      background-color: #00a651;
      height: 30px;
      
      &.top {
        top: 45px;
      }
      
      &.bottom {
        bottom: 45px;
      }
    }
  }
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}
.GM__light-dashboard-vehicle,
.GM__dark-dashboard-vehicle {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    margin-bottom: 0;
    border-bottom: 1px solid;
    
    .header-left {
      flex: 0 1 500px; // Limita el ancho pero permite reducción
      
      .search-component {
        max-width: 100%;
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      gap: 16px;
      
      .GM__light-sort-dropdown,
      .GM__dark-sort-dropdown {
        width: auto;
      }

      .settings-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
        transition: background-color 0.2s ease;
        
        .settings-icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

// Media queries compartidas
@media screen and (max-width: 1200px) {
  .GM__light-dashboard-vehicle,
  .GM__dark-dashboard-vehicle {
    main {
      .vehicles-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        gap: 24px;
        width: 100%;
        
        // Ajuste específico para contenedores combinados
        .combined-vehicle-container {
          grid-column: span 2; // Ocupará 2 columnas por defecto
          
          @media screen and (max-width: 1200px) {
            grid-column: span 2; // En pantallas medianas, sigue ocupando 2 columnas
          }
          
          @media screen and (max-width: 900px) {
            grid-column: span 1; // En pantallas pequeñas, ocupa 1 columna y se apila verticalmente
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .GM__light-dashboard-vehicle,
  .GM__dark-dashboard-vehicle {
    main {
      .vehicles-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  
  .combined-vehicle-container {
    flex-direction: column;
    
    .vehicle-connector {
      height: 40px;
      margin: 0;
      
      .connector-line {
        height: 15px;
        
        &.top {
          top: 0;
        }
        
        &.bottom {
          bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .GM__light-dashboard-vehicle,
  .GM__dark-dashboard-vehicle {
    header {
      flex-direction: column;
      gap: 16px;

      .header-left {
        width: 100%;
      }

      .header-right {
        width: 100%;
        justify-content: space-between;
      }
    }

    main {
      .vehicles-grid {
        grid-template-columns: 1fr;
      }
    }
  }
}