// VehicleProfileInfoLight.scss
$light-primary-color: #FFFFFF;
$light-secondary-color: #F5F7FA;
$light-accent-color: #0078D4;
$light-text-primary: #333333;
$light-text-secondary: #666666;
$light-error-color: #D83B01;
$light-success-color: #107C10;
$light-border-radius: 8px;
$light-transition-speed: 0.3s;

@mixin light-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin light-card-shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.GM__light-vehicle-profile-info {
  margin-bottom: 20px;
  position: relative;
  
  .profile-header {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 2rem;
    min-height: 500px;
    margin-bottom: 20px;
    
    .profile-image-container {
      .rotated-rectangle {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $light-secondary-color;
        border-radius: $light-border-radius;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        
        .profile-image-wrapper {
          display: flex;
          flex-direction: column; 
          width: 100%;
          height: 100%;
          
          .profile-main-image {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            z-index: 2;
            
            .profile-image {
              width: 100%;
              height: 100%;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
            }
          }
          
          .profile-backdrop {
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            z-index: 1;
            
            .profile-backdrop-image {
              width: 100%;
              height: 100%;
              object-position: center;
              opacity: 0.3;
              
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: blur(8px);
              }
            }
          }
        }
      }
    }

    .profile-info {
      background-color: $light-primary-color;
      border-radius: $light-border-radius;
      padding: 2rem;
      @include light-card-shadow;
      border: 1px solid rgba(0, 0, 0, 0.05);

      .info-display {
        h2 {
          font-size: 2rem;
          color: $light-text-primary;
          margin-bottom: 2rem;
          font-weight: 600;
          border-bottom: 2px solid rgba($light-accent-color, 0.3);
          padding-bottom: 1rem;
        }

        .info-sections {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;

          .info-column {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .info-section {
            background-color: $light-secondary-color;
            border-radius: $light-border-radius;
            padding: 1rem;
            border: 1px solid rgba(0, 0, 0, 0.05);

            .section-title {
              color: $light-accent-color;
              font-size: 1.2rem;
              margin-bottom: 1rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              
              &::after {
                content: '';
                flex: 1;
                height: 1px;
                background: rgba($light-accent-color, 0.3);
                margin-left: 1rem;
              }
            }

            .section-content {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .info-item {
                display: flex;
                flex-direction: column;
                gap: 0.3rem;

                .label {
                  color: $light-text-secondary;
                  font-size: 0.9rem;
                }

                .value {
                  color: $light-text-primary;
                  font-size: 1rem;

                  &.status {
                    display: inline-flex;
                    align-items: center;
                    padding: 0.25rem 0.75rem;
                    border-radius: 12px;
                    font-size: 0.9rem;
                    
                    &:not(:empty) {
                      &::before {
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                      }
                    }
                  }
                }
              }
              
              .footer-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                
                .view-more-btn {
                  background-color: rgba($light-accent-color, 0.1);
                  color: $light-accent-color;
                  padding: 0.5rem 1rem;
                  border-radius: $light-border-radius;
                  border: none;
                  cursor: pointer;
                  transition: all $light-transition-speed ease;
                  
                  &:hover {
                    background-color: rgba($light-accent-color, 0.2);
                  }
                }
              }
              
              .empty-state {
                text-align: center;
                padding: 1rem;
                
                .add-btn {
                  background-color: rgba($light-accent-color, 0.1);
                  color: $light-accent-color;
                  padding: 0.5rem 1rem;
                  border-radius: $light-border-radius;
                  border: none;
                  margin-top: 0.5rem;
                  cursor: pointer;
                  transition: all $light-transition-speed ease;
                  
                  &:hover {
                    background-color: rgba($light-accent-color, 0.2);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .edit-toggle-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 40px;
    height: 40px;
    background-color: rgba($light-accent-color, 0.1);
    border: 1px solid rgba($light-accent-color, 0.2);
    border-radius: 50%;
    cursor: pointer;
    @include light-flex-center;
    transition: all $light-transition-speed ease;

    svg {
      width: 20px;
      height: 20px;
      fill: $light-accent-color;
      transition: fill $light-transition-speed ease;
    }

    &:hover {
      background-color: $light-accent-color;
      transform: scale(1.05);

      svg {
        fill: white;
      }
    }
  }
  
  // Estados específicos en tema claro
  .info-item .value.status {
    &:contains("Activo"), 
    &:contains("Operativo"),
    &:contains("Aceptado") {
      background-color: rgba($light-success-color, 0.1);
      color: $light-success-color;
      
      &::before {
        background-color: $light-success-color;
      }
    }

    &:contains("Inactivo"), 
    &:contains("Fuera de servicio"),
    &:contains("Pendiente") {
      background-color: rgba($light-error-color, 0.1);
      color: $light-error-color;
      
      &::before {
        background-color: $light-error-color;
      }
    }
  }
}

// Media queries específicas
@media (max-width: 1024px) {
  .GM__light-vehicle-profile-info {
    .profile-header {
      grid-template-columns: 1fr;

      .profile-image-container {
        max-height: 400px;
        .profile-image-wrapper {
          .profile-main-image {
            width: 100%;
          }
          
          .profile-backdrop {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .GM__light-vehicle-profile-info {
    .profile-header {
      .profile-info {
        .info-sections {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}