// _UserProfileEditDark.scss
$dark-primary-color: #2A2D34;
$dark-secondary-color: #1a1c21;
$dark-accent-color: #00A561;
$dark-text-primary: #E8E9EA;
$dark-text-secondary: #B8B9BA;
$dark-error-color: #dc3545;
$dark-success-color: #28a745;
$dark-border-radius: 8px;
$dark-transition-speed: 0.3s;

@mixin dark-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin dark-card-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

.GM__dark-profile-user-edit {
  margin-bottom: 10px;
  position: relative;

  .profile-header {
    min-height: 500px;
    margin-bottom: 20px;

    .profile-info {
      background-color: $dark-primary-color;
      border-radius: $dark-border-radius;
      padding: 2rem;
      @include dark-card-shadow;

      form {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .main-section {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 2rem;
          
          .left-column, .right-column {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
          }

          .photo-section {
            background-color: rgba($dark-secondary-color, 0.4);
            border-radius: $dark-border-radius;
            padding: 1.5rem;
            margin-bottom: 1.5rem;
          }

          .additional-info {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
          }
        }

        .contact-section {
          background-color: rgba($dark-secondary-color, 0.4);
          border-radius: $dark-border-radius;
          padding: 1.5rem;
          
          h2 {
            color: $dark-accent-color;
            font-size: 1.2rem;
            margin-bottom: 1.5rem;
            font-weight: 500;
          }

          .contact-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;
            margin-bottom: 1.5rem;

            .phone-group {
              .phone-input-container {
                display: grid;
                grid-template-columns: 150px 1fr;
                gap: 1rem;
              }
            }
          }

          .location-section {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    label {
      color: $dark-text-secondary;
      font-size: 0.9rem;
    }

    input, select {
      background-color: rgba($dark-secondary-color, 0.2);
      border: 1px solid rgba($dark-text-secondary, 0.1);
      border-radius: $dark-border-radius;
      padding: 0.75rem;
      color: $dark-text-primary;
      font-size: 1rem;
      transition: all $dark-transition-speed ease;

      &:focus {
        border-color: $dark-accent-color;
        outline: none;
      }
    }
  }

  .form-actions {
    display: flex;
    gap: 1rem;
    margin-top: auto;
    padding-top: 2rem;
    border-top: 1px solid rgba($dark-text-secondary, 0.1);

    button {
      padding: 0.75rem 1.5rem;
      border-radius: $dark-border-radius;
      font-size: 1rem;
      font-weight: 500;
      transition: all $dark-transition-speed ease;
      cursor: pointer;
      border: none;

      &.save-button {
        background-color: $dark-accent-color;
        color: $dark-text-primary;

        &:hover {
          background-color: darken($dark-accent-color, 10%);
        }
        
        &:disabled {
          background-color: rgba($dark-accent-color, 0.5);
          cursor: not-allowed;
        }
      }

      &.cancel-button {
        background-color: rgba($dark-error-color, 0.1);
        color: $dark-error-color;

        &:hover {
          background-color: rgba($dark-error-color, 0.2);
        }
      }
    }
  }

  .edit-toggle-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 40px;
    height: 40px;
    background-color: rgba($dark-error-color, 0.1);
    border: 1px solid rgba($dark-error-color, 0.2);
    border-radius: 50%;
    cursor: pointer;
    @include dark-flex-center;
    transition: all $dark-transition-speed ease;

    svg {
      width: 20px;
      height: 20px;
      fill: $dark-error-color;
      transition: fill $dark-transition-speed ease;
    }

    &:hover {
      background-color: $dark-error-color;
      transform: scale(1.05);

      svg {
        fill: $dark-text-primary;
      }
    }
  }
}

// Media queries para responsividad
@media (max-width: 1024px) {
  .GM__dark-profile-user-edit {
    .profile-header {
      .profile-info {
        form {
          .main-section {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .GM__dark-profile-user-edit {
    .profile-header {
      .profile-info {
        form {
          .contact-section {
            .contact-row {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }
  }
}