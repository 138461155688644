// Variables tema oscuro
$background-dark: #111;
$background-medium: #222;
$border-color: #333;
$border-color-light: #444;
$text-color: #fff;
$text-color-muted: #777;
$accent-color: #0f0;
$accent-hover: #3f3;
$danger-color: #f00;
$danger-hover: #d00;

.GM__dark-vehicles-container {
  width: 100%;
  height: 87vh;
  background: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  .GM__dark-vehicles-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #000000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    will-change: transform;
    
    &.header-hidden {
      transform: translateY(-100%);
      opacity: 0;
    }
    
    .GM__dark-vehicles-title {
      font-size: 2.5rem;
      padding-left: 20px;
      color: white;
    }
    
    .GM__dark-vehicles-navigation {
      display: flex;
      align-items: center;
      padding-left: 20px;
      margin-bottom: 1.5rem;
      
      .GM__dark-navigation-item-clickable {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        color: white;
        background-color: rgba(255, 255, 255, 0.1);
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      
      .GM__dark-navigation-item-current {
        font-size: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        color: #4CAF50;
        background-color: rgba(76, 175, 80, 0.1);
      }
      
      .GM__dark-navigation-separator {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
      }
    }
    
    .GM__dark-header-divider {
      width: 70%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      margin: 0;
    }
  }
  
  .GM__dark-vehicles-content {
    flex: 1;
    overflow-y: auto;
    position: relative;
    transition: all 0.3s ease-in-out;
    height: 87vh;
    margin-top: 0;
    display: flex;
    flex-direction: column; 
    background-color: $background-dark;
    
    &.header-hidden {
      height: 100%;
      margin-top: calc(var(--header-height, 200px) * -1);
    }
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $background-dark;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $border-color;
      border-radius: 4px;
      
      &:hover {
        background: $border-color-light;
      }
    }
  }
}