// SearchMini.scss actualizado
.search-component {
  max-width: 500px;
  width: 100%;
  
  input {
    width: 100%;
    padding: 12px 16px;
    font-size: 14px;
    border: 1px solid #e0e6ed;
    border-radius: 8px;
    background-color: #f5f7fa;
    color: #333;
    transition: all 0.3s ease;
    
    &:focus {
      outline: none;
      border-color: #4CAF50;
      box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
    }
    
    &::placeholder {
      color: #777;
    }
  }
  
  // Necesitamos estas clases específicas para detectar el tema oscuro
  .GM__dark-dashboard-vehicle & {
    input {
      background-color: #2b2b2b;
      border-color: #444;
      color: #fff;
      
      &::placeholder {
        color: #aaa;
      }
      
      &:focus {
        border-color: #9747FF;
        box-shadow: 0 0 0 2px rgba(151, 71, 255, 0.3);
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}