.preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 9999;
  
    &.open {
      visibility: visible;
      opacity: 1;
    }
  
    .modal-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  
    .modal-content-preview {
      position: absolute;
      width: 90vw;
      height: 100%;
      background-color: #2a2d34;
      border-radius: 0;
      overflow: hidden;
      box-shadow: none;
      animation: modal-slide-down 0.3s ease;
  
      .modal-body {
        color: #fff;
        height: calc(100% - 50px);
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
  
        .media-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #1f2024;
        }
      }
  
      .modal-footer {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        background-color: #23262d;
  
        .close-button {
          background-color: #444;
          color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;
  
          &:hover {
            background-color: #666;
          }
        }
      }
    }
  }
  
  @keyframes modal-slide-down {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  