// VehiclesCardTheme.scss

// Variables para tema claro
$background-light: #f5f7fa;
$card-bg-light: #f0f2f5;
$primary-color-light: #4CAF50;
$secondary-color-light: #FFAA00;
$text-color-light: #333;
$border-color-light: #e0e6ed;

// Variables para tema oscuro
$background-dark: #1E1E1E;
$card-bg-dark: #121212;
$primary-color-dark: #4CAF50; // Mantener el verde
$secondary-color-dark: #FFAA00; // Mantener el naranja
$text-color-dark: #ffffff;
$border-color-dark: #444;

// Estilos para tema claro
.GM__light-vehicle-card {
  .vehicle-card {
    background: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    
    .card-header {
      .column {
        &.first {
          background: #ffffff;
          color: $text-color-light;
          
          .first-margen {
            background: $background-light;
          }
        }
        
        &.second {
          background: #ffffff;
          
          .second-content {
            background: $background-light;
          }
          
          .second-margenv2, 
          .second-margenv3 {
            background: $background-light;
            
            &::before {
              background: #ffffff;
            }
          }
        }
      }
    }
    
    .card-content {
      background: $background-light;
      color: $text-color-light;
      
      .image-wrapper {
        .image-border {
          background: #ffffff;
          border-color: $primary-color-light;
        }
      }
      
      .info-wrapper {
        .info {
          .line {
            background: $primary-color-light;
          }
          
          .text {
            color: $text-color-light;
          }
        }
      }
    }
    
    &.trailer {
      .image-border.trailer-border {
        border-color: $secondary-color-light;
      }
      
      .line.trailer-line {
        background: $secondary-color-light;
      }
    }
  }
  
  .vehicle-connector {
    .connector-icon {
      background-color: $primary-color-light;
    }
    
    .connector-line {
      background-color: $primary-color-light;
    }
  }
}

// Estilos para tema oscuro - Mantenemos la configuración original
.GM__dark-vehicle-card {
  .vehicle-card {
    background: #9747FF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    
    .card-header {
      .column {
        &.first {
          background: #9747FF;
          
          .first-margen {
            background: #1E1E1E;
          }
        }
        
        &.second {
          background: #9747FF;
          
          .second-content {
            background: #1E1E1E;
          }
          
          .second-margenv2, 
          .second-margenv3 {
            background: #1E1E1E;
            
            &::before {
              background: #9747FF;
            }
          }
        }
      }
    }
    
    .card-content {
      background: #1E1E1E;
      
      .image-wrapper {
        .image-border {
          background: #ffffff;
          border-color: #00a651;
        }
      }
      
      .info-wrapper {
        .info {
          .line {
            background: #00a651;
          }
          
          .text {
            color: #ffffff;
          }
        }
      }
    }
  }
}

// Mantenemos los hover effects consistentes
.GM__light-vehicle-card, 
.GM__dark-vehicle-card {
  .card-link, 
  .card-link-combine {
    &:hover {
      .vehicle-card {
        transform: translateY(-5px);
        transition: transform 0.3s ease;
      }
    }
  }
}