// Variables para el tema claro
$background-light: #f5f7fa;
$background-lighter: #ffffff;
$border-color-light: #e0e6ed;
$border-color-lighter: #d1d9e6;
$text-color-light: #2c3e50;
$text-color-muted-light: #7f8c9a;
$accent-color-light: #4CAF50;
$accent-hover-light: #3e8e41;
$danger-color-light: #e74c3c;
$danger-hover-light: #c0392b;

.GM__light-dashboard-vehicle {
  width: 100%;
  height: 87vh; // Ajustado para coincidir con el tema oscuro
  background: $background-lighter;
  color: $text-color-light;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  
  header {
    background: #ffffff;
    border-color: #e0e6ed;
    
    .header-right {
      .settings-icon-wrapper {
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  main {
    flex: 1;
    overflow-y: auto;
    background-color: $background-light;
    padding: 20px;
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $background-lighter;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $border-color-light;
      border-radius: 4px;
      
      &:hover {
        background: $border-color-lighter;
      }
    }
    
    // Estilo para tarjetas originales (mantiene compatibilidad)
    .vehicle-card {
      border: 1px solid $border-color-light;
      padding: 16px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      background-color: $background-lighter;
      transition: transform 0.2s ease, box-shadow 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
      }

      h3 {
        font-size: 18px;
        margin: 0;
        color: $text-color-light;
        font-weight: 600;
      }

      p {
        margin: 8px 0;
        color: $text-color-muted-light;
        font-size: 14px;
      }
    }
    .vehicles-grid {
      width: 100%;
    }
    
    .loading-indicator {
      background-color: rgba(0, 0, 0, 0.03);
      color: $text-color-light;
    }
    
    .no-results {
      background-color: rgba(0, 0, 0, 0.05);
      color: $text-color-muted-light;
    }
    
    // Estilos específicos para tarjetas combinadas en tema claro
    .combined-vehicle-container {
      .vehicle-card {
        margin: 0; // Resetear margen para componentes combinados
        
        &.trailer {
          .image-border.trailer-border {
            border-color: #FFAA00;
          }
          
          .line.trailer-line {
            background: #FFAA00;
          }
        }
      }
    }
  }

  footer {
    margin-top: auto;
    padding: 20px;
    text-align: center;
    border-top: 1px solid $border-color-light;
    background: $background-lighter;
  }
}