.critical-points-table-container {
  width: 100%;
  overflow-x: auto;
  margin: 2rem 0;
  
  // Light theme (default)
  &.theme-light {
    .critical-points-table {
      th, td {
        border: 1px solid #ddd;
        color: #333;
        
        &.quarter-separator {
          border-right: 2px solid #666;
        }
      }
      
      th {
        background-color: #f5f5f5;
        
        &.point-name-header {
          background-color: #f5f5f5;
        }
      }
      
      td {
        background-color: #ffffff;
        
        &.row-total,
        &.monthly-total {
          background-color: #f9f9f9;
        }
        
        &.grand-total {
          background-color: #f0f0f0;
        }
      }
      
      tfoot tr {
        background-color: #f5f5f5;
      }
    }
  }
  
  // Dark theme
  &.theme-dark {
    .critical-points-table {
      th, td {
        border: 1px solid #444;
        color: #e0e0e0;
        
        &.quarter-separator {
          border-right: 2px solid #666;
        }
      }
      
      th {
        background-color: #1E1E1E;
        
        &.point-name-header {
          background-color: #1E1E1E;
        }
      }
      
      td {
        background-color: #1E1E1E;
        
        &.row-total,
        &.monthly-total {
          background-color: #1E1E1E;
        }
        
        &.grand-total {
          background-color: #1E1E1E;
        }
      }
      
      tfoot tr {
        background-color: #1E1E1E;
      }
    }
  }

  .critical-points-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    
    th, td {
      padding: 0.75rem;
      text-align: center;
      transition: background-color 0.3s ease;
    }
    
    th {
      font-weight: bold;
      
      &.point-name-header {
        text-align: left;
        min-width: 200px;
      }
    }
    
    td {
      &.point-name {
        text-align: left;
        font-weight: 500;
      }
      
      &.violation-cell {
        position: relative;
        padding: 0;
        height: 40px;
        
        .violation-indicator {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          cursor: pointer;
          color: rgb(0, 0, 0);
          font-weight: bold;
          transition: all 0.2s ease;
          
          &:hover {
            transform: scale(1.1);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
    
    tfoot {
      .total-label {
        text-align: left;
        font-weight: bold;
      }
    }
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 16px;

    .custom-pagination {
      &.light {
        // Estilos para tema claro
        .MuiPaginationItem-root {
          color: #333;
          
          &.Mui-selected {
            background-color: #1976d2;
            color: white;
            
            &:hover {
              background-color: #1565c0;
            }
          }
          
          &:hover {
            background-color: rgba(25, 118, 210, 0.1);
          }
        }
      }

      &.dark {
        // Estilos para tema oscuro
        .MuiPaginationItem-root {
          color: #fff;
          
          &.Mui-selected {
            background-color: #90caf9;
            color: #1a1a1a;
            
            &:hover {
              background-color: #82b1ff;
            }
          }
          
          &:hover {
            background-color: rgba(144, 202, 249, 0.1);
          }
        }

        .MuiPaginationItem-ellipsis {
          color: #fff;
        }

        button {
          &.Mui-disabled {
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }

      // Estilos comunes para ambos temas
      .MuiPaginationItem-root {
        font-size: 14px;
        min-width: 32px;
        height: 32px;
        margin: 0 4px;
        padding: 0;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;

        &:focus {
          outline: none;
        }
      }
    }
  }
}