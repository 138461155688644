$background-dark: #111;
$background-medium: #222;
$border-color: #333;
$border-color-light: #444;
$text-color: #fff;
.multi-download-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  
    .modal-backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  
    .modal-content-preview {
      position: relative;
      background: #23262d;
      border-radius: 8px;
      width: 60%;
      height: 80%;
      padding: 20px;
      display: flex;
      flex-direction: column;
  
      .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
  
        h2 {
          font-size: 1.5rem;
          margin: 0;
          color: #fff;
        }
  
        .user-name {
          font-size: 0.9rem;
          color: #a8a8a8;
          margin-top: 5px;
        }
  
        .close-button {
          background: none;
          border: none;
          font-size: 1.2rem;
          cursor: pointer;
          color: #fff;
        }
      }
  
      .modal-body {
        display: flex;
        gap: 20px;
        height: 90%;
  
        .column {
          flex: 1;
  
          &.left {
            .actions {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
  
              label {
                display: flex;
                align-items: center;
                font-size: 1rem;
                color: #ffffff;
  
                input {
                  margin-right: 10px;
                }
              }
            }
  
            .document-list {
              height: 95%;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
              }
              
              &::-webkit-scrollbar-track {
                background: $background-dark;
              }
              
              &::-webkit-scrollbar-thumb {
                background: $border-color;
                border-radius: 4px;
                
                &:hover {
                  background: $border-color-light;
                }
              }
  
              .section-download {
                margin-bottom: 10px;
  
                .section-title {
                  font-size: 1.2rem;
                  font-weight: bold;
                  margin-bottom: 10px;
                  color: #ffffff;
                }
              }
  
              .clickable {
                background-color: #2b2f36;
                padding: 2px;
                border-radius: 4px;
                font-size: 1.1rem;
                font-weight: bold;
                cursor: pointer;
                color: #ffffff;
              }
  
              .collapsible-content {
                margin-left: 20px; // Espacio para diferenciar la lista interna
                margin-bottom: 20px;
              }
  
              .document-item {
                margin-bottom: 15px;
  
                label {
                  display: flex;
                  align-items: center;
                  gap: 15px;
                  color: #ffffff;
  
                  svg {
                    width: 24px;
                    height: 24px;
                  }
  
                  input {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
  
          &.right {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
  
            .preview-image {
              width: 70%;
              height: auto;
              margin-bottom: 20px;
            }
  
            .download-button {
              background-color: #28a745;
              color: white;
              border: none;
              padding: 10px 20px;
              border-radius: 4px;
              cursor: pointer;
              font-size: 1rem;
  
              &:disabled {
                background-color: #6c757d;
                cursor: not-allowed;
              }
  
              &:hover:enabled {
                background-color: #218838;
              }
            }
          }
        }
      }
    }
  }
  