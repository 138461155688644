.color-picker {
    position: relative;
    
    &__swatch {
      padding: 5px;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #ddd;
      display: inline-block;
      cursor: pointer;
    }
    
    &__color {
      width: 36px;
      height: 18px;
      border-radius: 2px;
    }
    
    &__popover {
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      margin-top: 5px;
    }
    
    &__cover {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }