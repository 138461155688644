// DocumentRequirementsManagerLight.scss - Tema claro para el gestor de requisitos

// Variables para el tema claro
$background-light: #f8f9fa;
$card-bg-light: #ffffff;
$primary-color-light: #1976d2;
$primary-hover-light: #1565c0;
$success-color-light: #2e7d32;
$warning-color-light: #ed6c02;
$danger-color-light: #d32f2f;
$text-primary-light: #333333;
$text-secondary-light: #666666;
$border-color-light: #e0e0e0;
$hover-bg-light: #f5f5f5;
$selected-bg-light: #e3f2fd;
$disabled-bg-light: #f5f5f5;
$disabled-text-light: #9e9e9e;
$chip-bg-light: #e0e0e0;
$chip-text-light: #333333;
$required-bg-light: rgba(46, 125, 50, 0.1);
$not-required-bg-light: rgba(211, 47, 47, 0.1);
$scrollbar-track-light: #f0f0f0;
$scrollbar-thumb-light: #c0c0c0;
$scrollbar-thumb-hover-light: #a0a0a0;
$divider-light: #e0e0e0;
$tab-active-color-light: #1976d2;
$tab-inactive-color-light: #666666;

// Contenedor principal
.GM__light-document-requirements-manager {
  @extend %flex-container;
  background-color: $background-light;
  padding: var(--spacing-md);
  min-height: 600px;
  width: 100%;
  box-sizing: border-box;
  
  .GM__light-header-section {
    margin-bottom: var(--spacing-lg);
    
    .GM__light-section-title {
      font-size: 1.75rem;
      color: $text-primary-light;
      margin: 0 0 var(--spacing-sm) 0;
      font-weight: 600;
    }
  }
  
  .GM__light-tabs-container {
    @extend %flex-container;
    background-color: $card-bg-light;
    @extend %card-base;
    flex: 1;
    overflow: hidden;
    
    .GM__light-configuration-tabs {
      background-color: $card-bg-light;
      border-bottom: 1px solid $border-color-light;
      
      .GM__light-tab {
        color: $tab-inactive-color-light;
        font-size: 0.95rem;
        
        &.Mui-selected {
          color: $tab-active-color-light;
          font-weight: 600;
        }
      }
      
      .MuiTabs-indicator {
        background-color: $tab-active-color-light;
      }
    }
    
    .GM__light-tab-content {
      padding: var(--spacing-md);
      overflow: auto;
      flex: 1;
      
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-light;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-light;
        
        &:hover {
          background: $scrollbar-thumb-hover-light;
        }
      }
    }
  }
}

// Componentes de configuración por operación
.GM__light-operation-config-tab {
  @extend %flex-row;
  gap: var(--spacing-lg);
  height: 100%;
  
  .GM__light-operation-list {
    width: 280px;
    background-color: $card-bg-light;
    @extend %card-base;
    @extend %flex-container;
    padding: var(--spacing-md);
    
    .GM__light-list-title {
      font-size: 1.1rem;
      color: $text-primary-light;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__light-scrollable-list {
      @extend %flex-container;
      overflow-y: auto;
      flex: 1;
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-light;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-light;
        
        &:hover {
          background: $scrollbar-thumb-hover-light;
        }
      }
      
      .GM__light-list-item {
        padding: var(--spacing-md);
        border-radius: var(--input-radius);
        cursor: pointer;
        transition: background-color var(--transition-speed) ease;
        color: $text-primary-light;
        margin-bottom: var(--spacing-xs);
        
        &:hover {
          background-color: $hover-bg-light;
        }
        
        &.GM__light-selected {
          background-color: $selected-bg-light;
          font-weight: 500;
          color: $primary-color-light;
        }
      }
    }
  }
  
  .GM__light-operation-detail {
    flex: 1;
    
    .GM__light-detail-panel {
      @extend %flex-container;
      @extend %card-base;
      background-color: $card-bg-light;
      padding: var(--spacing-md);
      height: 100%;
    }
  }
}

// Componentes de configuración por tipo de vehículo
.GM__light-vehicle-type-config-tab {
  @extend %flex-row;
  gap: var(--spacing-lg);
  height: 100%;
  
  .GM__light-vehicle-type-list {
    width: 300px;
    background-color: $card-bg-light;
    @extend %card-base;
    @extend %flex-container;
    padding: var(--spacing-md);
    
    .GM__light-list-title {
      font-size: 1.1rem;
      color: $text-primary-light;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__light-scrollable-list {
      @extend %flex-container;
      overflow-y: auto;
      flex: 1;
      @extend %scrollbar-base;
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-light;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-light;
        
        &:hover {
          background: $scrollbar-thumb-hover-light;
        }
      }
      
      .GM__light-vehicle-accordion {
        margin-bottom: var(--spacing-sm);
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $border-color-light;
        border-radius: var(--input-radius);
        
        .GM__light-accordion-summary {
          padding: var(--spacing-sm) var(--spacing-md);
          min-height: auto;
          
          .GM__light-category-title {
            font-size: 1rem;
            margin: 0;
            color: $text-primary-light;
            font-weight: 500;
          }
        }
        
        .GM__light-accordion-details {
          padding: 0 var(--spacing-md) var(--spacing-sm);
          
          .GM__light-category-items {
            @extend %flex-container;
            
            .GM__light-list-item {
              padding: var(--spacing-md);
              border-radius: var(--input-radius);
              cursor: pointer;
              transition: background-color var(--transition-speed) ease;
              color: $text-primary-light;
              margin-bottom: var(--spacing-xs);
              
              &:hover {
                background-color: $hover-bg-light;
              }
              
              &.GM__light-selected {
                background-color: $selected-bg-light;
                font-weight: 500;
                color: $primary-color-light;
              }
            }
          }
        }
      }
    }
  }
  
  .GM__light-vehicle-type-detail {
    flex: 1;
    
    .GM__light-detail-panel {
      @extend %flex-container;
      @extend %card-base;
      background-color: $card-bg-light;
      padding: var(--spacing-md);
      height: 100%;
    }
  }
}

// Estilos compartidos de paneles de detalle
.GM__light-detail-panel {
  .GM__light-detail-header {
    @extend %flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-lg);
    
    .GM__light-detail-title {
      font-size: 1.3rem;
      color: $text-primary-light;
      margin: 0;
      font-weight: 600;
    }
    
    .GM__light-configure-button {
      background-color: $primary-color-light;
      color: white;
      border: none;
      padding: var(--spacing-sm) var(--spacing-md);
      border-radius: var(--input-radius);
      cursor: pointer;
      font-weight: 500;
      transition: background-color var(--transition-speed) ease;
      
      &:hover {
        background-color: $primary-hover-light;
      }
    }
  }
  
  .GM__light-detail-info {
    @extend %flex-container;
    margin-bottom: var(--spacing-lg);
    
    .GM__light-info-row {
      @extend %flex-row;
      margin-bottom: var(--spacing-md);
      
      .GM__light-info-label {
        font-weight: 500;
        color: $text-secondary-light;
        width: 180px;
      }
      
      .GM__light-info-value {
        color: $text-primary-light;
      }
    }
  }
  
  .GM__light-document-list-section {
    .GM__light-section-subtitle {
      font-size: 1.1rem;
      color: $text-primary-light;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__light-document-chips {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-sm);
      
      .GM__light-document-chip {
        background-color: $chip-bg-light;
        color: $chip-text-light;
        border-radius: 16px;
        padding: 4px 12px;
        font-size: 0.85rem;
        
        &.MuiChip-root {
          height: 28px;
        }
      }
    }
    
    .GM__light-no-documents-message {
      color: $text-secondary-light;
      font-style: italic;
    }
  }
}

// Matriz Comparativa
.GM__light-comparison-matrix-tab {
  @extend %flex-container;
  gap: var(--spacing-lg);
  
  .GM__light-operation-selection {
    background-color: $card-bg-light;
    @extend %card-base;
    padding: var(--spacing-md);
    margin-bottom: var(--spacing-md);
    
    .GM__light-section-subtitle {
      font-size: 1.1rem;
      color: $text-primary-light;
      margin: 0 0 var(--spacing-md) 0;
      font-weight: 600;
    }
    
    .GM__light-operation-checkboxes {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-md);
      
      .GM__light-operation-checkbox-label {
        margin: 0;
        
        .GM__light-operation-checkbox {
          color: $primary-color-light;
          
          &.Mui-checked {
            color: $primary-color-light;
          }
        }
      }
    }
  }
  
  .GM__light-matrix-container {
    @extend %flex-container;
    flex: 1;
    
    .GM__light-instruction-message {
      text-align: center;
      color: $text-secondary-light;
      padding: var(--spacing-xl) 0;
    }
    
    .GM__light-error-message {
      text-align: center;
      color: $danger-color-light;
      padding: var(--spacing-xl) 0;
    }
    
    .GM__light-comparison-table-wrapper {
      @extend %flex-container;
      background-color: $card-bg-light;
      @extend %card-base;
      padding: var(--spacing-md);
      
      .GM__light-matrix-title {
        font-size: 1.2rem;
        color: $text-primary-light;
        margin: 0 0 var(--spacing-md) 0;
        font-weight: 600;
      }
      
      .GM__light-vehicle-table-wrapper-table {
        overflow-x: auto;
        @extend %scrollbar-base;
        
        &::-webkit-scrollbar-track {
          background: $scrollbar-track-light;
        }
        
        &::-webkit-scrollbar-thumb {
          background: $scrollbar-thumb-light;
          
          &:hover {
            background: $scrollbar-thumb-hover-light;
          }
        }
        
        .GM__light-vehicle-table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
          
          th, td {
            padding: var(--spacing-md);
            border-bottom: 1px solid $border-color-light;
            
            &.fixed-column {
              position: sticky;
              left: 0;
              background-color: $card-bg-light;
              z-index: 10;
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
            }
          }
          
          th {
            text-align: left;
            background-color: $hover-bg-light;
            font-weight: 600;
            color: $text-primary-light;
            
            &.fixed-column {
              z-index: 11;
              background-color: $hover-bg-light;
            }
          }
          
          .GM__light-document-name {
            font-weight: 500;
            color: $text-primary-light;
          }
          
          .GM__light-requirement-cell {
            text-align: center;
            
            .GM__light-required-indicator {
              background-color: $required-bg-light;
              color: $success-color-light;
              padding: 4px 8px;
              border-radius: var(--input-radius);
              font-size: 0.85rem;
              font-weight: 500;
            }
            
            .GM__light-not-required-indicator {
              background-color: $not-required-bg-light;
              color: $danger-color-light;
              padding: 4px 8px;
              border-radius: var(--input-radius);
              font-size: 0.85rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

// Diálogo de selección de documentos
.GM__light-document-selection-modal {
  min-width: 600px;
  max-width: 90vw;
  
  .GM__light-modal-header-container {
    padding: var(--spacing-md) var(--spacing-lg);
    border-bottom: 1px solid $border-color-light;
    
    .GM__light-modal-header {
      font-size: 1.3rem;
      color: $text-primary-light;
      margin: 0;
      font-weight: 600;
    }
  }
  
  .GM__light-modal-content {
    padding: var(--spacing-lg);
    max-height: 60vh;
    overflow-y: auto;
    @extend %scrollbar-base;
    
    &::-webkit-scrollbar-track {
      background: $scrollbar-track-light;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $scrollbar-thumb-light;
      
      &:hover {
        background: $scrollbar-thumb-hover-light;
      }
    }
    
    .GM__light-selection-instruction {
      margin: 0 0 var(--spacing-md) 0;
      color: $text-secondary-light;
    }
    
    .GM__light-document-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: var(--spacing-md);
      
      .GM__light-document-checkbox-item {
        .GM__light-document-checkbox-label {
          margin: 0;
          display: flex;
          align-items: flex-start;
          
          .GM__light-document-checkbox {
            color: $primary-color-light;
            padding: 4px;
            margin-right: 8px;
            
            &.Mui-checked {
              color: $primary-color-light;
            }
          }
          
          .GM__light-document-label {
            display: flex;
            align-items: center;
            
            .GM__light-document-name-label {
              font-size: 0.95rem;
              color: $text-primary-light;
            }
            
            .GM__light-info-icon {
              margin-left: var(--spacing-xs);
              font-size: 1rem;
              color: $text-secondary-light;
              cursor: help;
            }
          }
        }
      }
    }
  }
  
  .GM__light-modal-footer {
    padding: var(--spacing-md) var(--spacing-lg);
    border-top: 1px solid $border-color-light;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-md);
    
    .GM__light-btn {
      padding: var(--spacing-sm) var(--spacing-lg);
      border-radius: var(--input-radius);
      font-weight: 500;
      cursor: pointer;
      border: none;
      transition: background-color var(--transition-speed) ease;
      
      &.GM__light-btn-cancel {
        background-color: $hover-bg-light;
        color: $text-primary-light;
        
        &:hover {
          background-color: darken($hover-bg-light, 5%);
        }
      }
      
      &.GM__light-btn-save {
        background-color: $primary-color-light;
        color: white;
        
        &:hover {
          background-color: $primary-hover-light;
        }
        
        &:disabled {
          background-color: $disabled-bg-light;
          color: $disabled-text-light;
          cursor: not-allowed;
        }
      }
    }
  }
}

// Estados vacíos y de error
.GM__light-empty-state {
  text-align: center;
  padding: var(--spacing-xl) 0;
  color: $text-secondary-light;
  
  p {
    margin: var(--spacing-sm) 0;
  }
}

.GM__light-error-state {
  color: $danger-color-light;
  text-align: center;
  padding: var(--spacing-xl) 0;
}