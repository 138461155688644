.violation-table {
  width: 100%;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;

  // Tema oscuro
  &.dark {
    background: transparent;

    .MuiTableContainer-root {
      background: transparent;
      box-shadow: none;
    }

    .table {
      background: transparent;
      border-radius: 15px 15px 0 0;

      // Encabezado de la tabla
      .MuiTableHead-root {
        background: #0d4e57;


        .MuiTableCell-head {
          color: white;
          font-weight: 600;
          padding: 16px 20px;
          font-size: 1rem;
          text-transform: uppercase;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);

          &:first-of-type {
            border-top-left-radius: 15px;
            padding-left: 24px;
          }
          &:last-of-type {
            border-top-right-radius: 15px;
            padding-right: 24px;
          }

        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
          background: #2A2D34;
          transition: background-color 0.2s ease;


          &:hover {
            background: #324752;
          }

          .MuiTableCell-body {
            color: white;
            border: none;
            padding: 16px 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &:first-of-type {
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              padding-left: 24px;
            }

            &:last-of-type {
              border-top-right-radius: 15px;
              border-bottom-right-radius: 15px;
              padding-right: 24px;
            }
          }
          .MuiButton-contained {
            background: #00A651;
            color: white;
            text-transform: none;
            border-radius: 20px;
            padding: 6px 16px;
            font-size: 0.875rem;
            min-width: 120px;

            &:hover {
              background: #008f6f;
            }
          }
        }
      }
    }
  }
}
.violation-details-dialog {
  .MuiDialog-paper {
    background: #1a2c34;
    color: white;
    border-radius: 8px;
    max-width: 65%;
  }

  .dialog-header {
    background: #0d4e57;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dialog-title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .close-button {
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 1.25rem;
      padding: 4px 8px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
      }
    }
  }

  .dialog-content {
    background: #2A2D34;
    padding: 24px;

    .driver-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .stats {
        text-align: right;
        
        span {
          font-size: 1.5rem;
          font-weight: 500;
          color: #00A651;
          display: block;
        }
      }
    }

    .vehicle-info {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      .info-field {
        flex: 1;
        background: rgba(255, 255, 255, 0.05);
        padding: 12px;
        border-radius: 4px;

        .field-value {
          margin-top: 4px;
          font-weight: 500;
        }
      }
    }

    .occurrence-card {
      background: #1a2c34;
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 16px;

      .occurrence-title {
        color: #00A651;
        margin-bottom: 16px;
      }

      .occurrence-details {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .detail-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.coordinates {
            padding-left: 24px;
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .detail-group {
          background: rgba(255, 255, 255, 0.05);
          padding: 12px;
          border-radius: 4px;
        }

        .map-button {
          background: #00A651;
          color: white;
          border-radius: 20px;
          text-transform: none;
          margin-left: 16px;

          &:hover {
            background: #008f6f;
          }
        }
      }
    }
  }
}
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 16px;

  .custom-pagination {
    &.light {
      // Estilos para tema claro
      .MuiPaginationItem-root {
        color: #333;
        
        &.Mui-selected {
          background-color: #1976d2;
          color: white;
          
          &:hover {
            background-color: #1565c0;
          }
        }
        
        &:hover {
          background-color: rgba(25, 118, 210, 0.1);
        }
      }
    }

    &.dark {
      // Estilos para tema oscuro
      .MuiPaginationItem-root {
        color: #fff;
        
        &.Mui-selected {
          background-color: #90caf9;
          color: #1a1a1a;
          
          &:hover {
            background-color: #82b1ff;
          }
        }
        
        &:hover {
          background-color: rgba(144, 202, 249, 0.1);
        }
      }

      .MuiPaginationItem-ellipsis {
        color: #fff;
      }

      button {
        &.Mui-disabled {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    // Estilos comunes para ambos temas
    .MuiPaginationItem-root {
      font-size: 14px;
      min-width: 32px;
      height: 32px;
      margin: 0 4px;
      padding: 0;
      border-radius: 4px;
      transition: all 0.2s ease-in-out;

      &:focus {
        outline: none;
      }
    }
  }
}
.no-data {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  overflow: hidden;

}