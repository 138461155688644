.cards-carousel-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  
    .carousel-button {
      background: rgba(255, 255, 255, 0.8);
      border: none;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;
      z-index: 2;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
      &:hover:not(:disabled) {
        background: rgba(255, 255, 255, 0.9);
        transform: scale(1.1);
      }
  
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  
    .carousel-content {
      flex: 1;
      overflow: hidden;
      position: relative;
  
      .carousel-items {
        display: flex;
        transition: transform 0.5s ease;
        width: 100%;
  
        .carousel-item {
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem 0;
        }
      }
    }
  }