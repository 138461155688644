// Variables para el tema oscuro
$table-bg-dark: #222;
$table-border-dark: #333;
$table-header-bg-dark: #111;
$table-row-hover-dark: #333;
$text-primary-dark: #fff;
$text-secondary-dark: #aaa;
$accent-color-dark: #0099ff;
$accent-hover-dark: #00b3ff;
$warning-color-dark: #ffc107;
$warning-hover-dark: #e0a800;
$danger-color-dark: #ff4d4d;
$danger-hover-dark: #ff2626;
$secondary-color-dark: #6c757d;
$secondary-hover-dark: #5a6268;
$input-bg-dark: #333;
$input-border-dark: #444;
$modal-bg-dark: #222;

// Estilos para el componente de tabla en tema oscuro
.GM__dark-vehicle-table-wrapper {
  margin: 2rem 0;
  font-family: "Roboto", sans-serif;
  color: $text-primary-dark;
  
  // Sección de encabezado
  .GM__dark-header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    
    .GM__dark-section-title {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 600;
      color: $text-primary-dark;
    }
    
    .GM__dark-add-button {
      background-color: $accent-color-dark;
      color: #fff;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 600;
      transition: background-color 0.2s ease;
      
      &:hover {
        background-color: $accent-hover-dark;
      }
    }
  }
  
  // Contenedor de tabla con scroll
  .GM__dark-vehicle-table-wrapper-table {
    position: relative;
    overflow-x: auto;
    background: $table-bg-dark;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    max-width: 100%;
    
    &::-webkit-scrollbar {
      height: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: $table-border-dark;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: $secondary-color-dark;
      border-radius: 4px;
      
      &:hover {
        background: $secondary-hover-dark;
      }
    }
    
    // Tabla principal
    .GM__dark-vehicle-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      background-color: $table-bg-dark; // Fondo explícito para la tabla
      
      // Estilos para encabezados
      thead {
        tr {
          th {
            background-color: $table-header-bg-dark;
            padding: 0.75rem;
            text-align: left;
            font-weight: 600;
            color: $text-primary-dark;
            border-bottom: 2px solid $table-border-dark;
            position: relative;
            min-width: 120px;
            
            &:first-child {
              border-top-left-radius: 4px;
            }
            
            &:last-child {
              border-top-right-radius: 4px;
            }
          }
        }
      }
      
      // Estilos para cuerpo de tabla
      tbody {
        tr {
          transition: background-color 0.2s ease;
          
          &:hover {
            background-color: $table-row-hover-dark;
          }
          
          &:last-child td {
            border-bottom: none;
            
            &:first-child {
              border-bottom-left-radius: 4px;
            }
            
            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
          
          td {
            padding: 0.75rem;
            color: $text-primary-dark;
            position: relative;
            border-bottom: 1px solid $table-border-dark;
            background-color: $table-bg-dark; // Fondo explícito para todas las celdas
            
            &.GM__dark-empty-message {
              text-align: center;
              padding: 1.5rem;
              color: $text-secondary-dark;
            }
            
            // Estilo para celdas con botón de copia
            &.GM__dark-copyable-cell {
              position: relative;
              padding-right: 2.5rem;
              
              span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              
              .GM__dark-copy-icon {
                position: absolute;
                right: 0.5rem;
                top: 50%;
                transform: translateY(-50%);
                background: none;
                border: none;
                padding: 0.25rem;
                cursor: pointer;
                opacity: 0.6;
                transition: opacity 0.2s ease;
                filter: invert(1); // Para el tema oscuro
                
                &:hover {
                  opacity: 1;
                }
                
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
            
            // Estilos para botones de acción
            &.GM__dark-action-buttons {
              display: flex;
              gap: 0.5rem;
              
              .GM__dark-edit-button {
                background-color: $warning-color-dark;
                color: #000;
                border: none;
                padding: 0.4rem 0.75rem;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.2s ease;
                
                &:hover {
                  background-color: $warning-hover-dark;
                }
              }
              
              .GM__dark-delete-button {
                background-color: $danger-color-dark;
                color: #fff;
                border: none;
                padding: 0.4rem 0.75rem;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.2s ease;
                
                &:hover {
                  background-color: $danger-hover-dark;
                }
              }
            }
          }
        }
      }
      
      // Implementación de columnas fijas - Sistema optimizado para tema oscuro
      
      // Modo 1: Solo primera columna fija
      &.fixed-columns-1 {
        th, td {
          &.fixed-column-1 {
            position: sticky !important;
            left: 0;
            z-index: 10;
            background-color: $table-bg-dark !important; // Color de fondo explícito
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent);
            }
          }
        }
        
        thead th.fixed-column-1 {
          background-color: $table-header-bg-dark !important; // Color de fondo explícito para header
          z-index: 20;
        }
        
        tbody tr:hover td.fixed-column-1 {
          background-color: $table-row-hover-dark !important; // Color de fondo explícito para hover
        }
      }
      
      // Modo 2: Solo segunda columna fija
      &.fixed-columns-2 {
        th, td {
          &.fixed-column-2 {
            position: sticky !important;
            left: 0;
            z-index: 10;
            background-color: $table-bg-dark !important; // Color de fondo explícito
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent);
            }
          }
        }
        
        thead th.fixed-column-2 {
          background-color: $table-header-bg-dark !important; // Color de fondo explícito para header
          z-index: 20;
        }
        
        tbody tr:hover td.fixed-column-2 {
          background-color: $table-row-hover-dark !important; // Color de fondo explícito para hover
        }
      }
      
      // Modo 3: Ambas columnas fijas
      &.fixed-columns-3 {
        th, td {
          &.fixed-column-1, &.fixed-column-2 {
            position: sticky !important;
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 4px;
              pointer-events: none;
              background: linear-gradient(to right, rgba(0, 0, 0, 0.3), transparent);
            }
          }
          
          &.fixed-column-1 {
            left: 0;
            z-index: 12;
            background-color: $table-bg-dark !important; // Color de fondo explícito
          }
          
          &.fixed-column-2 {
            left: 150px; // Ajustar según el ancho de la primera columna
            z-index: 11;
            background-color: $table-bg-dark !important; // Color de fondo explícito
          }
        }
        
        thead th {
          &.fixed-column-1 {
            background-color: $table-header-bg-dark !important; // Color de fondo explícito para header
            z-index: 22;
          }
          
          &.fixed-column-2 {
            background-color: $table-header-bg-dark !important; // Color de fondo explícito para header
            z-index: 21;
          }
        }
        
        tbody tr:hover td {
          &.fixed-column-1, &.fixed-column-2 {
            background-color: $table-row-hover-dark !important; // Color de fondo explícito para hover
          }
        }
      }
    }
  }
  
  // Estilos del modal
  .GM__dark-vehicle-modal {
    background-color: $modal-bg-dark;
    padding: 2rem;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    max-width: 480px;
    width: 90%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    
    .GM__dark-modal-title {
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.3rem;
      color: $text-primary-dark;
      font-weight: 600;
    }
    
    .GM__dark-form-label {
      display: block;
      margin-top: 1rem;
      font-weight: 600;
      color: $text-primary-dark;
    }
    
    .GM__dark-form-input {
      width: 100%;
      padding: 0.5rem;
      margin-top: 0.25rem;
      border: 1px solid $input-border-dark;
      border-radius: 4px;
      font-size: 0.95rem;
      color: $text-primary-dark;
      background-color: $input-bg-dark;
      
      &:focus {
        outline: none;
        border-color: $accent-color-dark;
        box-shadow: 0 0 0 2px rgba(0, 153, 255, 0.25);
      }
    }
    
    .GM__dark-form-select {
      width: 100%;
      padding: 0.5rem;
      margin-top: 0.25rem;
      border: 1px solid $input-border-dark;
      border-radius: 4px;
      font-size: 0.95rem;
      color: $text-primary-dark;
      background-color: $input-bg-dark;
      
      &:focus {
        outline: none;
        border-color: $accent-color-dark;
        box-shadow: 0 0 0 2px rgba(0, 153, 255, 0.25);
      }
    }
    
    .GM__dark-error-message {
      color: $danger-color-dark;
      font-size: 0.85rem;
      margin-top: 0.25rem;
      display: block;
    }
    
    .GM__dark-modal-actions {
      margin-top: 1.5rem;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      
      .GM__dark-save-button {
        background-color: $accent-color-dark;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: $accent-hover-dark;
        }
      }
      
      .GM__dark-cancel-button {
        background-color: $secondary-color-dark;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 600;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: $secondary-hover-dark;
        }
      }
    }
  }
}

// Ajustes para dispositivos móviles
@media (max-width: 1024px) {
  .GM__dark-vehicle-table-wrapper {
    .GM__dark-vehicle-table-wrapper-table {
      .GM__dark-vehicle-table {
        &.fixed-columns-3 {
          th, td {
            &.fixed-column-2 {
              left: 120px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .GM__dark-vehicle-table-wrapper {
    margin: 1rem 0;
    
    .GM__dark-header-section {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      
      .GM__dark-add-button {
        width: 100%;
      }
    }
    
    .GM__dark-vehicle-table-wrapper-table {
      .GM__dark-vehicle-table {
        &.fixed-columns-3 {
          th, td {
            &.fixed-column-2 {
              left: 100px;
            }
          }
        }
        
        th, td {
          padding: 0.5rem;
          font-size: 0.9rem;
        }
      }
    }
  }
}