// ExportManager.scss
.export-manager {
    padding: 15px;
    display: flex;
    flex-direction: column;
    
    h3 {
      margin: 0 0 15px 0;
      font-size: 1.2rem;
      color: #333;
    }
    
    .form-group {
      margin-bottom: 15px;
      
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
        color: #555;
        
        &.checkbox-label {
          display: flex;
          align-items: center;
          font-weight: normal;
          
          input[type="checkbox"] {
            margin-right: 8px;
          }
        }
      }
    }
    
    .export-stats {
      background-color: #f5f5f5;
      border-radius: 4px;
      padding: 12px;
      margin-bottom: 15px;
      
      .stat {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        
        .stat-label {
          color: #666;
        }
        
        .stat-value {
          font-weight: 500;
          color: #333;
        }
      }
    }
    
    .export-btn {
      margin-bottom: 15px;
      background-color: #2196f3;
      color: white;
      padding: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      
      &:hover {
        background-color: #1976d2;
      }
      
      &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
    
    .export-help {
      border-top: 1px solid #eee;
      padding-top: 15px;
      
      h4 {
        margin: 0 0 10px 0;
        font-size: 1rem;
        color: #333;
      }
      
      p {
        margin: 0;
        font-size: 0.9rem;
        color: #666;
        line-height: 1.4;
      }
    }
  }