// Variables compartidas
$light-background: #ffffff;
$light-paper-background: #f8f8f8;
$light-text-color: #333333;
$light-secondary-color: #666666;
$light-hover-background: rgba(0, 0, 0, 0.04);
$light-selected-background: rgba(25, 118, 210, 0.08);
$light-selected-text: #1976d2;
$light-divider-color: #e0e0e0;

$dark-background: #121212;
$dark-paper-background: #1e1e1e;
$dark-text-color: #ffffff;
$dark-secondary-color: #aaaaaa;
$dark-hover-background: rgba(255, 255, 255, 0.08);
$dark-selected-background: rgba(144, 202, 249, 0.16);
$dark-selected-text: #90caf9;
$dark-divider-color: #333333;

// Estilos base del componente
.filter-menu-container {
  position: relative;
  display: inline-block;
  height: 100%;
  
   // Estilo específico para el botón
   .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    transition: background-color 0.2s;
    cursor: pointer;
    
    .filter-icon {
      margin-right: 8px;
    }
    
    // Tema claro
    &.light {
      color: $light-text-color;
      background-color: $light-background;
      border: 1px solid $light-divider-color;
      
      &:hover {
        background-color: $light-hover-background;
      }
    }
    
    // Tema oscuro
    &.dark {
      color: $dark-text-color;
      background-color: rgba(255, 255, 255, 0.05);
      border: 1px solid $dark-divider-color;
      
      &:hover {
        background-color: $dark-hover-background;
      }
    }
  }
  // Menú desplegable
  .menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 260px; // Reducido de 280px
    max-height: 450px; // Reducido para que no ocupe tanto espacio
    overflow-y: auto;
    border-radius: 4px;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 8px;
    
    // Personalización de la barra de desplazamiento
    &::-webkit-scrollbar {
      width: 6px; // Reducido el ancho del scrollbar
    }
    
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
  }
  
  // Elementos del menú
  .filter-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 38px; // Ligeramente más pequeño
    padding: 6px 16px; // Ajustado padding vertical
    cursor: pointer;
    transition: background-color 0.2s;
    
    .item-content {
      display: flex;
      align-items: center;
      flex: 1;
    }
    
    .item-icon {
      display: flex;
      align-items: center;
      margin-right: 12px;
      
      img, svg {
        width: 20px;
        height: 20px;
      }
    }
    
    .item-text {
      font-size: 14px;
      font-weight: 500; // Texto más grueso
      letter-spacing: 0.1px; // Mejor legibilidad
    }
    
    .folder-actions {
      display: flex;
      align-items: center;
      
      .action-divider {
        width: 1px;
        height: 18px;
        margin-right: 8px;
      }
      
      .settings-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  
  // Sección del menú
  .menu-section {
    .section-header {
      padding: 10px 16px; // Reducido padding vertical
      font-size: 13px; // Ligeramente más pequeño
      font-weight: 600; // Más grueso para que resalte
      text-transform: uppercase; // Similar al estilo Firebase
      letter-spacing: 0.5px;
    }
    
    .divider {
      height: 1px;
      width: 100%;
    }
  }
  
  // Subfolder container
  .subfolder-container {
    width: 100%;
  }
}

// Tema claro
.filter-menu-container.light {
    height: 100%;
  .filter-button {
    
    color: $light-text-color;
    background-color: $light-background;
    border: 1px solid $light-divider-color;
    
    &:hover {
      background-color: $light-hover-background;
    }
  }
  
  .menu-dropdown {
    background-color: $light-background;
    border: 1px solid $light-divider-color;
  }
  
  .section-header {
    color: $light-secondary-color;
    background-color: $light-paper-background;
  }
  
  .divider {
    background-color: $light-divider-color;
  }
  
  .filter-item {
    &.light {
      color: $light-text-color;
      
      &:hover {
        background-color: $light-hover-background;
      }
      
      &.selected {
        background-color: $light-selected-background;
        color: $light-selected-text;
        font-weight: 600; // Más notable cuando está seleccionado
      }
      
      .item-icon {
        color: $light-text-color;
      }
      
      .action-divider {
        background-color: $light-divider-color;
      }
      
      .settings-icon {
        color: $light-secondary-color;
        
        &:hover {
          color: $light-text-color;
        }
      }
    }
  }
  
  .subfolder-container.light {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

// Tema oscuro (tipo Firebase)
.filter-menu-container.dark {
  .filter-button {
    color: $dark-text-color;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid $dark-divider-color;
    
    &:hover {
      background-color: $dark-hover-background;
    }
  }
  
  .menu-dropdown {
    background-color: rgb(5, 30, 52); // Color específico de Firebase
    color: $dark-text-color;
    
    // Personalización de scrollbar para tema oscuro
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  
  .section-header {
    color: rgb(160, 175, 195); // Color gris más claro para títulos
    background-color: rgb(5, 30, 52);
    font-weight: 600; // Más visible en fondo oscuro
  }
  
  .divider {
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  .filter-item {
    &.dark {
      color: $dark-text-color;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
      
      &.selected {
        background-color: rgba(71, 98, 130, 0.2);
        color: rgb(102, 157, 246); // Color azul de Firebase
        font-weight: 600; // Más visible cuando está seleccionado
      }
      
      .item-icon {
        color: $dark-text-color;
      }
      
      .action-divider {
        background-color: rgba(255, 255, 255, 0.12);
      }
      
      .settings-icon {
        color: rgba(255, 255, 255, 0.5);
        
        &:hover {
          color: $dark-text-color;
        }
      }
    }
  }
  
  .subfolder-container.dark {
    background-color: rgba(71, 98, 130, 0.2);
  }
}