// Variables
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;
$header-height: 60px;
$content-padding: 20px;

// Mixin para estructura común
@mixin layout-structure {
  display: grid;
  grid-template-rows: auto;
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
}

@mixin content-common {
  margin: 10px;
  padding: 10px 0px 10px 10px;
  border-radius: 10px;
  grid-column: 2;
  grid-row: 1;
  overflow: hidden;
}

// Tema Oscuro
.GM__dark {
  &-main {
    background: transparent;
    overflow: hidden;
  }

  &-container {
    @include layout-structure;
    grid-template-columns: 56px 1fr;
    background: #000000;
  }

  &-content {
    @include content-common;
    background: transparent;
  }

  &-content-expanded {
    @include content-common;
    background: transparent;
  }

  &-container-expanded {
    @include layout-structure;
    grid-template-columns: 250px 1fr;
    background: #000000;
  }
}

// Tema Claro
.GM__light {
  &-main {
    background: transparent;
    overflow: hidden;
  }

  &-container {
    @include layout-structure;
    grid-template-columns: 56px 1fr;
    background: #ffffff;
  }

  &-content {
    @include content-common;
    background: #f5f5f5;
  }

  &-content-expanded {
    @include content-common;
    background: #f5f5f5;
  }

  &-container-expanded {
    @include layout-structure;
    grid-template-columns: 250px 1fr;
    background: #ffffff;
  }
}