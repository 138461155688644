// Variables para tema claro
$light-background: #ffffff;
$light-text-color: #333333;
$light-secondary-color: #666666;
$light-hover-background: rgba(0, 123, 255, 0.18);
$light-error-color: #e66;
$light-icon-size: 24px;
$light-spacing-unit: 8px;
$light-header-height: 64px;

.GM__light-person-container {
  padding: 5px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background: transparent;
  color: $light-text-color;

  .GM__light-person-header {
    width: 100%;
    height: $light-header-height;
    margin-bottom: 20px;
    padding: $light-spacing-unit * 2;
  
    .GM__light-header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      position: relative;
      gap: $light-spacing-unit * 3;
  
      .GM__light-settings-container {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        z-index: 2;
  
        .GM__light-settings-container-icon {
          cursor: pointer;
          padding: $light-spacing-unit;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: transform 0.2s ease;
          background: none;
          border: none;
          appearance: none;
          margin: 0;
          
          &:hover {
            transform: scale(1.1);
          }
          
          &:focus-visible {
            outline: 2px solid #007bff;
            outline-offset: 2px;
          }
        
          .GM__light-settings-icon {
            width: $light-icon-size;
            height: $light-icon-size;
            object-fit: contain;
          }
        }
      }
    }
  }

  .GM__light-person-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    width: 100%;

    .GM__light-grid-item {
      &:hover {
        background-color: $light-hover-background;
        border-radius: 8px;
      }
    }
  }

  .GM__light-error {
    text-align: center;
    padding: $light-spacing-unit * 4;
    font-size: 1.125rem;
    color: $light-error-color;
  }
}

// Media queries 
@media screen and (max-width: 768px) {
  .GM__light-person-container {
    padding: 16px;

    .GM__light-person-header {
      height: auto;

      .GM__light-header-content {
        flex-direction: column;
        gap: 16px;
        align-items: stretch;

        .GM__light-settings-container {
          justify-content: flex-end;
        }
      }
    }

    .GM__light-person-grid {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 480px) {
  .GM__light-person-container {
    padding: 12px;

    .GM__light-person-header {
      margin-bottom: 16px;
    }
  }
}