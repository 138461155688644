.password-recovery-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f2f2f2;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .password-recovery-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  
    .password-recovery-input {
      margin-bottom: 1rem;
    }
  
    .password-recovery-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 1rem;
  
      .password-recovery-button {
        width: 100%;
        background-color: #4caf50;
        color: white;
        padding: 0.75rem 1.5rem;
        border-radius: 4px;
        font-size: 1rem;
        font-weight: 500;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #45a049;
        }
      }
  
      .password-recovery-back-button {
        color: #4caf50;
        font-size: 0.875rem;
        margin-top: 1rem;
      }
    }
  }